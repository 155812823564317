import styled from '@emotion/styled';
import React from 'react'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import TitleItem from '../../common/item/TitleItem';

export default function AboutContentChartListItem({list}:any) {
    return (
        <Wrap>
            <TitleItem title1='Meca Economy' lineState='bottom'></TitleItem>
            <ListBoxWrap>
                {list?.map((data:any,index:any)=>{
                    return(
                        <ContentWrap key={`chart-item-${index}`}>
                            <ContentTitleWrap>
                                <ContentTitleBg bgColor={data.color}></ContentTitleBg>
                                <BasicText marginLeft={1.3} theme='black' fontSize={18} style={{padding:'8px 0'}}>{data.name}</BasicText>
                            </ContentTitleWrap>
                            <BasicText theme='black' fontSize={18}>{data.value}%</BasicText>
                        </ContentWrap>
                    )
                })}
            </ListBoxWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const ContentWrap = styled(RowBetween)`
    border: 1px solid #fff;
    background-color: rgba(255,255,255,0.2);
    margin-top: 15px;
    padding-right: 15px;
`;

const ListBoxWrap = styled.div`
    margin-top: 60px;
`;

const ContentTitleWrap = styled(Row)`
    align-items: stretch;
`;

const ContentTitleBg = styled.div<{bgColor?:string}>`
    width: 10px;
    background-color: ${props => props.bgColor ?? 'transparent'};
`;
