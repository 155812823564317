import styled from '@emotion/styled';
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import {addTheme} from '../../../style/basic/addTheme';

interface centerTitleItem{
    title?:string;
    sub?:any;
    subMarginTop?:number;
}

export default function CenterTitleItem({title,sub,subMarginTop}:centerTitleItem) {
    return (
        <Wrap>
            <Center>
                <BasicText textAlign={'center'} theme={'black'} fontSize={addTheme.title.fontSize} mFontSize={addTheme.title.mFontSize} fontWeight={addTheme.title.fontWeight}>{title}</BasicText>
            </Center>
            {sub !== undefined &&
                <Center>
                    <BasicText marginTop={subMarginTop ?? 6} textAlign={'center'} theme={'subtext'} fontSize={addTheme.subTitle.fontSize} mFontSize={addTheme.subTitle.mFontSize}>{sub}</BasicText>
                </Center>
            }
        </Wrap>
    )
}


const Wrap = styled.div``;