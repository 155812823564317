import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import React, { useEffect, useState } from 'react'
import { MaxWidthWrap, PaddingWrap } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import ProfilePart from '../part/ProfilePart';
import CountPart from '../part/CountPart';
import TitleItem from '../../common/item/TitleItem';
import { FaAngleDown } from 'react-icons/fa';
import ListPage from '../../list/page/ListPage';
import NftItem from '../../list/item/NftItem';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useWeb3React } from '@web3-react/core';
import { useLocation, useNavigate } from 'react-router';
import { apiMyList } from '../../../api/mainApi';
import BasicText from '../../basic/BasicText';
import useGetUrlParams from '../../../utils/custom-hooks/common/useGetUrlParams';
import { mecaNftAddress,mecaNftAbi, mecaTokenAddress, mecaTokenAbi } from '../../../data/tokenData';
import EmailDialogPage from '../../common/page/EmailDialogPage';
import useNft from '../../../utils/custom-hooks/common/useNft';
import { ETH_ID } from '../../../api/config';
import useTokenInfo from '../../../utils/custom-hooks/common/useTokenInfo';

const listInit = {nft_id:0,nft_name:'',nft_img:''}

export default function MyPage() {
    const page = useGetUrlParams('page');
    const theme = useTheme();
    const user = useUserInfo();
    const navigate = useNavigate();
    const location = useLocation();
    const {account,chainId} = useWeb3React();
    const [list,setList] = useState([listInit]);
    const [nowPage,setNowPage] = useState(Number(page));
    const [totalPage,setTotalPage] = useState(1);
    const [nftCount,setNftCount] = useState(0);
    const [mecaCount,setMecaCount] = useState(0);
    const [openChk,setOpenChk] = useState(false);
    const [noListTxt,setNoListTxt] = useState('');
    const [netState,setNetState] = useState('');

    const nft = useNft(mecaNftAddress,mecaNftAbi);
    const tokenInfo = useTokenInfo(mecaTokenAddress,mecaTokenAbi);

    useEffect(()=>{
        if(!account) return;
        if(user.user_wallet_eth || user.user_wallet_bsc) {
            if(location.search === '?page=1'){
                if(nowPage !== 1){
                    navigate(`?page=${nowPage}`);
                }
            }else{
                navigate(`?page=${nowPage}`);
            }
            getMyList();
            return
        };
    },[user.user_wallet_eth, user.user_wallet_bsc,user.user_id,nowPage,account,chainId]);

    useEffect(()=>{
        if(!account) return;
        getTokenInfo();
    },[account,chainId]);

    useEffect(()=>{
        if(user.user_id === 0 && !account){
            navigate('/');
        }
    },[user.user_id,account])

    // getTokenInfo
    const getTokenInfo = async() =>{
        try {
            const tokenBal = await tokenInfo?.tokenBalance();
            let mecaBal = tokenBal === undefined ? 0 : tokenBal;
            setMecaCount(mecaBal);
        } catch (error) {
            setMecaCount(0);
            setNetState('meca');
        }
        try {
            const nftList = await nft?.nftListWallet(account);
            setNftCount(nftList.length);
        } catch (error) {
            setNftCount(0);
            setNetState('nft');
        }

    }

    //구매목록
    const getMyList = async() =>{
        try {
            if(chainId !== ETH_ID){
                setNoListTxt('Please change the network to ETH.');
                setList([listInit])
                return;
            }

            const nftList = await nft?.nftListWallet(account);
            let nftListTxt = '';
            nftList.map((item:any,index:any)=>{
                nftListTxt += `'${item}'${(index+1)===nftList.length? '':','}`;
            });
            
            const res = await apiMyList(nftListTxt,nowPage,9);
            
            if(!res.result || res.data.length === 0){
                setNowPage(1);
                setList([listInit]);
                setNoListTxt('No items to display')
                return;
            }
            setList(res.data);
            let total = res.total === 0 ? 1 : res.total;
            setTotalPage(total);
        } catch (error) {
            
        }
    }
    
    return (
        <Wrap>
            <EmailDialogPage openChk={openChk} setOpenChk={setOpenChk} userId={user.user_id}></EmailDialogPage>
            <PaddingWrap padding='100px 0 0'>
                <MaxWidthWrap maxWidth='800px'>
                    <ProfileWrap>
                        <ProfilePart account={account} user={user} setEmailOpen={setOpenChk}></ProfilePart>
                        <PaddingWrap padding='50px 0 0'>
                            <CountPart nftCount={nftCount} mecaCount={mecaCount} netState={netState}></CountPart>
                        </PaddingWrap>
                        <BgWrap>
                            <BgInnerWrap>
                                <BgItem>
                                    <BgItemWrap></BgItemWrap>
                                    <BgItemWrap></BgItemWrap>
                                </BgItem>
                                <BgItem>
                                    <BgItemWrap></BgItemWrap>
                                    <BgItemWrap></BgItemWrap>
                                </BgItem>
                            </BgInnerWrap>
                        </BgWrap>
                    </ProfileWrap>
                </MaxWidthWrap>
            </PaddingWrap>
            <MaxWidthWrap>
                <PaddingWrap padding='80px 0 30px'>
                    <TitleItem title1='My NFTs' lineState='top' icon={<FaAngleDown color={theme.black}></FaAngleDown>}></TitleItem>
                </PaddingWrap>
                <ListWrap>
                    <ListPage type='number' nowPage={nowPage} totalPage={totalPage} count={3} mCount={2} gap={30} changeNowPage={setNowPage}>
                        {list[0]?.nft_id === 0 ? 
                            <BasicText theme='black'>{noListTxt}</BasicText>
                        :list?.map((data,index)=>{
                            return(
                                <NftItem key={`myNft-${data.nft_id}`} title={data.nft_name} fontSize={18} img={data.nft_img} link={`/detail/${data.nft_id}`}></NftItem>
                            )
                        })}
                    </ListPage>
                </ListWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ProfileWrap = styled.div`
    position: relative;
    &>div{position: relative;}
`;

const ListWrap = styled.div``;

const BgWrap = styled.div`
    position: absolute !important;
    top:-10%;
    left:0;
    width:100%;
    height: 100%;
    z-index: -1;
    @media ${({theme}:any)=>theme.media.m}{
        top:-20%;
    }
`;


const rotateAni = keyframes`
    100%{ transform:translate(-50%,-50%) rotate(360deg);}
`;

const BgInnerWrap = styled.div`
    position: absolute;
    top:50%;
    left: 50%;
    width: 30%;
    &::before{content:''; display:block; padding-bottom:100%;}
    background-color: #ddd;
    transform: translate(-50%,-50%) rotate(0deg);
    filter: blur(60px);
    animation: ${rotateAni} 18s 1s infinite ease;
`;

const scaleAni = keyframes`
    0%{transform:scale(1);}
    50%{transform:scale(1.3);}
    100%{transform:scale(1);}
`;

const BgItem = styled.div`
    position: absolute;
    width: 100%;
    height: 200%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    &:nth-of-type(2){transform:translate(-50%,-50%) rotate(90deg);}
    &:nth-of-type(1)>div:nth-of-type(1){background-color:rgba(0, 8, 255, 0.5); animation: ${scaleAni} 10s 1s infinite ease;}
    &:nth-of-type(1)>div:nth-of-type(2){background-color:rgba(255, 0, 0,0.5); animation: ${scaleAni} 15s 4s infinite ease;}
    &:nth-of-type(2)>div:nth-of-type(1){background-color:rgba(225, 0, 255,0.5); animation: ${scaleAni} 10s 8s infinite ease;}
    &:nth-of-type(2)>div:nth-of-type(2){background-color:rgba(0, 238, 255, 0.5); animation: ${scaleAni} 15s 12s infinite ease;}
`;


const BgItemWrap = styled.div`
    width:100%;
    border-radius: 100%;
    &::before{content:''; display:block; padding-bottom:100%;}
`;
