import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { Row } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import BasicText from '../../basic/BasicText';
import GridItem from '../../common/item/GridItem';
import BtnItem from '../../header/item/BtnItem';

export default function MainPart({linkList}:any) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [playNow,setPlayNow] = useState('');
    const goMarket = () =>{
        navigate('/collection')
    }
    useEffect(()=>{
        if(linkList[0].meca_link_id === 0) return;
        if(linkList === undefined || linkList.length === 0){
            setPlayNow('');
        }else{
            const link = linkList.filter((data:any)=>data.meca_link_type === 0);
            setPlayNow(link[0].meca_link_txt);
        }
    },[linkList]);

    const playNowBtn = () =>{
        if(playNow === '') return;
        location.href=playNow;
    }
    return (
        <div>
            <Wrap>
                <LeftWrap>
                    <BasicText marginTop={5} theme={'black'} fontSize={38} ls={4} fontWeight={800}>MECA</BasicText>
                    <BasicText marginTop={1} fontSize={18} mFontSize={15} theme='black'>
                        Welcome to Meca Project to lower the entry barriers by using the crypto market to solve the high investment barriers in the online casino industry
                    </BasicText>
                    <BtnWrap>
                        <BtnItem padding='9px 14px' margin='0 20px 0 0' minWidth='140px' clickEvent={goMarket} text='Go Marketplace' borderColor={theme.btnBorder}></BtnItem>
                        <BtnItem padding='9px 14px' minWidth='140px' clickEvent={playNowBtn} text='Play Now' background={theme.comingBtn} borderColor={theme.comingBtn}></BtnItem>
                    </BtnWrap>
                </LeftWrap>
                <SpaceWrap></SpaceWrap>
                <RightWrap>
                    <ImgWrap>
                        <video autoPlay={true} muted={true} loop={true}>
                            <source src='/img/home/MECA_NFT_0001_Trim.mp4'></source>
                        </video>
                    </ImgWrap>
                </RightWrap>
            </Wrap>
        </div>
    )
}

const Wrap = styled(Row)`
    height: calc(100vh - 72px);
    @media ${({theme}:any)=>theme.media.m}{
        flex-wrap: wrap;
        height: auto;
        margin-top: 80px;
    }
`;
const LeftWrap = styled.div`
    width:40%;
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
    }
`;
const RightWrap = styled.div`
    width: 40%;
    @media ${({theme}:any)=>theme.media.m}{
        width: 100%;
    }
`;

const SpaceWrap = styled.div`
    width:20%;
    @media ${({theme}:any)=>theme.media.m}{
        padding: 30px 0;
    }
`;

const BtnWrap = styled(Row)`
    margin-top: 25px;
`;

const ImgWrap = styled.div`
    /* position: absolute;
    top:0;
    left:0; */
    width:100%;
    overflow: hidden;
    &>video{max-width:100%;}
`;