import CryptoJS from 'crypto-js'

export const walletMiddleSubstring = (address: string) => {
    const startString = address.substr(0, 7);
    const endString = address.substr(-7);

    return `${startString}...${endString}`;
}

export const hashMiddleClampSubstring = (hash: string) => {
    const startString = hash.substr(0, 10);
    const endString = hash.substr(-10);

    return `${startString}...${endString}`;
}

export const subStringToStart = (text: string) => {
    return `${text.substring(0, 13)}...`;
}

export const subStringToStartCount = (text: string, count: number) => {
    return `${text.substring(0, count)}...`;
}

export const subStringToStartCountNoDots = (text: string, count: number) => {
    if(text.length > count){
        return `${text.substring(0, count)}...`;
    } else {
        return `${text.substring(0, count)}`;
    }
}


export const hashMiddleClampSubstringCount = (hash: string, count: number) => {
    const startString = hash.substr(0, count);
    const endString = hash.substr(-count);

    return `${startString}...${endString}`;
}

export const cryptoSHA256Hex = (nowDate: number) => {
    const key = 'HELLO WORLD';
    var encrypted = CryptoJS.AES.encrypt(nowDate.toString(), key);
    return encrypted.ciphertext.toString().toUpperCase();
}


export const MTCcryptoSHA256Hex = (nowDate: number) => {
    const key = 'mtcZZANG';
    var encrypted = CryptoJS.AES.encrypt(nowDate.toString(), key);
    return `MTC${encrypted.ciphertext.toString().toUpperCase()}`;
}

export const keyEnc = (txt:string) =>{
    if(process.env.REACT_APP_MASTER === undefined) return '';
    let encrypted = CryptoJS.AES.encrypt(txt,process.env.REACT_APP_MASTER).toString();
    return encrypted;
}
export const keyDec = (txt:string) =>{
    if(process.env.REACT_APP_MASTER === undefined) return '';
    let bytes = CryptoJS.AES.decrypt(txt, process.env.REACT_APP_MASTER);
    let decrypt = bytes.toString(CryptoJS.enc.Utf8)
    return decrypt;
}