import styled from '@emotion/styled';
import React, { useRef } from 'react'
import { Center, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';

export default function PropertiesItem({data}:any) {
    const theme = useTheme();

    return (
        <Wrap>
            {data !== undefined &&
                data?.map((data:any,index:any)=>{
                    return(
                        <ItemWrap key={`${data.title}-${index}`} borderColor={theme.btnBorder} bg={theme.detailProperties}>
                            <TextWrap>
                                <BasicText theme='black' fontSize={15}>{data.title}</BasicText>
                                <BasicText theme='black' fontSize={19} fontWeight={700}>{data.value}</BasicText>
                            </TextWrap>
                        </ItemWrap>
                    )
                })
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width:499px){
        grid-template-columns: 1fr 1fr;
    }
`;

const ItemWrap = styled.div<{borderColor?:string, bg?:string}>`
    width: 100%;
    background-color: ${props=>props.bg ?? 'transparent'};
    border: 1px solid;
    border-color: ${props=>props.borderColor ?? 'transparent'};
    padding: 6px;
    overflow-x: auto;
`;

const TextWrap = styled.div`
    max-width: 100%;
    &>p{display:block; overflow: hidden; text-overflow:ellipsis;}
`;