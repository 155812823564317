import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import BasicText from '../../basic/BasicText'
import styled from '@emotion/styled';
import {AiFillCheckCircle, AiFillExclamationCircle, AiFillWarning} from 'react-icons/ai';

interface modalItemType {
    type?:string;
    content1?:string;
    content2?:string;
    modalOpen:boolean;
    setModalOpen?:any;
    stateReset?:any;
}

export default function ModalItem({type,content1,content2,modalOpen,setModalOpen,stateReset}:modalItemType) {
    const handleClose = () =>{
        if(type==='success'){
            stateReset();
            return;
        }
        setModalOpen(false);
    }

    return (
        <Dialog
            open={modalOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={(e)=>{}}
            sx={{'.MuiDialog-paper':{bgcolor:'#fff',color:'#000',border:'1px solid #000',borderRadius:'15px'}}}
        >
            <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
                {type === 'success' ? <AiFillCheckCircle size={45} color='#222389'></AiFillCheckCircle>
                    : type=== 'alert' ? <AiFillExclamationCircle size={45} color='#f0d154'></AiFillExclamationCircle>
                    : type === 'fail' && <AiFillWarning size={45} color='#e32727'></AiFillWarning>

                }
            </DialogTitle>
            <DialogContent>
                <BasicText sx={{textAlign:'center'}} color='#000'>{content1}</BasicText> 
                <BasicText sx={{textAlign:'center'}} color='#000'>{content2}</BasicText> 
            </DialogContent>
            <DialogActions>
                <BtnWrap>
                    <Button onClick={handleClose} sx={{bgcolor:'#35365c','&:hover':{bgcolor:'#35365c'}}}>
                        {type==='success' ? 'OK' : 'Re-Try'}
                    </Button>
                </BtnWrap>
            </DialogActions>
        </Dialog>
    )
}

const BtnWrap = styled.div`
    width:100%;
    text-align: center;
    & button{
        color:#fff; 
        border-radius:15px; 
        text-transform:capitalize;
        width: 70%;
    }
`;