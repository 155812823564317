import styled from '@emotion/styled';
import React from 'react'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme'
import BasicText from '../../basic/BasicText';

interface btnItemType{
    text?:string;
    size?:number;
    mSize?:number;
    children1?:any;
    children2?:any;
    padding?:string;
    clickEvent?:any;
    width?:string;
    background?:string;
    borderColor?:string;
    margin?:string;
    minWidth?:string;
    cursor?:string;
    borderRadius?:number;
    mPadding?:string;
}

export default function BtnItem({text,size,mSize,children1,children2,padding,clickEvent,width,background,borderColor,margin,minWidth,cursor,borderRadius,mPadding}:btnItemType) {
    const theme = useTheme();
    let childrenType = typeof children1;
    const btnClick = () =>{
        if(clickEvent=== undefined) return;
        clickEvent();
    }
    
    return (
        <Wrap cursor={cursor} width={width} minWidth={minWidth} borderColor={borderColor} childrenType={childrenType} padding={padding} onClick={()=>btnClick()} background={background} margin={margin} borderRadius={borderRadius} mPadding={mPadding}>
            {children1}
            {(text !== '' || text !== undefined) &&
                <BasicText theme={'black'} fontSize={size} mFontSize={mSize}>{text}</BasicText>
            }
            {children2}
        </Wrap>
    )
}

const Wrap = styled.button<{width?:string,color?:string,borderColor?:string,size?:number,childrenType?:any,padding?:string,background?:string,margin?:string,minWidth?:string, cursor?:string, borderRadius?:number, mPadding?:string}>`
    color: ${props=>props.color ?? '#ddd'};
    border: ${props=>props.borderColor === undefined ? '2x solid transparent' : `2px solid ${props.borderColor}`};
    padding: ${props=>props.padding ?? '6px 10px'};
    border-radius: ${({theme}:any)=>theme.snsBtn.borderRadius ?? '0'};
    display: flex;
    align-items: center;
    &>p{margin-left: ${props => props.childrenType === 'undefined' ? 0 : '6px'}; display:block; text-align:center; width:100%;}
    width: ${props=>props.width ?? 'auto'};
    background-color: ${props => props.background ?? 'transparent'};
    margin:${props=>props.margin ?? 0};
    min-width:${props=>props.minWidth ?? 'auto'};
    cursor: ${props => props.cursor ?? 'pointer'};
    border-radius: ${props => props.borderRadius ?? '0'}px;
    @media ${({theme}:any) => theme.media.m}{
        padding : ${props => props.mPadding === undefined ? props.padding ?? '6px 10px' : props.mPadding ?? '4px 6px'}
    }
`;