import React from 'react'
import styled from '@emotion/styled';
import { listType,viewCountType } from '../page/ListPage';
import NumberBtnPart from '../part/NumberBtnPart'
import NoItemPart from '../part/NoItemPart'

interface noneListType extends listType{
    listCount:number;
    viewCount?:viewCountType;
    children?:any;
}

export default function NoneListPart({type,viewCount,gap,nowPage=1,totalPage=1,changeNowPage,listCount,children}:noneListType) {
    
    return (
        <>
            <Wrap gap={gap} viewCount={viewCount}>
                {children === undefined ?
                    <NoItemPart></NoItemPart>
                : children}
            </Wrap>
            {/*  && totalPage !== 1 */}
            {(type==='number') &&
                <NumberBtnPart nowPage={nowPage} totalPage={totalPage} changeNowPage={changeNowPage} listCount={listCount}></NumberBtnPart>
            }
        </>
    )
}
const Wrap = styled.div<{viewCount?:any,gap?:number}>`
    width: 100%;
    display: grid;
    gap: ${props=>props.gap ?? 0}px;
    grid-template-columns: ${props=>props.viewCount.pcCount ?? '1fr 1fr 1fr 1fr'};
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-columns: ${props=>props.viewCount.mCount ?? '1fr 1fr 1fr 1fr'};
    }
`;

