import styled from '@emotion/styled'
import React from 'react'
import { Center, Row } from '../../../style/basic/commonStyle'
import BasicText from '../../basic/BasicText'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme'
import { Button } from '@mui/material';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { FaEthereum } from 'react-icons/fa';
import {SiBinance} from 'react-icons/si';
import {GrMail} from 'react-icons/gr';
import { walletMiddleSubstring } from '../../../utils/format/string'

export default function ProfilePart({account,user,setEmailOpen}:any) {
    const theme = useTheme();
    const userInfo = useUserInfo();

    return (
        <div>
            <Center>
                <BasicText theme='black' fontSize={28} mFontSize={24} fontWeight={700}>MY PAGE</BasicText>
            </Center>
            {user.user_email === '' && 
                <BtnWrap>
                    <Button onClick={()=>setEmailOpen(true)} variant='contained' sx={{bgcolor: theme.comingBtn, borderRadius:25, color:'#fff', '&:hover':{bgcolor:theme.comingBtn}}}>Email Authentication</Button>
                </BtnWrap>
            }
            <Center>
            </Center>
            <WalletWrap>
                {user.user_email !== '' &&
                    <WalletItem>
                        <WalletLeft>
                            <WalletIcon>
                                <GrMail color='#0e0744' size={18}></GrMail>
                            </WalletIcon>
                            <BasicText theme='black'>Email</BasicText>
                        </WalletLeft>
                        <BasicText limit='true' line={1} fontSize={21} theme='black' textAlign={'right'}>{userInfo.user_email}</BasicText>
                    </WalletItem>
                }
                <WalletItem>
                    <WalletLeft>
                        <WalletIcon>
                            <FaEthereum color='#0e0744' size={18}></FaEthereum>
                        </WalletIcon>
                        <BasicText theme='black'>Ethereum</BasicText>
                    </WalletLeft>
                    <BasicText limit='true' line={1} fontSize={21} theme='black' textAlign={'right'}>{walletMiddleSubstring(userInfo.user_wallet_eth ?? '')}</BasicText>
                </WalletItem>
                <WalletItem>
                    <WalletLeft>
                        <WalletIcon>
                            <SiBinance color='#0e0744' size={18}></SiBinance>
                        </WalletIcon>
                        <BasicText theme='black'>Binance</BasicText>
                    </WalletLeft>
                    <BasicText limit='true' line={1} fontSize={21} theme='black' textAlign={'right'}>{walletMiddleSubstring(userInfo.user_wallet_bsc ?? '')}</BasicText>
                </WalletItem>
            </WalletWrap>
        </div>
    )
}

const BtnWrap = styled(Center)`
    padding: 15px 0 0;
    margin-top: 25px;
`;

const WalletWrap = styled.div`
    margin-top: 45px;
`;
const WalletItem = styled(Row)`
    border: 1px solid #fff;
    padding: 12px;
    border-radius: ${({theme}:any)=>theme.borderRadius};
    background-color: rgba(255,255,255,0.24);
    margin-top: 20px;
`;
const WalletLeft = styled(Row)``;
const WalletIcon = styled(Center)`
    background-color: #fff;
    border-radius: 100%;
    width: 30px;
    height:30px;
    margin-right: 10px;
`;