import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Row } from '../../../style/basic/commonStyle'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import {AiOutlineLineChart} from 'react-icons/ai';
import useScrollCount from '../../../utils/custom-hooks/common/useScrollCount';
import BasicText from '../../basic/BasicText';

interface boardPartType{
    title?:Array<string>;
    size?:Array<number>;
    list:any;
}

export default function BoardListPart({list}:boardPartType) {
    const theme = useTheme();
    const countItem = useScrollCount(list.profit_profit ?? 0);
    
    return (
        <>
            <Wrap boardBg={theme.boardBg}>
                <ContentWrap>
                    {list.length!==0 && 
                        <ContentItemWrap borderColor={theme.comingBtn}>
                            <Row>
                                <AiOutlineLineChart color='#fff' size={30}></AiOutlineLineChart>
                                <BasicText marginLeft={1} theme='black' fontSize={25}>Total Profit</BasicText>
                            </Row>
                            <TextWrap>
                                <ValueWrap>
                                    <BasicText theme='black' fontSize={34} mFontSize={28} fontWeight={800}>$</BasicText>
                                    <CountWrap {...countItem}></CountWrap>
                                    {/* <BasicText theme='black' fontSize={34} mFontSize={28} fontWeight={800} comma={true}>{list.profit_profit}</BasicText> */}
                                </ValueWrap>
                                <BasicText theme='black' marginTop={1} fontSize={18}>{list.profit_company}</BasicText>
                            </TextWrap>
                        </ContentItemWrap>
                    }
                </ContentWrap>
            </Wrap>
        </>
    )
}

const Wrap = styled.div<{boardBg?:string}>`
    width:100%;
    /* min-width: 600px; */
`

const ContentWrap = styled.div`
    width:100%;
`;

const ContentItemWrap = styled.div<{borderColor?:string}>`
    border-radius: ${({theme}:any)=>theme.borderRadius};
    border: 1px solid ${props=>props.borderColor ?? 'transparent'};
    padding: 30px;
    @media ${({theme}:any)=>theme.media.m}{
        padding: 15px;
    }
`;

const TextWrap = styled.div`
    text-align: right;
    & p{display:block; word-break: break-all;}
    margin-top: 10px;
`;

const ValueWrap = styled(Row)`
    justify-content: end;
`;

const CountWrap = styled.p`
    color: #fff;
    font-size: 34px;
    font-weight: 800;
`;