import styled from '@emotion/styled';
import React from 'react'

export interface BasicInputType{
    padding?:string;
    value?:any;
    setValue?:any;
    onKeyDownEvent?:any;
    color?:string;
}

export default function BasicInput({padding,value='',setValue,onKeyDownEvent,color}:BasicInputType) {
    
    return (
        <Wrap>
            <Input color={color} padding={padding} value={value} onChange={(el)=>setValue(el.target.value)} onKeyDown={(e)=>onKeyDownEvent(e)}></Input>
        </Wrap>
    )
}

const Wrap = styled.div<{border?:string}>`
    width: 100%;
`;

const Input = styled.input<{padding?:string}>`
    width: 100%;
    padding:${props=>props.padding ?? '0'};
    color : ${props=>props.color ?? '#ddd'};
    &:focus-visible{outline:none;}
`;