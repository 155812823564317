import styled from '@emotion/styled'
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';

export default function NumberItem({children,changeNowPage,page,nowPage}:any) {
    const theme = useTheme();
    const numberColor = page===nowPage ? 'btnBorder' : 'detailProperties';
    return (
        <Item borderColor={theme[numberColor]} onClick={()=>changeNowPage(page)} nowActive={page===nowPage}>
            <ItemInner>
                <BasicText theme={numberColor}>{children}</BasicText>
            </ItemInner>
        </Item>
    )
}

const Item = styled.div<{nowActive?:boolean,borderColor?:string}>`
    border: ${props => props.borderColor === undefined ? `1px solid #282424`: `1px solid ${props.borderColor}`};
    width: 26px;
    margin: 0 1px;
    height: 26px;
    cursor: pointer;
    background-color: ${props=>props.nowActive ? '#2422220':'#221d1d'};
`;

const ItemInner = styled(Center)`
    height: 100%;
`;