import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Center } from '../../../style/basic/commonStyle';
import AboutContentPartChartItem from '../item/AboutContentChartItem';
import { aboutChartData } from '../../../data/dummy';
import AboutContentChartListItem from '../item/AboutContentChartListItem';

export default function AboutContentPartChart() {
    const [chart,setChart] = useState([{id:1,name:'Ecosystem',value:50,color:'#dd33ee'}]);
    useEffect(()=>{
        getChartData();
    },[])

    const getChartData = async() =>{
        const res = await aboutChartData();
        setChart(res.list);
    }

    return (
        <Wrap>
            <LeftWrap>
                <AboutContentPartChartItem chart={chart}></AboutContentPartChartItem>
            </LeftWrap>
            <SpaceWrap></SpaceWrap>
            <RightWrap>
                <AboutContentChartListItem list={chart}></AboutContentChartListItem>
            </RightWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    align-items: start;
    @media ${({theme}:any)=>theme.media.m}{
        flex-wrap: wrap;
    }
`;
const LeftWrap = styled.div`
    width: 52%;
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
    }
`;


const SpaceWrap = styled.div`
    width:5%;
`;

const RightWrap = styled.div`
    width: 43%;
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
        margin-top: 30px;
    }
`;