import styled from '@emotion/styled';
import React from 'react'
import InformationItem from '../item/InformationItem'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';

export default function InformationPart({data,clickEvent}:any) {
    const theme = useTheme();
    return (
        <Wrap>
            <ItemWrap>
                <InformationItem type='Details' background={theme.itemBg} data={data?.nft_detail}></InformationItem>
            </ItemWrap>
            <ItemWrap>
                <InformationItem type='Listing' clickEvent={clickEvent} background={theme.itemBg} data={data}></InformationItem>
            </ItemWrap>
            <ItemWrap>
                <InformationItem type='Properties' background={theme.itemBg} data={data?.nft_properties}></InformationItem>
            </ItemWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    display: grid;
    color: #fff;
    grid-template-areas:
        "detail listing"
		"properties properties";
    &>:nth-of-type(1){grid-area: detail;}
    &>:nth-of-type(2){grid-area: listing;}
    &>:nth-of-type(3){grid-area: properties;}
    gap: 25px;
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-areas:
        "detail" 
        "listing"
        "properties";
    }
`;

const ItemWrap = styled.div``;