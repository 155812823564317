import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import BasicText from '../../basic/BasicText';
import {BsChevronDown,BsChevronUp} from 'react-icons/bs';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { Center, Row } from '../../../style/basic/commonStyle';
import { BsCheckLg } from 'react-icons/bs';

interface selectPartType {
    title?:string;
    border?:string;
    padding?:string;
    background?:string;
    list?:Array<{id?:number,title?:string}>;
    select?:Array<any>;
    setSel?:any;
}

export default function SelectPart({title,list,border,padding,background,select,setSel}:selectPartType) {
    const theme = useTheme();
    const [openChk,setOpenChk] = useState(false);
    const [selectIdx,setSelectIdx] = useState([0]);

    useEffect(()=>{
        if(select?.length === 0 || select === undefined || select[0]?.id === 0) return;
        setSelectIdx(select.map((data)=>data.id));
        setOpenChk(true);
    },[select])

    useEffect(()=>{
        let arrTxt = selectIdx.join();
        setSel(arrTxt);
    },[selectIdx,setSel])

    const openClick = (bol:boolean) =>{
        setOpenChk(bol);
    }

    const itemClick = (data:any) =>{
        if(selectIdx[0]===0){
            setSelectIdx([data.id]);
            return;
        }
        let chk = selectIdx.some(item => item===data.id);
        if(chk){
            if(selectIdx.length === 1){
                setSelectIdx([0]);
                return;
            }
            setSelectIdx(arr=>arr.filter(item=>item !== data.id));
            return;
        }
        setSelectIdx(arr=>[...arr,data.id]);
    }
    
    return (
        <Wrap>
            {(list?.length === 1 && list[0].title === '') ?
                <></> 
                :
                <>
                    <NowSelectWrap border={border} padding={padding} background={background} onClick={()=>openClick(!openChk)}>
                        <BasicText theme='black'>{title}</BasicText>
                        {openChk ?
                            <BsChevronUp color={theme.black}></BsChevronUp>
                            : <BsChevronDown color={theme.black}></BsChevronDown>
                        }
                    </NowSelectWrap>
                    {openChk &&
                        <SelectWrap border={border} background={background}>
                            {list?.map((data)=>{
                                let chk = select?.some(item=>item.id === data.id);
                                return(
                                    <SelectItem key={`select-${data.id}`} padding={padding} hover={theme.itemBg} onClick={()=>itemClick(data)}>
                                        <CheckWrap chk={chk}>
                                            {chk &&
                                                <BsCheckLg size={9} color='#111'></BsCheckLg>
                                            }
                                        </CheckWrap>
                                        <BasicText theme='black'>{data.title}</BasicText>
                                    </SelectItem>
                                )
                            })}
                        </SelectWrap>
                    }
                </>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const NowSelectWrap = styled.div<{border?:string,padding?:string,background?:string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${props=>props.border ?? 'none'};
    padding: ${props => props.padding ?? '0'};
    cursor: pointer;
    border-radius : 2px;
    background-color: ${props=>props.background ?? 'transparent'};
`;
const SelectWrap = styled.div<{border?:string,background?:string}>`
    width:100%;
    border: ${props=>props.border ?? 'none'};
    border-top: none;
    background-color: ${props=>props.background ?? 'transparent'};
`;

const SelectItem = styled(Row)<{padding?:string,hover?:string}>`
    padding: ${props => props.padding ?? '0'};
    cursor: pointer;
    &:hover{background-color: ${props=>props.hover}}
`;

const CheckWrap = styled(Center)<{chk?:boolean}>`
    width: 15px;
    height:15px;
    border: 1px solid #fff;
    margin-right: 8px;
    background-color: ${props=>props.chk ? '#f7f7f7' : 'transparent'};
`;