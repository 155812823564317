import React from 'react'
import styled from '@emotion/styled';
import BasicText from '../../basic/BasicText';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { Link } from 'react-router-dom';
import { Center } from '../../../style/basic/commonStyle';

export default function LogoItem({logoType,logo,logoMargin,logoSize}:any) {
    const theme = useTheme();
    return (
        <>
            <ImgWrap to={'/'} logomargin={logoMargin}>
                {logoType === 'img' ? 
                    <Img logosize={logoSize} alt='logo-img' src={logo}></Img> 
                : logoType === 'text' && 
                <Center>
                    <LogoImg alt='logo-img' src={'./img/logo/meca_token.png'}></LogoImg>
                    <BasicText fontSize={logoSize?.fontSize === undefined ? 'auto' : logoSize.fontSize} theme={'black'}>{logo}</BasicText>
                </Center>
                }
            </ImgWrap>
        </>
    )
}

const ImgWrap = styled(Link)<{logomargin?:string}>`
    margin: ${props=>props.logomargin ?? '0px'};
`;
const Img = styled.img<{logosize?:any}>`
    width: ${props => props.logosize.w === undefined ? 'auto' :  props.logosize.w};
    height: ${props => props.logosize.h === undefined ? 'auto' :  props.logosize.h};
`;

const LogoImg = styled.img`
    max-height: 36px;
    margin-right: 5px;
`;
