import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import NoneListPart from '../part/NoneListPart'
import InfiniteListPart from '../part/InfiniteListPart';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import NewsItem from '../../home/item/NewsItem';
import NftItem from '../item/NftItem';
import NoListItem from '../../home/item/NoListItem';

export interface listType {
    type:'none'|'number'|'infinite';
    list?:any;
    count?:number;
    mCount?:number;
    gap?:number;
    nowPage?:number;
    totalPage?:number;
    changeNowPage?:any;
    listCount?:number;
    children?:any;
    link?:string;
    page?:string;
}

export interface viewCountType{
    pcCount?:string;
    mCount?:string;
}
/**
 * 
 * @param type 어떤 리스트를 할건지 'none'|'number'|'infinite';
 * @param list api리스트 데이터;
 * @param count pc에서 몇개씩 보여줄건지;
 * @param mCount m에서 몇개씩 보여줄건지;
 * @param gap 아이템들의 간격;
 * @param nowPage 현재 페이지;
 * @param totalPage 전체페이지;
 * @param changeNowPage 현재 페이지 변경 함수;
 * @param listCount 하단의 페이지 번호를 몇개씩 보여줄건지;
 * @param children none | number - import 한곳에서 list.map / infinite - ListPage.tsx 에서 list.map
 * @param link url
 * @param page infinite type
 */
export default function ListPage({type,list,count=1,mCount=1,gap=10,nowPage=1,totalPage=1,changeNowPage,listCount=10,children,link,page}:listType) {
    const theme = useTheme();
    const [viewCount,setViewCount] = useState({pcCount:`1`,mCount:`1`});
    
    // const [newList, setNewList] = useState(list);
    // useEffect(()=>{
    //     if(type!=='infinite') return;
    //     console.log({nowPage,list});
    //     if(nowPage === 1){
    //         setNewList(list);
    //     }
    //     if(nowPage!==1) {
    //         setNewList((arr:any)=>[...arr,...list]);
    //     }
    // },[type,list]);
    
    useEffect(()=>{
        let pc='';
        let m='';
        for (let i = 0; i < count; i++) {
            pc += `1fr `;
        }
        for (let i = 0; i < mCount; i++) {
            m += `1fr `;
        }
        setViewCount({pcCount:pc,mCount:m});
    },[count]);
    
    return (
        <Wrap>
            {(type==='none' || type=='number') &&
                <NoneListPart type={type} viewCount={viewCount} gap={gap} nowPage={nowPage} totalPage={totalPage} changeNowPage={changeNowPage} listCount={listCount}>
                    {children}
                </NoneListPart>
            }
            {type === 'infinite' &&
                <InfiniteListPart type={type} viewCount={viewCount} gap={gap} changeNowPage={changeNowPage} nowPage={nowPage} totalPage={totalPage}>
                    {list.length === 0 ?
                        <NoListItem></NoListItem>
                        : list?.map((data:any,index:any)=>{
                            if(page === 'collection'){
                                return(
                                    <NftItem key={index} title={data.nft_name} background={theme.itemBg} fontSize={20} mFontSize={16} link={`${link}/${data.nft_id}`} img={data.nft_img}></NftItem>
                                )
                            }
                            if(page === 'news'){
                                return(
                                    <NewsItem key={index} link={`${link}/${data.notice_id}`} {...data}></NewsItem>
                                )
                            }
                        })
                    }
                </InfiniteListPart>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;