import { css } from '@emotion/react';
import styled from '@emotion/styled'
import { resolvePtr } from 'dns/promises';
import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

interface gridItemType{
    bottomState ?: string;
    children?:any;
    background?:string;
    text?:string;
    fontSize?:number;
    mFontSize?:number;
    link?:string;
    imgHeight?:string;
    position?:string;
    title?:string;
    id?:number;
    price?:number;
    lh?:number;
    type?:string;
    nft_price_eth?:number;
    nft_name?:string;
    nft_status?:number;
    style?:any;
    borderNo?:any;
}

export default function GridItem({bottomState,children,background,text,fontSize,mFontSize,link='#',imgHeight,position,title,type,...rest}:gridItemType) {
    
    return (
        <ContentWrap style={rest.style}>
            {link === 'no' ? 
                <Wrap bottomstate={bottomState} background={background} border={rest.borderNo}>
                    {type === 'about' ?
                        <>
                            {children}
                        </>
                        :<>
                            <ItemWrap imgHeight={imgHeight}>
                                {children}
                            </ItemWrap>
                            {text !== undefined &&
                                <TextWrap>
                                    <BasicText theme='black' fontSize={fontSize} mFontSize={mFontSize}>{text}</BasicText>
                                </TextWrap>
                            }
                        </>
                    }
                </Wrap>
                : <WrapLink to={link} bottomstate={bottomState} background={background}>
                    <ItemWrap imgHeight={imgHeight} type={type}>
                        {type === 'nft' ?
                            <NftImgWrap>
                                {children}
                            </NftImgWrap> :
                            children
                        }
                    </ItemWrap>
                    {text !== undefined &&
                        <TextWrap>
                            <BasicText theme='black' fontSize={fontSize} mFontSize={mFontSize}>{text}</BasicText>
                        </TextWrap>
                    }
                    {position==='main' &&
                        <MainItemWrap>
                            <BasicText theme='black' fontSize={18} mFontSize={mFontSize}>{rest.nft_name}</BasicText>
                            <PriceWrap>
                                <PriceLeftWrap>
                                    <img src='/img/erc.png' alt='eth'></img>
                                    <PriceItem>
                                        <BasicText theme='black' lh={rest.lh} fontSize={13}>ETH</BasicText>
                                        <BasicText theme='black' lh={rest.lh} fontSize={18}>{rest.nft_price_eth}</BasicText>
                                    </PriceItem>
                                </PriceLeftWrap>
                                {rest.nft_status === 0 ? 
                                    <PriceRightWrap bgColor='#2f5f89' borderColor='#00cdfe'>Buy Now</PriceRightWrap>
                                    : rest.nft_status === 2 && <PriceRightWrap>Closed</PriceRightWrap>
                                }
                            </PriceWrap>
                        </MainItemWrap>
                    }
                </WrapLink>
            }
        </ContentWrap>
    )
}

const ContentWrap = styled.div`
    border-radius: ${({theme}:any) => theme.borderRadius};
`;

const Wrap = styled.div<{bottomstate?:string,background?:string, border?:boolean}>`
    width:100%;
    position: relative;
    display: block;
    background-color: ${props=>props.background ?? 'transparent'};
    border: ${props => props.border ? 'none':'1px solid #fff'};
    /* border: 1px solid #fff; */
    border-radius: ${({theme}:any) => theme.borderRadius};
    overflow: hidden;
    &::before{content:''; display:block; padding-bottom:${props=>props.bottomstate ?? '100%'};}
`
const WrapLink = styled(Link)<{bottomstate?:string,background?:string}>`
    width:100%;
    position: relative;
    display: block;
    background-color: ${props=>props.background ?? 'transparent'};
    border-radius: ${({theme}:any) => theme.borderRadius};
    border: 1px solid #fff;
    &::before{content:''; display:block; padding-bottom:${props=>props.bottomstate ?? '100%'};}
    overflow: hidden;
`

const ItemWrap = styled.div<{imgHeight?:string,type?:string}>`
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    overflow: hidden;
    &::before{content:''; padding-bottom:100%; display:block;}
    border-radius: ${({theme}:any)=>theme.borderRadius};
    overflow: hidden;
    text-align: center;
    height: ${props=>props.imgHeight ?? 'auto'};
    &>img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        height: 100%;
        max-width: 100%;
        transition: 0.3s;
    }
    @media ${({theme}:any)=>theme.media.m}{
        height: auto;
    }
    ${(props)=>props.type === 'nft' ?
        css`
            &:hover{
                &>img{transform:translate(-50%,-50%) scale(1.05);}
            }
        `:``
    }
`;

const NftImgWrap = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:95%;
    overflow: hidden;
    &>img{width:100%; transition: 0.3s;}
    &:hover{
        &>img{transform: scale(1.05);}
    }
`;

const TextWrap = styled(Center)`
    padding-bottom: 15px;
`;

const MainItemWrap = styled.div`
    padding: 10px 15px;
`;

const PriceWrap = styled(RowBetween)`
    margin-top:10px;
    @media ${({theme}:any)=> theme.media.m}{
        flex-wrap: wrap;
    }
`;
const PriceLeftWrap = styled(Row)`
    &>img{width:32px;}
`;
const PriceItem = styled.div`
    margin-left: 8px;
`;
const PriceRightWrap = styled.button<{bgColor?:string,borderColor?:string}>`
    flex-shrink: 0;
    color: #fff;
    border-radius: 50px;
    background-color: ${props=>props.bgColor ?? 'transparent'};
    border: 1px solid ${props=>props.borderColor ?? 'transparent'};
    padding: 8px 14px;
    box-shadow: 0 0 4px ${props=>props.borderColor ?? 'transparent'};
    @media ${({theme}:any)=>theme.media.m}{
        margin-top: 14px;
        padding: 8px 12px;
    }
`;