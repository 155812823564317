import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Center, MaxWidthWrap, PaddingWrap } from '../../../style/basic/commonStyle';
import CenterTitleItem from '../../common/item/CenterTitleItem';
import SearchPage from '../../search/page/SearchPage';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { BiSearch } from 'react-icons/bi';
import SelectPart from '../../common/part/SelectPart';
import ListPage from '../../list/page/ListPage';
import { apiNftCategoryList, apiNftList } from '../../../api/mainApi';
import BasicText from '../../basic/BasicText';
import NoListItem from '../../home/item/NoListItem';
import NftItem from '../../list/item/NftItem';
import GridItem from '../../common/item/GridItem';
import { IMG_URI } from '../../../api/config';
import { useLocation, useNavigate } from 'react-router';
import useGetUrlParams from '../../../utils/custom-hooks/common/useGetUrlParams';

const BG_LIST = {id:1,title:''}

const RoundList = [
    {id:1,title:'One'},
    {id:2,title:'Two'},
    {id:3,title:'Three'},
    {id:4,title:'Four'},
    {id:5,title:'Five'},
    {id:6,title:'Special'},
];

const POINT_LIST ={id:1,title:''}


const CHIP_LIST = {id:1,title:''}


const listItem = {nft_id:0,nft_desc:'',nft_img:'',nft_price_eth:0,nft_price_meca:0,nft_name:'',nft_create_time:0,nft_like_count:0,nft_status:0,nft_view_count:0,nft_properties:[{id:0,title:'',value:''}]};

export default function CollectionPage() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const page = useGetUrlParams('page');
    const sel1 = useGetUrlParams('select1',0);
    const sel2 = useGetUrlParams('select2',0);
    const sel3 = useGetUrlParams('select3',0);
    const sel4 = useGetUrlParams('select4',0);
    const [bgList,setBgList] = useState([BG_LIST]);
    const [pointList,setPointList] = useState([POINT_LIST]);
    const [chipList,setChipList] = useState([CHIP_LIST]);
    const sc = useGetUrlParams('sc');
    
    const [search,setSearch] = useState('');
    const [resSearch,setResSearch] = useState(sc);

    const [nowPage,setNowPage] = useState(Number(page));
    const [totalPage,setTotalPage] = useState(5);
    const [list,setList] = useState([listItem]);

    // select 결과
    const [select1,setSelect1] = useState([{id:0,title:''}]);
    const [sel1Txt,setSel1Txt] = useState(sel1);
    const [select2,setSelect2] = useState([{id:0,title:''}]);
    const [sel2Txt,setSel2Txt] = useState(sel2);
    const [select3,setSelect3] = useState([{id:0,title:''}]);
    const [sel3Txt,setSel3Txt] = useState(sel3);
    const [select4,setSelect4] = useState([{id:0,title:''}]);
    const [sel4Txt,setSel4Txt] = useState(sel4);
    
    useEffect(()=>{
        // 카테고리
        getCategoryList();
    },[])

    // 검색 결과값
    const resSearchClick = (ca:any,value:string) =>{
        setResSearch(value);
        setNowPage(1);
    }
    
    // url -> select
    const selectTxtChange = (sel?:any,list?:any,setSelect?:any) =>{
        if(sel === 0) return;
        let arr = sel.split(',');
        let res = list?.filter((item:any)=> arr.some((data:any)=> Number(data) === item.id));
        setSelect(res);
        return res;
    }

    useEffect(()=>{
        let newSelect1 = selectTxtChange(sel1Txt,bgList,setSelect1);
        let newSelect2 = selectTxtChange(sel2Txt,RoundList,setSelect2);
        let newSelect3 = selectTxtChange(sel3Txt,pointList,setSelect3);
        let newSelect4 = selectTxtChange(sel4Txt,chipList,setSelect4);
        if(location.search === '?page=1&sc=&select1=0&select2=0&select3=0&select4=0'){
            if(nowPage !== 1){
                navigate(`?page=${nowPage}&sc=${resSearch}&select1=${sel1Txt}&select2=${sel2Txt}&select3=${sel3Txt}&select4=${sel4Txt}`);
            }
        }else{
            navigate(`?page=${nowPage}&sc=${resSearch}&select1=${sel1Txt}&select2=${sel2Txt}&select3=${sel3Txt}&select4=${sel4Txt}`);
        }
        getList(newSelect1,newSelect2,newSelect3,newSelect4);
    },[nowPage,resSearch,sel1Txt,sel2Txt,sel3Txt,sel4Txt]);

    const getList = async(newSel1:any,newSel2:any,newSel3:any,newSel4:any) =>{
        const res = await apiNftList(nowPage,9,resSearch,newSel1,newSel2,newSel3,newSel4);
        
        if(!res.result){
            setList([listItem]);
            setNowPage(1);
            setTotalPage(1);
            return;
        }
        setTotalPage(res.total);
        setList(res.data);
    }

    // 카테고리 리스트
    const getCategoryList = async() =>{
        const res = await apiNftCategoryList();
        if(!res.result){
            setBgList([BG_LIST]);
            setPointList([POINT_LIST]);
            setChipList([CHIP_LIST]);
        }
        
        setBgList(res.data.bg);
        setPointList(res.data.point);
        setChipList(res.data.chip);
    }
    
    return (
        <Wrap>
            <PaddingWrap padding='100px 0'>
                <CenterTitleItem title='Meca Collection'></CenterTitleItem>
            </PaddingWrap>
            <MaxWidthWrap>
                <ContentWrap>
                    <SelectWrap>
                    {/* <SelectPart title='Round' select={select2} list={RoundList} background={theme.listItemBg} border={`1px solid ${theme.more}`} padding='7px 10px' setSel={setSel2Txt}></SelectPart> */}
                        <SelectPart title='Round' select={select2} list={RoundList} background={theme.listItemBg} border={`1px solid ${theme.more}`} padding='7px 10px' setSel={setSel2Txt}></SelectPart>
                        <SelectPartWrap>
                            <SelectPart title='Background' select={select1} list={bgList} background={theme.listItemBg} border={`1px solid ${theme.more}`} padding='7px 10px' setSel={setSel1Txt}></SelectPart>
                        </SelectPartWrap>
                        <SelectPartWrap>
                            <SelectPart title='PointColor' select={select3} list={pointList} background={theme.listItemBg} border={`1px solid ${theme.more}`} padding='7px 10px' setSel={setSel3Txt}></SelectPart>
                        </SelectPartWrap>
                        <SelectPartWrap>
                            <SelectPart title='Chip' select={select4} list={chipList} background={theme.listItemBg} border={`1px solid ${theme.more}`} padding='7px 10px' setSel={setSel4Txt}></SelectPart>
                        </SelectPartWrap>
                    </SelectWrap>
                    <ListWrap>
                        <SearchPage background={theme.listItemBg} setResSearch={resSearchClick} value={search} setValue={setSearch} color={theme.black} padding='8px 10px' border={`1px solid ${theme.more}`} icon={<BiSearch color={theme.black} size={20}></BiSearch>}></SearchPage>
                        <ListContentWrap>
                            {list[0].nft_id === 0 ?
                                <Center style={{minHeight:'300px'}}>
                                    <BasicText theme='black'>No items to display</BasicText>
                                </Center>
                                 :
                                <ListPage list={list} type='number' count={3} mCount={1} gap={15} nowPage={nowPage} totalPage={totalPage} changeNowPage={setNowPage} link='/detail' page='collection'>
                                    {list.length === 0 ?
                                        <NoListItem></NoListItem>
                                        : list?.map((data:any,index:any)=>{
                                            return(
                                                <GridItem key={`nft-list-${index}`} background={theme.itemBg} text={data.nft_name} fontSize={20} mFontSize={16} link={`/detail/${data.nft_id}`} type='nft'>
                                                    <img alt='nft-img' src={IMG_URI+data.nft_img}></img>
                                                </GridItem>
                                                // <NftItem key={index} title={data.nft_name} background={theme.itemBg} fontSize={20} mFontSize={16} link={`/detail/${data.nft_id}`} img={data.nft_img}></NftItem>
                                            )
                                        })
                                    }
                                </ListPage>
                            }
                        </ListContentWrap>
                    </ListWrap>
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``

const ContentWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-columns: 1fr;
    }
`;

const SelectWrap = styled.div`
    width: 100%;
`;
const SelectPartWrap = styled.div`
    margin-top: 15px;
`;

const ListWrap = styled.div`
    width:100%;
`;

const ListContentWrap = styled.div`
    margin-top: 30px;
`;