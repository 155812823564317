import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../components/home/page/HomePage';
import { useDispatch } from 'react-redux';
import { MaxWidthWrap, Row } from '../style/basic/commonStyle';
import HeaderPage from '../components/header/page/HeaderPage';
import { useTheme } from '../utils/custom-hooks/common/useTheme';
import { thunkTheme } from '../store/common/theme';
import styled from '@emotion/styled';
import BtnItem from '../components/header/item/BtnItem';
import CollectionPage from '../components/Collection/page/CollectionPage';
import ScrollTop from '../utils/custom-hooks/common/ScrollTop';
import FooterPage from '../components/footer/page/FooterPage';
import ComingSoonPage from '../components/coming/page/ComingSoonPage';
import DetailPage from '../components/detail/page/DetailPage';
import NewsPage from '../components/news/page/NewsPage';
import NewsDetailPage from '../components/newsDetail/page/NewsDetailPage';
import TermsPage from '../components/terms/page/TermsPage';
import {MENU,FOOTER_MENU} from '../data/menu';
import MyPage from '../components/mypage/page/MyPage';
import { useNavigate } from 'react-router-dom';
import useScrollMove from '../utils/custom-hooks/common/useScrollMove';
import { useWeb3React } from '@web3-react/core';
import {InjectedConnector} from '@web3-react/injected-connector';
import { apiUserInfo, apiUserJoin, apiUserLog, apiUserWalletAdd } from '../api/mainApi';
import EmailDialogPage from '../components/common/page/EmailDialogPage';
import { setUserInfo, setUserInfoLogOutActionCreator } from '../store/common/user';
import { useUserInfo } from '../utils/custom-hooks/common/useUserInfo';
import ModalDialogPage from '../components/common/page/ModalDialogPage';
import { ETH_ID, BSC_ID } from '../api/config';

export default function Router(){
    const dispatch = useDispatch();
    const [openChk,setOpenChk] = useState(false);
    const [chainModal,setChainModal] = useState(false);
    const [chainText,setChainText] = useState('');
    const {about,collection,roadmap,faq} = useScrollMove();
    const user = useUserInfo();

    const {
        account,
        activate,
        deactivate,
        chainId,
    } = useWeb3React();
    
    useEffect(()=>{
        dispatch(thunkTheme('dark'));
    },[])

    useEffect(()=>{
        if(user.user_id === 0 || user.user_logout ) return;
        userLog();
    },[user.user_id])

    const theme = useTheme();
    const navigate = useNavigate();

    const goMyPage = () =>{
        navigate(`/myPage?page=1`);
    }

    const logoSize = {
        fontSize:'24px',
    }

    const userLog = async()=>{
        const res = await apiUserLog(user.user_id,user.user_wallet_eth,user.user_wallet_bsc);
    }

    const menuClickEvent = (event:any,link:any) =>{
        event.preventDefault();
        if(link === '/terms'){
            navigate('/terms');
            return;
        }
        
        navigate('/');
        setTimeout(() => {
            if(link === '/' || link === '//'){
                window.scrollTo(0, 0);
                return;
            }
            if(link === '/#about'){
                about.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }
            if(link === '/#collection'){
                collection.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }
            if(link === '/#roadmap'){
                roadmap.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }
            if(link === '/#faq'){
                faq.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                return;
            }
        }, 200);
    }
    
    //지갑연결
    const Injected = new InjectedConnector({});

    //지갑연결
    const connect = (type?:string) =>{
        // window.location.href='https://metamask.app.link/dapp/meka.main.cemonemo.co.kr/';
        if ((window as any).ethereum === undefined) {
            // 지갑이 설치 안되어있으면 설치 페이지를 오픈한다.
            alert('Please install metamask.');
            window.open(
              `https://metamask.io/download.html`,
              "_blank"
            );
            return;
        }
        activate(Injected);
        getUserInfo(type);
    }

    useEffect(()=>{
        if(user.user_logout) return;
        //로그아웃이 true이면 metamask connect x
        connect();
    },[user.user_logout]);

    //db에 계정이 없다면
    const userJoin = async() =>{
        const res = await apiUserJoin(account,chainId);
        
        if(!res.result){
            return;
        }
        return;
    }

    const userWalletAdd = async(user_id?:number) =>{
        const res = await apiUserWalletAdd(user_id,account,chainId);
        return res;
    }

    //지갑연결 후 지갑주소로 db계정
    const getUserInfo = async(type?:string) =>{
        if(chainId === undefined) return;
        // if(user.user_wallet_eth !== account || user.user_wallet_bsc !== account){
            if(chainId === ETH_ID || chainId === BSC_ID){
                const res = await apiUserInfo(account,chainId);
                if(!res.user_chk){
                    //db에 계정이 없다면
                    await userJoin();
                    return;
                }
                
                if(res.data.user_wallet_eth==='"'){
                    dispatch(setUserInfoLogOutActionCreator());
                    chainModalOpen('ETH');
                    await userWalletAdd(res.data.user_id);
                    return;
                }else{
                    setChainModal(false);
                }
                if(res.data.user_wallet_bsc===''){
                    dispatch(setUserInfoLogOutActionCreator());
                    chainModalOpen('BSC');
                    await userWalletAdd(res.data.user_id);
                    return;
                }else{
                    setChainModal(false);
                }
                if((res.data.user_email_show === 0 && res.data.user_email === '')){
                    setOpenChk(true);
                }else{
                    setOpenChk(false);
                }
                //db에 계정있으면
                dispatch(setUserInfo({
                    user_id:res.data.user_id,
                    user_email:res.data.user_email,
                    user_profile:res.data.user_profile,
                    user_wallet_bsc: res.data.user_wallet_bsc,
                    user_wallet_eth: res.data.user_wallet_eth,
                    user_point:res.data.user_point,
                    user_logout: false,
                }))
            }else{
                chainModalOpen('');
                dispatch(setUserInfoLogOutActionCreator());
            }
        // }
    }

    useEffect(()=>{
        if(!account) return;
        if(chainId === undefined) return;
        //지갑주소가 있으면
        getUserInfo();
    },[account,chainId]);

    useEffect(()=>{
        let timer = setTimeout(() => {
            // 지갑 로그아웃 했을때
            if(!account){
                dispatch(setUserInfoLogOutActionCreator());
                return;
            }
        }, 150);
        // 로그인되어있어도 처음에 undefined 나와서 timeout
        return () => clearTimeout(timer);
    },[account])
    
    //로그아웃
    const logoutClick = () =>{
        dispatch(setUserInfoLogOutActionCreator());
        deactivate();
    }

    //네트워크 모달
    const chainModalOpen = (text:string) =>{
        setChainModal(true);
        setChainText(text);
    }

    return (
        <Wrap>
            <ScrollTop></ScrollTop>
            <ModalDialogPage openChk={chainModal} setOpenChk={setChainModal} chainChk={chainText}></ModalDialogPage>
            <EmailDialogPage openChk={openChk} setOpenChk={setOpenChk} userId={user.user_id}></EmailDialogPage>
            <HeaderWrap background={theme.header}>
                <MaxWidthWrap>
                    <HeaderPage 
                        addItem={
                            (user.user_id==='' || user.user_id === 0) ?
                            <BtnItem clickEvent={()=>connect('btn')} size={13} text='Connect To Wallet' borderColor={theme.btnBorder} mPadding={'4px 6px'}></BtnItem>
                            : <Row>
                                <BtnItem clickEvent={goMyPage} text='MyPage' borderColor={theme.btnBorder} size={13}></BtnItem>
                                <BtnItem clickEvent={logoutClick} text='Logout' borderColor={theme.btnBorder} size={13} margin={'0 0 0 8px'}></BtnItem>
                            </Row>
                        } 
                        addPosition='right' logoSize={logoSize} logoPosition='left' menuPosition='center' logoType='text' logo='MECA' menu={MENU} menuMarginLeft='60px' wrapPadding='18px 0' menuClickEvent={menuClickEvent}></HeaderPage>
                </MaxWidthWrap>
            </HeaderWrap>
            <HeaderSpaceWrap></HeaderSpaceWrap>
            <Routes>
                <Route path="/" element={<HomePage aboutRef={about} collectionRef={collection} roadmapRef={roadmap} faqRef={faq}/>} />
                <Route path='/coming' element={<ComingSoonPage count={new Date().getTime()+100000000}/>}></Route>
                <Route path="/collection" element={<CollectionPage/>} />
                <Route path="/detail/:pId" element={<DetailPage></DetailPage>}></Route>
                <Route path='/press/' element={<NewsPage></NewsPage>}></Route>
                <Route path='/pressDetail/:pId' element={<NewsDetailPage></NewsDetailPage>}></Route>
                <Route path='/myPage' element={<MyPage></MyPage>}></Route>
                <Route path='/terms' element={<TermsPage></TermsPage>}></Route>
                {/* <Route path='*' element={<ComingSoonPage count={new Date().getTime()+100000000}/>}></Route> */}
            </Routes>
            <FooterWrap background={theme.header}>
                <FooterPage menu={FOOTER_MENU} logo='MECA' type='text' logoSize={logoSize} menuClickEvent={menuClickEvent}></FooterPage>
            </FooterWrap>
        </Wrap>
    )
}
const Wrap = styled.div`
    overflow: hidden;
`;

const HeaderWrap = styled.div<{background?:string}>`
    background-color: ${props=>props.background ?? '#00001e'};
    position: fixed;
    width: 100%;
    left:0;
    top:0;
    &::before{
        content:''; width:100%; position: absolute; height: 100%;
        box-shadow: 0 0px 28px rgba(255,255,255,0.4);
    }
    &>div{position: relative;}
    z-index: 50;
`;

const HeaderSpaceWrap = styled.div`
    height: 72px;
`;

const FooterWrap = styled.div<{background?:string}>`
    background-color: ${props=>props.background ?? '#00001e'};
`;