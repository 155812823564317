import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}
/**
 * user_security_level : 1-Master, 2-Manager
 */
export interface userInfo {
    user_id: any;
    user_email?: string;
    user_profile?:string;
    user_wallet_bsc?:string;
    user_wallet_eth?:string;
    user_logout?:boolean;
    user_point:number;
}

const initialState: userInfo = {
  user_id: 0,
  user_email: '',
  user_profile:'',
  user_wallet_bsc:'',
  user_wallet_eth:'',
  user_logout:false,
  user_point:0,
};

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
      user_id: action.payload.data.user_id ?? state.user_id,
      user_email: action.payload.data.user_email ?? state.user_email,
      user_profile: action.payload.data.user_profile ?? state.user_profile,
      user_wallet_bsc: action.payload.data.user_wallet_bsc ?? state.user_wallet_bsc,
      user_wallet_eth: action.payload.data.user_wallet_eth ?? state.user_wallet_eth,
      user_logout: action.payload.data.user_logout ?? state.user_logout,
      user_point: action.payload.data.user_point ?? state.user_point,
    })
,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => ({
    ...initialState,user_logout:true,
  }),
}, initialState);
