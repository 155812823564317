import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { recentBlockList } from './block/recentBlockList';
import { themeColor } from './common/theme';
import { securityChart } from './security/securityChart';
import { securityLog } from './security/securityLog';
import { generateBlock } from './block/generateBlock';
import { recentTransactionList } from './transaction/recentTransactionList';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { globalLoadingState } from './common/globalLoading';
import { nodePlatformModalState } from './node/nodePlatformModal';
import { transactionCount } from './transaction/transactionCount';
import { globalToast } from './common/globalToast';
import { globalHistory } from './common/globalHistory';
import { blockBarChar } from './block/blockBarChar';
import { blockNodeBarChart } from './block/blockNodeBarChart';
import { fullScreen } from './common/fullScreen';
import { globalDialog } from './common/globalDialog';
import {user} from './common/user';

const persistConfig = {
    key: "meka",
    // localStorage에 저장합니다.
    storage,
    whitelist: ['fullScreen','user'],
    blacklist: ["nodePlatformModalState", "generateBlock", "transactionCount", "recentBlockList", "themeColor", "recentTransactionList", "securityChart", "securityLog"]
};

const rootReducer = combineReducers({
    themeColor,
    recentBlockList,
    recentTransactionList,
    securityChart,
    securityLog,
    generateBlock,
    globalLoadingState,
    nodePlatformModalState,
    transactionCount,
    globalToast,
    globalHistory,
    blockBarChar,
    blockNodeBarChart,
    fullScreen,
    globalDialog,
    user,
});

export type RootState = ReturnType<typeof rootReducer>;

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default function reducer() {
    return persistReducer(persistConfig, rootReducer);
}
