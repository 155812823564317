import styled from '@emotion/styled';
import React from 'react'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';

export default function TermsPage() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <h1>TERMS AND CONDITIONS OF USE</h1>
                <h5>Last Updated: 15 JAN 2023</h5>
                <br />
                <br />
                <p>
                Before using the services, you must carefully read these Terms and
                Conditions of Use ("Terms", "Agreement") of https://mecazone.com
                ("Website", "Site"). These Terms govern your access to and use of this
                Website, as well as all content (images, text, software, graphics, data,
                messages, or any other information, and any other website content),
                functionality of the Website, and services offered on or through the
                Website, including the purchase and usage of MECA NFTs (all of the
                foregoing, collectively, will be referred to as the "Services"). The
                Services are owned, operated, licensed, or controlled by the Company and
                its affiliates. These Terms apply to all users and others ("You", "User")
                who access or use the Website and/or the Services provided therein and/or
                to anyone legally possessing MECA NFTs. The Company’s Privacy Policy and
                all additional terms, guidelines, and rules set forth on the Website shall
                be interpreted by reference to these Terms. By connecting Your
                cryptocurrency wallet (e.g. MetaMask) to the Services, indicating Your
                e-mail address in the respective section of the Website or otherwise using
                or accessing the Services, You acknowledge that You have read and agree to
                these Terms.
                </p>
                <br />
                <h2>1. DEFINITIONS AND PARTIES INVOLVED</h2>
                <ul>
                <li>
                    "Company" is the operator of (https://mecazone.com) When first-person
                    pronouns are used in this Agreement (Us, We, Our, Ours, etc.), these
                    provisions are referring to the Company.
                </li>
                <li>
                    "You" or "User" refers to the User of this Website and/or Services. When
                    second-person pronouns are used in this Agreement, such as "Your/Yours"
                    these provisions are referring to the User. You become a User by
                    accessing this Website in any way and/or by using our Services in any
                    manner. Nobody is authorized to access the Site or use the Services
                    unless they have signed this Agreement. Such signature does not need to
                    be a physical signature, since electronic acceptance of this Agreement
                    is permitted by the Electronic Signatures in Global and National
                    Commerce Act (E-Sign Act), Regulation (EU) No 910/2014 of the European
                    Parliament and of the Council of 23 July 2014 and other federal and
                    state laws. You manifest Your agreement to this contractual Agreement by
                    using the Website and/or the Services or by taking any act demonstrating
                    Your assent thereto. You should understand that this has the same legal
                    effect as You placing Your physical signature on any other legal
                    contract. If You click any link, button, or other place provided to You
                    in any part of Our Site’s interface, then this will indicate that You
                    have legally agreed to all of these Terms. Additionally, by using Our
                    Site or any of our Services in any manner, You understand and agree that
                    We will consider such use as Your affirmation of Your complete and
                    unconditional acceptance to all terms in this Agreement.
                </li>
                <li>
                    "Collector" refers herein to the User, who has at least one MECA NFT in
                    its possession on the cryptocurrency wallet irrespectively of the legal
                    ground of such ownership (initial purchase (minting) of MECA NFT from
                    the Company, purchase of MECA NFT after secondary sale, receiving MECA
                    NFT as a gift, etc.).
                </li>
                <li>
                    "MECA NFT" / "NFT" refers herein to a limited-edition digital asset that
                    embodies or includes certain artwork created, owned, or licensed by the
                    Company, each of which is a non-fungible token that uses smart contracts
                    on the Ethereum blockchain ("Smart Contracts") and the ownership of
                    which can be transferred via gift, purchase, or sale.
                </li>
                <li>
                    "Cryptocurrency Wallet" / "Wallet" refers herein to an electronic
                    (cryptocurrency) wallet that allows You to purchase, store, and engage
                    in transactions using cryptocurrency and NFTs. To be able to use Our
                    Services You will need to connect to Our Website and have Your Wallet
                    connected and supported on MetaMask and/or other Wallet that is
                    supported and/or will be supported by the Company. Wallet shall allow
                    You to purchase, store, and engage in transactions using the native
                    cryptocurrency of the Ethereum, Ether (ETH) and the Binance Smart Chain,
                    MECA (meca).
                </li>
                </ul>
                <br />
                <h2>2. RECREATIONAL PURPOSE OF OUR SERVICES</h2>
                <ul>
                <li>
                    Our Website provides Services to Users solely for entertainment and
                    recreational purposes.
                </li>
                <li>
                    EACH MECA NFT IS A UNIQUE NON-FUNGIBLE PIECE OF DIGITAL ARTWORK AND, AS
                    SUCH, IT IS A COMPLETE PRODUCT. ANY INFORMATION PROVIDED BY US OR ON THE
                    SITE IS FOR ENTERTAINMENT AND INFORMATIONAL PURPOSES ONLY AND SHALL NOT
                    BE CONSTRUED NOR IS INTENDED TO BE CONSTRUED AS AN OFFER TO SELL, OR A
                    SOLICITATION OF AN OFFER TO BUY ANY INTEREST IN ANY SECURITY, ENTITY,
                    INVESTMENT VEHICLE OR CONTRACT, INCLUDING, BUT NOT LIMITED TO, WITH
                    RESPECT TO NFTS.
                </li>
                <li>
                    YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE NO EXPECTATION OF PROFIT
                    ASSOCIATED WITH YOUR PURCHASE OF ANY NFTS AND YOU HAVE NO EXPECTATION TO
                    DERIVE PROFITS FROM THE NFTS AS A RESULT OF OUR EFFORTS OR THE EFFORTS
                    OF ANY THIRD PARTY.
                </li>
                </ul>
                <br />
                <h2>3. REQUIREMENTS FOR USAGE OF OUR SERVICES</h2>
                <ul>
                <li>
                    At a future date set by the Company, which will be displayed on the
                    Website, You will have an opportunity to purchase MECA NFTs directly
                    from the Company via a minting process. By minting the NFT, You agree to
                    comply with any terms, including licenses or payment rights that are
                    embedded within or otherwise included in any NFT that You purchase.
                </li>
                <li>
                    In order to access and use the Services, including purchasing the NFTs,
                    You will need to have a web browser and Wallet on MetaMask
                    (WalletConnect and/or other Wallet that is supported and/or will be
                    supported by the Company) that is compatible with Our NFTs and standard
                    on the Ethereum network. Before purchasing (minting) the NFT, You must
                    connect and unlock Your Wallet through the Website. Once You submit an
                    order to mint the NFT, Your order is passed on through the Wallet to the
                    applicable bridge extension, which completes the transaction on the
                    blockchain platform via the smart contract on Your behalf.
                </li>
                <li>
                    When You link Your Wallet, You understand and agree that You are solely
                    responsible for maintaining the security of Your Wallet and Your control
                    over any wallet-related authentication credentials, including any seed
                    phrase or private cryptocurrency keys, as well as NFTs or
                    cryptocurrencies that are stored in or are accessible through Your
                    Wallet. Any unauthorized access to Your Wallet by third parties could
                    result in the loss or theft of NFTs and/or other assets held in Your
                    Wallet and any associated wallets, including any linked financial
                    information. Company is not responsible for managing and maintaining the
                    security of Your Wallet. Company has no responsibility or liability to
                    You for any unauthorized access to or use of Your Wallet nor is the
                    Company responsible if You are unable to locate Your credentials from
                    Your Wallet.
                </li>
                <li>
                    The Ethereum blockchain, which We used in Our NFTs, provides an
                    immutable ledger of all transactions that occur on the blockchain. This
                    means that all MECA NFTs are outside the control of any one party,
                    including the Company, and are subject to various risks and
                    uncertainties. Company neither owns nor controls cryptocurrency wallets
                    (including MetaMask and WalletConnect), the Ethereum network, the
                    Ethereum blockchain, Your browser, or any other third-party site,
                    product, or service (including third-party wallets or marketplaces) that
                    You might access, visit, or use for the purpose of enabling You to use
                    the Services or to purchase, MECA NFTs. Except for transferring the
                    digital ownership of an NFT from the Company to the initial purchaser
                    through the Services, the Company has no responsibility with respect to
                    any secondary transactions of MECA NFTs. Company will not be liable for
                    the acts or omissions of any third parties, nor will Company be liable
                    for any damage that You may suffer as a result of Yur transactions with
                    MECA NFT or any other interaction with any third parties. You understand
                    that Your Ethereum public address will be made publicly visible whenever
                    You engage in a transaction with NFT. Aside from transferring control of
                    the MECA NFT to the initial purchasers, the Company has no control over
                    the transfer, storage, ownership or maintenance of the MECA NFT.
                </li>
                <li>
                    To use different NFT’s Bonuses You may need to have separate accounts on
                    websites, which may be operated by other legal entities. It is Your sole
                    responsibility to create and maintain such accounts. In case otherwise
                    stated, the Company is not the operator of such third-party websites,
                    and it is Your responsibility to cooperate and interact with such
                    websites. Prior to the usage of these websites, We encourage You to
                    carefully read the terms and conditions (and other relevant
                    documentation) of the said websites.
                </li>
                </ul>
                <br />
                <h2>4. MECA NFTS</h2>
                <ul>
                <li>
                    User has the possibility to become a Collector by purchasing MECA NFT.
                    There will be a limited number of MECA NFTs initially minted - only
                    7500. You can find a detailed description of the different types of MECA
                    NFTs in the special section of Our Website.
                </li>
                <li>
                    The initial sale of MECA NFTs will start at a date, that will be
                    explicitly specified in the special section of the Website. You can
                    purchase MECA NFT on the Website using a Wallet, as described above. All
                    sales are definite and final, and We do not guarantee that all
                    interested buyers will be able to purchase MECA NFTs before they are
                    sold out since there is a limited number of MECA NFTs. We disclaim all
                    liability in connection with the purchase of any MECA NFTs. It is Your
                    responsibility to ensure that Your purchase of any MECA NFT has been
                    completed. We reserve the right to terminate or end the initial sale of
                    MECA NFTs at any time.
                </li>
                <li>
                    Company is not and shall not be a party to any transaction or dispute
                    between any initial minter of an NFT and any subsequent owner of such
                    NFT, whether arising from any rights granted in that NFT or otherwise,
                    unless otherwise set forth in connection with such NFT.
                </li>
                </ul>
                <br />
                <h2>5. PRICING AND transaction FEES; PAYMENTS</h2>
                <ul>
                <li>
                    All pricing and payment terms for MECA NFTs are indicated at the moment
                    and at the point of sale or otherwise on the Website, and any payment
                    obligations You incur are binding at the time of initial purchasing
                    (minting). You may not substitute any other currency, whether
                    cryptocurrency or fiat currency, for any currency in which You have
                    contracted to pay at the time of purchase. For clarity, no fluctuation
                    in the value of any currency, whether cryptocurrency or otherwise, shall
                    impact or excuse Your obligations with respect to any obligation to pay
                    any amounts.
                </li>
                <li>
                    When each MECA NFT is sold for the first time, the agreement for sale is
                    between the Company and the initial Collector. If the initial Collector
                    decides to sell an MECA NFT ("Secondary Sale"), then the Company will
                    not be a party to any agreement between the applicable buyer, seller, or
                    facilitator of the secondary sale.
                </li>
                <li>
                    By placing an order on the Website or through the Services, You agree
                    that You are submitting a binding offer to purchase an MECA NFT, You
                    agree to pay all applicable fees associated with such transaction
                    (including "gas fee"), and You authorize the Company (and other third
                    parties as may be applicable) to automatically charge and collect such
                    fees from Your Wallet. In other words, any acquisition, purchase, trade,
                    or sale of NFTs will be conducted solely through the Ethereum, Binance
                    Smart Chain network, though the Website may serve as a platform that
                    facilitates such transactions. We have no control over these
                    transactions as or once they occur, nor do We have the ability to
                    reverse any transactions. You agree that We will have no liability to
                    You or to any third party for any claims or damages that may arise as a
                    result of any transactions pertaining to the Smart Contracts, or any
                    other transactions that will be conducted via the Ethereum, Binance
                    Smart Chain network.
                </li>
                <li>
                    It is the User's responsibility to decide whether any taxes apply to the
                    purchase of an MECA NFT You perform through the Services, and to
                    withhold, report and remit the relevant funds to the appropriate tax
                    authorities. You agree that the Company is not responsible for
                    determining whether any taxes apply to Your transfers or for collecting,
                    reporting, withholding, or remitting any taxes arising from any purchase
                    of an MECA NFT and does not act as Your tax agent.
                </li>
                </ul>
                <br />
                <h2>6. LICENSE AND INTELLECTUAL PROPERTY</h2>
                <ul>
                <li>
                    Our Website and Services contain images, content, text, software,
                    graphics, data, messages, or any other information.
                </li>
                <li>
                    Company’s rights to the Website and Services. You acknowledge and agree
                    that all content on the Website including, without limitation, text,
                    graphical images, all designs, photographs, music, video, software, MECA
                    NFT collection, scripts and trademarks, service marks, "look and feel",
                    organization, and logos contained therein (collectively referred to as
                    the "Proprietary Materials"), are owned by and licensed to the Company.
                    All Proprietary Materials are subject to copyright, trademark and/or
                    other rights under the laws of applicable jurisdictions, including
                    domestic laws, foreign laws, and international conventions. We reserve
                    all Our rights over Our Proprietary Materials. Except as otherwise
                    explicitly permitted, You agree not to copy, modify, publish, transmit,
                    distribute, participate in the transfer or sale of, create derivative
                    works of, or in any other way exploit, in whole or in part, any content
                    of the Proprietary Materials. Except as expressly set forth herein, Your
                    use of the Services does not grant You ownership, of or any other rights
                    with respect to any art, content, code, data, or other Proprietary
                    Materials that You may access on or through the Services.
                </li>
                <li>
                    Company’s rights to MECA NFTs. Each MECA NFT includes certain artwork
                    created, owned, or licensed by the Company (the "Artwork"). Artwork
                    shall be regarded as intellectual property. You acknowledge and agree
                    that the Company owns or has licensed all legal rights, titles, and
                    interests in and to the Artwork, and all intellectual property rights
                    therein. The Company may also incorporate (create) one or more elements
                    of MECA NFTs as a trademark and branding (the "Brand"). ALTHOUGH EACH
                    MECA NFT ITSELF IS OWNED BY ITS COLLECTOR, THE ARTWORK EMBODIED THEREBY
                    AND ANY BRAND INCORPORATED THEREIN OR USED THEREWITH IS LICENSED
                    PURSUANT TO THESE TERMS, AND NOT TRANSFERRED OR SOLD, TO SUCH COLLECTOR.
                    The rights that You will acquire on the Artwork after the purchase are
                    limited to those expressly stated in these Terms. Company and its
                    licensors reserve all rights in and to the Artwork and Brand not
                    expressly granted to You herein.
                </li>
                </ul>
                <br />
                <h2>7. ELIGIBILITY</h2>
                <ul>
                <li>
                    All Users may access certain limited public areas of the Website. You
                    understand that all We are providing You is merely access to Our
                    Services as We provide them from time to time. You need to provide Your
                    own access to the Internet, and any Internet access or other fees that
                    You incur to access Our Website and use Our Services are Your sole
                    responsibility. We are not providing any hardware or software to You.
                    You need to purchase or license the necessary hardware and software in
                    order to access the Website and Services. This Agreement covers all
                    public and non-public areas of the Website.
                </li>
                <li>
                    When You use the Service, You hereby represent and warrant, to and for
                    the benefit of the Company, its affiliates, and its and their respective
                    representatives, as follows: You have conducted your own research around
                    the NFT marketplace and you understand how it operates and acknowledge
                    the risks associated with purchasing such NFT's. Additionally, You have
                    conducted an independent investigation of the Service and the matters
                    contemplated by these Terms, have formed Your own independent judgment
                    regarding their benefits, advantages, disadvantages, and risks. You
                    understand the necessary and desirable practices regarding the
                    foregoing, and, in making the determination to use the Services, You
                    have relied solely on the results of such investigation and such
                    independent judgement. Without limiting the generality of the foregoing,
                    You understand, acknowledge and agree that the legal requirements
                    pertaining to blockchain technologies and digital assets generally,
                    including the NFTs, are uncertain, and You have conducted an independent
                    investigation of such potentially applicable legal requirements and the
                    resulting risks and uncertainties, including the risk that one or more
                    governmental entities or other persons may assert that any digital
                    assets or cryptographic tokens (including the NFTs) may constitute
                    securities under applicable legal requirements. You hereby irrevocably
                    disclaim and disavow reliance upon any statements or representations
                    made by or on behalf of, or information made available by, the Company,
                    in determining to enter into this Agreement, mint any NFT, or otherwise
                    use the Services.
                </li>
                <li>
                    In order to be able to use Our Services and access Our Website You
                    warrant and represent that You:
                    <ul>
                    <li>
                        Have full power and authority to enter into this legal relationship
                        and by doing so You will not violate any other legal relationships;
                    </li>
                    <li>Have the legal right to access Our Website and Services;</li>
                    <li>
                        Are providing Us with complete and accurate information. You shall
                        update such information when it changes or when We request it;
                    </li>
                    <li>
                        Will not copy or distribute any part of the Website without Our
                        prior written authorization;
                    </li>
                    <li>
                        Will not use Our Services or will immediately cease using them if
                        any applicable law in Your Country or jurisdiction prohibits or will
                        prohibit You, at any time, from doing so;
                    </li>
                    <li>
                        Will fully comply with these Terms as well as with the Privacy
                        Policy.
                    </li>
                    </ul>
                </li>
                <li>
                    By using our Website and Services, You accept and acknowledge the
                    following risks: (a) the prices of blockchain assets and
                    cryptocurrencies are highly volatile. Fluctuations in the price of other
                    digital assets could materially and adversely affect the value of Your
                    NFT, which may also be subject to significant price volatility. We
                    cannot guarantee that any MECA NFTs purchased will retain their original
                    value. NFTs have no inherent or intrinsic value. For the avoidance of
                    any doubt, Your purchase of a MECA NFT should not be considered as an
                    investment; (b) The Website and Services does not store, send, or
                    receive NFTs. NFTs exist only by virtue of the ownership record
                    maintained on the blockchain in the Ethereum network. Any transfer of
                    NFTs occurs within the supporting blockchain in the Ethereum network;
                    (c) There are risks associated with using an Internet-based currency
                    including, but not limited to, the risk of hardware, software and
                    Internet connection failures, the risk of malicious software
                    introduction, and the risk that third parties may obtain unauthorized
                    access to information stored within Your wallet. You accept and
                    acknowledge that We will not be responsible for any communication
                    failures, disruptions, errors, distortions or delays You may experience
                    when using the Ethereum network, however caused; (d) A lack of use or
                    public interest in the creation and development of distributed
                    ecosystems could negatively impact the NFTs and, including but not
                    limited to, the value of the NFTs; (e) The regulatory regime governing
                    blockchain technologies, cryptocurrencies, and tokens is highly
                    uncertain, and any new regulations or policies may materially and
                    adversely affect the NFTs and, therefore, the potential value of NFTs;
                    (f) Upgrades to the blockchain platform that supports the MECA NFTs, a
                    hard fork in the said blockchain platform, a failure or cessation of the
                    blockchain platform, or a change in how transactions are confirmed on
                    the blockchain platform may have unintended, adverse effects on all
                    blockchains using such technologies, including, without limitation, Your
                    transactions involving NFTs purchased on the Website.
                </li>
                <li>
                    YOU AGREE TO INDEMNIFY AND HOLD US HARMLESS AGAINST ANY CLAIMS, DEMANDS
                    AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY
                    OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE,
                    LOSS OF PROFITS, LOSS OF DATA, OR LOSS OF ASSETS, WHETHER IT CONCERNS AN
                    ACTION, A CONTRACT, OR A TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE)
                    OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH INVALIDITY OR
                    BREACH OF ANY OF THE WARRANTIES, REPRESENTATIONS AND COVENANTS OF THIS
                    SECTION AND THE ENTIRE TERMS.
                </li>
                <li>
                    Service Interruption. From time to time, due to technological factors,
                    scheduled software updates and other factors beyond Our control,
                    Services may be temporarily interrupted. From time to time, access to
                    the Website or certain features of the Website may not be available for
                    use due to technological and other factors. You agree to hold Us
                    harmless against any such interruption of service.
                </li>
                </ul>
                <br />
                <h2>8. PRIVACY POLICY</h2>
                <ul>
                <li>
                    We retain a separate Privacy Policy and Your assent to these Terms also
                    signifies Your assent to the Privacy Policy. We reserve the right to
                    amend the Privacy Policy at any time by posting such amendments to the
                    Website. No other notification shall be made to You about any
                    amendments. Your continued use of the Website following such amendments
                    will constitute Your acceptance of such amendments.
                </li>
                </ul>
                <br />
                <h2>9. RESTRICTIONS APPLICABLE TO THE USE OF THE WEBSITE AND SERVICES</h2>
                <ul>
                <li>
                    You further represent, agree and warrant, that You will not violate any
                    law, contract, third-party right or commit a tort by accessing or using
                    the Website, and that You are solely responsible for Your actions and/or
                    inactions while using Our Website. You agree that You will only use the
                    Website and Services for purposes expressly permitted and contemplated
                    by this Agreement. You may not use the Website and Services for any
                    other purposes without Our prior express and written consent. Without
                    prejudice to the foregoing, You represent, agree and warrant, that YOU
                    WILL NOT:
                    <ul>
                    <li>
                        Use or attempt to use another User’s identity, given knowingly and
                        voluntarily as well as impersonate any person or entity, or falsely
                        state or otherwise misrepresent Your affiliation with a person or
                        entity;
                    </li>
                    <li>
                        Copy, duplicate, or distribute any part of the Website without Our
                        prior written authorization;
                    </li>
                    <li>
                        Intentionally or unintentionally violate any applicable local,
                        state, national or international law, and any regulations having the
                        force of law;
                    </li>
                    <li>Provide false, inaccurate, or misleading information;</li>
                    <li>
                        Use the Website in any manner that could interfere with, disrupt,
                        negatively affect, or inhibit other Users from using Our Website
                        with full functionality, or that could damage, disable, overburden,
                        or impair the functioning of the Website (servers or networks
                        connected to the Website) in any manner;
                    </li>
                    <li>
                        Obtain or attempt to access or otherwise obtain any materials or
                        information through any means not intentionally made available or
                        provided for through the Services;
                    </li>
                    <li>
                        Collect or store personal data about other Users and Collectors,
                        including via the use of any data mining, bots, or similar data
                        gathering and extraction tools as well as use any robot, spider,
                        crawler, scraper or other automated means or interface, not provided
                        by us, to access the Website or to extract data;
                    </li>
                    <li>
                        Encourage or induce any third party to engage in any of the
                        activities prohibited under this section of the Terms;
                    </li>
                    <li>
                        Use the Services to advertise or offer to sell or buy any goods or
                        services for any purpose that is not specifically authorized herein
                        and/or not specifically approved by Us in writing;
                    </li>
                    <li>
                        Use the Services or any MECA NFT to carry out financial activities
                        subject to registration or licensing, including but not limited to
                        creating, listing, or buying securities, commodities, options, real
                        estate, or debt instruments;
                    </li>
                    <li>
                        Create any derivative works based on Our Website, Services, MECA
                        NFTs or any of the materials contained therein or received via the
                        Services. You agree and stipulate that any and all derivative works
                        are NOT "fair use";
                    </li>
                    <li>
                        Remove any copyright or other proprietary notices or any of the
                        materials contained therein from Our Website;
                    </li>
                    <li>
                        Restrict or inhibit any other user from using and enjoying the
                        Services;
                    </li>
                    <li>
                        Publish falsehoods or misrepresentations that could damage the
                        Website or any third party.
                    </li>
                    </ul>
                </li>
                </ul>
                <br />
                <h2>10. CHANGE OF THE AGREEMENT</h2>
                <ul>
                <li>
                    We reserve the right to alter, amend or modify these Terms from time to
                    time, at Our sole discretion. We will post the amended Terms via the
                    Website and update the "Last Updated" date at the beginning of these
                    Terms. Any updated or edited version supersedes any prior versions
                    immediately upon posting, and the prior version is of no continuing
                    legal effect unless the revised version specifically refers to the prior
                    version and keeps the prior version or portions thereof in effect.
                </li>
                <li>
                    The amended Terms will be deemed effective immediately upon posting for
                    any new Users of the Services. In all other cases, the amended Terms
                    will become effective for pre-existing users from the date that the User
                    visits our Website or continues to use Our Services after the Company
                    publishes a new version of the Terms on the Website.
                </li>
                <li>
                    IF You do not agree to any amended Terms, You must discontinue using Our
                    Services and contact Us to terminate Your account.
                </li>
                <li>
                    Waiver. If You fail to periodically review this Agreement to determine
                    if any of the terms have changed, You assume all responsibility for Your
                    failure to do so and You agree that such failure amounts to Your
                    affirmative waiver of Your right to review the amended terms. We are not
                    responsible for Your neglect of Your legal rights.
                </li>
                </ul>
                <br />
                <h2>11. THIRD-PARTY SERVICES; THIRD-PARTY TERMS</h2>
                <ul>
                <li>
                    The Website or the Service may contain links to third-party properties
                    ("Third-Party Properties") and applications ("Third-Party
                    Applications"). When You click on a link to a Third-Party Property or
                    Third-Party Application, You are subject to the terms and conditions
                    (including privacy policies) of such other property or application. Such
                    Third-Party Properties and Third-Party Applications are not under the
                    control of the Company. The Company is not responsible for any
                    Third-Party Properties or Third-Party Applications. The Company provides
                    links to these Third-Party Properties and Third-Party Applications only
                    as a convenience and does not review, approve, monitor, endorse,
                    warrant, or make any representations with respect to Third-Party
                    Properties or Third-Party Applications, or their products or services.
                    You use all links in Third-Party Properties, and Third-Party
                    Applications at Your own risk. When You leave Our Service, Our Terms and
                    policies no longer govern Your use or interactions. You should review
                    all applicable agreements and policies, including privacy and data
                    gathering practices, of any Third-Party Properties or Third-Party
                    Applications, and You should conduct whatever investigation You deem
                    necessary or appropriate before proceeding with any transaction with any
                    third-party.
                </li>
                </ul>
                <br />
                <h2>12. INDEMNIFICATION AND RELEASE</h2>
                <ul>
                <li>
                    YOU AGREE TO INDEMNIFY AND HOLD THE COMPANY HARMLESS AGAINST ANY CLAIMS,
                    DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL,
                    OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF
                    USE, LOSS OF PROFITS, LOSS OF DATA, OR LOSS OF ASSETS, INCLUDING TOTAL
                    ONE, WHETHER IN AN ACTION, IN CONTRACT, IN TORT (INCLUDING BUT NOT
                    LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY
                    CONNECTED WITH:
                    <ul>
                    <li>Your use of and access to the Website;</li>
                    <li>Your violation of any part of these Terms;</li>
                    <li>
                        Your violation of any third party right, including without
                        limitation any copyright, property, or privacy right;
                    </li>
                    <li>
                        Any claim that Your usage of the Website and Services caused damage
                        to a third party;
                    </li>
                    <li>
                        Your use of the Website, Services and NFTs, including, but not
                        limited to those arising from Your personal errors and misbehavior
                        such as forgotten passwords, incorrectly constructed transactions,
                        loss of Your accesses etc;
                    </li>
                    <li>
                        Any risks associated with utilizing any NFTs, blockchain networks,
                        including, but not limited to the risk of unknown vulnerabilities in
                        or unanticipated changes to any network protocol;
                    </li>
                    <li>
                        Any software failures, protocol changes by third-party providers,
                        internet outages, Force Majeure event or other disasters including
                        third party distributed denial-of-service (DDOS) attacks, scheduled
                        or unscheduled maintenance, or other causes either within or outside
                        Our control, any malfunction, unintended function, unexpected
                        functioning of or attack on the Blockchain protocol that may cause
                        the Website, Services and NFTs to malfunction or function in an
                        unexpected or unintended manner. For the purposes hereof "Force
                        Majeure Event" shall be understood as any event beyond the Company’s
                        reasonable control, including but not limited to flood,
                        extraordinary weather conditions, earthquake, or other act of God,
                        fire, war, insurrection, riot, labor dispute, accident, action of
                        government, suspension of bank accounts of any kind, extraordinary
                        leaps of the course of cryptocurrency, communications, network or
                        power failure, or equipment or software malfunction or any other
                        cause beyond the Company’s reasonable control;
                    </li>
                    <li>
                        Any technical, typographical, or photographic errors in information
                        and materials appearing on the Website or related applications.
                    </li>
                    </ul>
                    These defense and indemnification obligations will survive these Terms
                    and Your use of the Website.
                </li>
                <li>
                    In the event that You have a dispute with one or more Users or any third
                    parties, You hereby release us, Our officers, employees, agents and
                    successors-in-right from claims, demands and damages (actual and
                    consequential) of every type or nature, known and unknown, suspected and
                    unsuspected, disclosed and undisclosed, arising out of or in any way
                    related to such disputes and/or the Website(s).
                </li>
                <li>
                    We do not provide any facility for sending or receiving private or
                    confidential electronic communications. All messages transmitted to Us
                    shall be deemed to be readily accessible to the general public. Users
                    should not use this Website or Services to transmit any communication
                    for which the sender intends only the sender and the intended
                    recipient(s) to read. Notice is hereby given that all messages and other
                    content entered into or on this Website or Services can and may be read
                    by the agents and operators of the Website or Services, regardless of
                    whether they are the intended recipients of such messages.
                </li>
                </ul>
                <br />
                <h2>13. DISCLAIMER OF WARRANTIES AND LIMITATIONS OF LIABILITIES</h2>
                <ul>
                <li>
                    READ THIS SECTION CAREFULLY AS IT LIMITS OUR LIABILITY TO THE MAXIMUM
                    EXTENT PERMITTED UNDER APPLICABLE LAW.
                </li>
                <li>
                    YOU AGREE THAT YOUR USE OF THE WEBSITE SHALL BE AT YOUR SOLE RISK. TO
                    THE FULLEST EXTENT PERMITTED BY LAW, THE WEBSITE, ITS OFFICERS,
                    DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR
                    IMPLIED, IN CONNECTION WITH THE WEBSITE AND YOUR USE THEREOF. THE
                    WEBSITE MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                    COMPLETENESS OF THE WEBSITE'S CONTENT OR THE CONTENT OF ANY WEBSITES
                    LINKED TO THIS WEBSITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR
                    ANY:
                    <ul>
                    <li>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;</li>
                    <li>
                        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
                        ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                        THEREIN;
                    </li>
                    <li>
                        INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SITE, ANY
                        BUGS, VIRUSES, TROJAN HORSES, ETC. WHICH MAY BE TRANSMITTED TO OR
                        THROUGH OUR SITE BY ANY THIRD PARTY;
                    </li>
                    <li>
                        ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY
                        KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED,
                        TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE. THE SITE
                        DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
                        ANY THIRD-PARTY PROVIDED PRODUCT OR SERVICE ADVERTISED ON, OFFERED
                        BY, OR FEATURED ON THE WEBSITE OR THROUGH THE WEBSITE OR ANY LINKED
                        WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND THE
                        WEBSITE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR
                        MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
                        PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                        THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </li>
                    </ul>
                </li>
                <li>
                    The Website, Services and MECA NFTs are provided on an "AS-IS" basis and
                    are provided without any warranty or condition, express, implied or
                    statutory. We specifically disclaim to the fullest extent any implied
                    warranties of merchantability, fitness for a particular purpose,
                    non-infringement, information accuracy, integration, interoperability,
                    or quiet enjoyment. We disclaim any warranties for viruses or other
                    harmful components in connection with the Website. Some jurisdictions do
                    not allow the disclaimer of implied warranties, therefore in such
                    jurisdictions, some of the foregoing disclaimers may not apply to You
                    insofar as they relate to such implied warranties.
                </li>
                </ul>
                <br />
                <h2>14. NO AGENCY RELATIONSHIP</h2>
                <ul>
                <li>
                    Nothing in this Agreement shall be deemed to constitute, create, imply,
                    give effect to, or otherwise recognize a partnership, employment, joint
                    venture, or formal business entity of any kind. The rights and
                    obligations of the parties shall be limited to those expressly set forth
                    herein
                </li>
                </ul>
                <br />
                <h2>15. ARBITRATION PROVISIONS</h2>
                <ul>
                <li>
                    Binding Arbitration. If there is a dispute between the Company and the
                    User (collectively referred to as "the Parties" and individually
                    referred to as the "Party") arising out of or otherwise relating to this
                    Agreement, the Parties shall meet, virtually or in person, and negotiate
                    in good faith to attempt to resolve the dispute. If the Parties are
                    unable to resolve the dispute through direct negotiations, then, except
                    as otherwise provided herein, the issue shall be submitted by either
                    Party to binding arbitration in accordance with applicable arbitration
                    rules. Claims subject to arbitration ("Arbitral Claims") shall include,
                    but are not limited to, contract and tort claims of all kinds, and all
                    claims based on any federal, state, or local law, statute, or
                    regulation, excepting only claims by Us under actions (regardless of the
                    underlying cause of action) seeking injunctions, attachment,
                    garnishment, and other equitable relief. The arbitration procedures
                    shall be conducted in the Republic of Cyprus, in a convenient location
                    agreed to by the Parties, or in the absence of such agreement, the
                    location shall be selected by the Arbitrator. The arbitration shall be
                    conducted by a single arbitrator, who is knowledgeable in Internet and
                    e-Commerce disputes.
                </li>
                <li>
                    YOU AND THE COMPANY AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE
                    OTHER ONLY IN ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                    MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if
                    a dispute is resolved through arbitration, the arbitrator may not
                    consolidate another person’s claims with a Party’s claims and may not
                    otherwise preside over any form of a representative or class proceeding.
                    If this specific provision is found to be unenforceable, then the
                    entirety of this section shall be null and void
                </li>
                </ul>
                <br />
                <h2>16. MISCELLANEOUS</h2>
                <ul>
                <li>
                    These Terms together with the Privacy Policy and any other legal notices
                    and information published by Us on the Website contain the entire
                    agreement and supersede all prior and contemporaneous understandings
                    between the Parties regarding the Services.
                </li>
                <li>
                    The invalidity or unenforceability of any terms of the Agreement shall
                    not affect the validity or enforceability of any other terms, all of
                    which shall remain in full force and effect.
                </li>
                <li>
                    Our failure or delay in exercising any right, power or privilege under
                    these Terms shall not operate as a waiver thereof.
                </li>
                <li>
                    All headings are solely for the convenience of reference and shall not
                    affect the meaning, construction, or effect of this Agreement.
                </li>
                <li>
                    You may not assign or transfer any of Your rights or obligations under
                    these Terms without prior written consent from the Company, including by
                    operation of law or in connection with any change of control. The
                    Company may assign or transfer any or all of its rights under these
                    Terms, in whole or in part, without obtaining Your consent or approval.
                </li>
                <li>
                    If these Terms or any other documents between You and Us are translated
                    and executed in any language other than English and there is any
                    conflict between the translation and the English version, the English
                    version shall prevail.
                </li>
                </ul>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    color: #fff;
    padding: 30px 0;
    h1{
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    h5 {
        display: block;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
    li {
        display: list-item;
        text-align: -webkit-match-parent;
        margin-top: 15px;
    }
`;