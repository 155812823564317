import styled from '@emotion/styled';
import React from 'react'
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/basic/addTheme';
import { momentFormat } from '../../../utils/format/time';

interface roadMapItemType{
    roadmap_quarter?:string;
    roadmap_title?:string;
    roadmap_content?:string;
}
export default function RoadMapItem({roadmap_quarter,roadmap_title,roadmap_content=''}:roadMapItemType) {
    let date = new Date(Number(roadmap_quarter));
    let currentMonth = date.getMonth()+1;
    let quarter = Math.ceil(currentMonth/3);
    let year = date.getFullYear();
    
    return (
        <Wrap>
            <DateWrap>
                <BasicText fontSize={addTheme.roadMap.date} theme='black'>{`${year}.${quarter}Q`}</BasicText>
            </DateWrap>
            <BasicText marginTop={1.4} theme='black' fontSize={addTheme.roadMap.title} fontWeight={addTheme.roadMap.w}>{roadmap_title}</BasicText>
            <BasicText dangerouslySetInnerHTML={{__html: roadmap_content.replaceAll('\n','<br>') }} marginTop={1.2} theme='subtext' fontSize={addTheme.roadMap.sub}></BasicText>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: absolute;
    top:0;
    left:0;
    padding: 20px 15px;
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
`;

const DateWrap = styled.div`
    background-color: #0005ff;
    padding: 4px 6px;
    display: inline-block;
`;