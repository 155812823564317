import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react'
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import BtnItem from '../../header/item/BtnItem';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { apiNowPrice } from '../../../api/mainApi';

const NOW_PRICE = {price_id: 0, price_title: '', price_type: 0, price_price: ''};

export default function ListingItem({data,clickEvent}:any) {
    const wrap = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    
    const [nowEth,setNowEth] = useState(0);
    const [nowMeca,setNowMeca] = useState(0);

    useEffect(()=>{
        getNowPrice();
    },[data])

    // 시세
    const getNowPrice = async() => {
        const res = await apiNowPrice();
        if(!res.result) return;
        res.data.map((item:typeof NOW_PRICE)=>{
            if(item.price_type === 1){
                let priceCul = Number(item.price_price) * data?.nft_price_eth;
                let price = Number((Math.ceil(priceCul * 10000) / 10000));
                setNowEth(price);
            }
            if(item.price_type === 2){
                let priceCul = Number(item.price_price) * data?.nft_price_meca;
                let price = Number((Math.ceil(priceCul * 10000) / 10000));
                setNowMeca(price);
            }
        })
        return;
    }


    return (
        <Wrap ref={wrap}>
            <ItemWrap align='start' style={{flexWrap:'wrap'}}>
                <BasicText theme='black'>Price</BasicText>
                <PriceWrap>
                    <PriceItemWrap>
                        <BasicText color={'#fff'} fontSize={18} fontWeight={800}>{data?.nft_price_eth} ETH</BasicText>
                        <BasicText marginLeft={1} theme='comingBtn' fontSize={15}>${nowEth}</BasicText>
                    </PriceItemWrap>
                    <PriceItemWrap>
                        <BasicText color={'#fff'} fontSize={18} fontWeight={800}>{data?.nft_price_meca} MECA</BasicText>
                        <BasicText marginLeft={1} theme='comingBtn' fontSize={15}>${nowMeca}</BasicText>
                    </PriceItemWrap>
                </PriceWrap>
            </ItemWrap>
            <ItemWrap>
                <BasicText theme='black'>Seller</BasicText>
                <BasicText theme='comingBtn'>{data?.nft_listing.seller}</BasicText>
            </ItemWrap>
            <ItemWrap>
                <BasicText theme='black'>Etherscan</BasicText>
                <BasicText theme='black'>
                    <a href={data?.nft_listing.scan} target='_blank' style={{textDecoration:'underline'}}>
                        Etherscan Link
                    </a>
                </BasicText>
            </ItemWrap>
            <ItemWrap>
                {data?.nft_status === 0 ?
                    <BtnWrap>
                        <BtnItem width='100%' text='Buy ETH' background={theme.comingBtn} clickEvent={()=>clickEvent('eth')}></BtnItem>
                        <BtnItem width='100%' text='Buy MECA' background={theme.comingBtn} clickEvent={()=>clickEvent('meca')}></BtnItem>
                    </BtnWrap>
                    : data?.nft_status === 2 && <BtnItem width='100%' text='Closed' background={theme.header} cursor='default'></BtnItem>
                }
            </ItemWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    height: 100%;
`;

const ItemWrap = styled(RowBetween)<{align?:string}>`
    height: calc(100% / 4);
    &:last-of-type{margin-top:8px;}
    align-items: ${props => props.align === 'start' ? 'start':'center'};
`;

const PriceWrap = styled.div`
    &>p{justify-content:end;}
`;

const PriceItemWrap = styled(Row)`
    flex-wrap: wrap;
    justify-content: end;
    text-align: right;
`;

const BtnWrap = styled(Row)`
    & button {margin: 0 5px; border-radius:4px;}
    @media ${({theme}:any)=>theme.media.m}{
        flex-direction: column;
        & button {margin: 5px 0;}
    }
`;