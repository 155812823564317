import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_SHOW = 'meca-admin/common/SET_DIALOG_SHOW';
const SET_HIDE = 'meca-admin/common/SET_DIALOG_HIDE';

export const setDialogShowCreator = createAction(SET_SHOW);
export const setDialogHideCreator = createAction(SET_HIDE);

export function setDialogData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setDialogShowCreator>> {
    
    return async function (dispatch) {
        try {
                dispatch(setDialogShowCreator({
                    data: data
                }));
        } catch (error) {
        
        }
    };
}

export interface dialogData {
    modalOpen: boolean;
    modalText: string;
}

const initialState: dialogData = {
    modalOpen:false,
    modalText:'',
};


export const globalDialog = handleActions<dialogData, any>({
    [SET_SHOW]: (state, action: ReturnType<typeof setDialogShowCreator>) => ({
        modalOpen: action.payload.data.modalOpen ?? state.modalOpen,
        modalText: action.payload.data.modalText ?? state.modalText,
      }),
    [SET_HIDE]: (state, action: ReturnType<typeof setDialogHideCreator>) => ({
        ...initialState,
    }),
  }, initialState);
