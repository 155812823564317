import styled from '@emotion/styled'
import React from 'react'
import { Center, Row } from '../../../style/basic/commonStyle'
import BasicText from '../../basic/BasicText'

export default function NoListItem() {
    return (
        <Wrap>
            <Center>
                <BasicText theme='black'>No items to display</BasicText>
            </Center>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    min-height: 80px;
    margin: 15px 0;
    &>div{width:100%;}
`;