export const addTheme = {
    media:{
        m:'(max-width: 992px)'
    },
    maxWidth:'1200px',
    padding:'0 15px',
    title:{
        fontSize: 38,
        mFontSize:28,
        fontWeight:700
    },
    subTitle:{
        fontSize: 20,
        mFontSize:16,
    },
    boardTitle:{
        fontSize: 20,
        mFontSize:15,
    },
    boardBtnSize:23,
    borderRadius:'8px',
    arrowLinkSize:24,
    roadMap:{
        date:17,
        title:20,
        w:800,
        sub:15,
    },
    news:{
        title:22,
        mTitle:18,
        content:15,
        data:15,
    },
    detail:{
        title:25,
        mTitle:20,
        sub:15,
        desc:17,
        mDesc:15,
    },
    snsBtn:{
        borderRadius: '4px'
    }
}