import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import BtnItem from '../../header/item/BtnItem';
import CountPart from '../part/CountPart';
import BoardWrapPart from '../part/BoardWrapPart';
import AboutPart from '../part/AboutPart';
import TopLineItem from '../item/TopLineItem';
import CollectionPart from '../part/CollectionPart';
import RoadMapPart from '../part/RoadMapPart';
import NewsPart from '../part/NewsPart';
import FaqPart from '../part/FaqPart';
import { apiRevenueList,apiCountList, apiNewsList, apiFaqList, apiNftList, apiLinkList } from '../../../api/mainApi';
import { useNavigate } from 'react-router';
import MainPart from '../part/MainPart';

const linkInit = {meca_link_id:0,meca_link_txt:'',meca_link_type:0};
export const FAQ = {faq_id:0,faq_title:'',faq_content:''}

export default function HomePage({aboutRef,collectionRef,roadmapRef,faqRef}:any){
    const theme = useTheme();
    const [boardListArr,setBoardListArr]:any = useState({profit_company:'',profit_profit:0});
    const [countData,setCountData] = useState([{setting_count_id:0,setting_count_content:'',setting_count_title:''}]);
    const [nftList,setNftList] = useState([{nft_id:0,nft_img:'',nft_price:0,nft_create_time:0, nft_status:0,nft_name:'', nft_desc:'',nft_view_count:0,nft_like_count:0,}])
    const [newsList, setNewsList] = useState([{notice_id:0,notice_title:'',notice_content:'',notice_create_time:0,notice_img:''}]);
    const [faqList,setFaqList] = useState([FAQ]);
    const [linkList,setLinkList] = useState([linkInit]);
    const navigate = useNavigate();

    useEffect(()=>{
        getRevenue();
        getCount();
        getNews();
        getFaq();
        getNftList();
        getLink();
    },[])

    //open revenue 리스트 api
    const getRevenue = async() => {
        const res = await apiRevenueList();
        
        if(!res.result){
            setBoardListArr({profit_company:'',profit_profit:0});
            return;
        }
        setBoardListArr(res.data);
    }
    //카운트 리스트 api
    const getCount = async() =>{
        const res = await apiCountList();
        setCountData(res.data);
    }

    //nft 리스트 api
    const getNftList = async() =>{
        const res = await apiNftList(1,8,'');
        if(!res.result || res.data.length ===0){
            setNftList([])
            return;
        }
        setNftList(res.data)
    }

    //news 리스트 api
    const getNews = async() =>{
        const res = await apiNewsList(1,4);
        if(!res.result || res.data.length ===0){
            setNewsList([]);    
            return;
        }
        setNewsList(res.data);
    }

    //faq list api
    const getFaq = async() =>{
        const res = await apiFaqList();
        if(!res.result || res.data.length ===0){
            setFaqList([FAQ]);    
            return;
        }
        setFaqList(res.data);
    }

    // link list api
    const getLink = async() =>{
        const res = await apiLinkList();
        
        if(!res.result){
            setLinkList([linkInit]);
            return;
        }
        setLinkList(res.data);
    }

    return (
        <Wrap>
            <Wrap paddingBottom={50}>
                <MaxWidthWrap>
                    <MainPart linkList={linkList}></MainPart>
                </MaxWidthWrap>
            </Wrap>
            <MaxWidthWrap>
                <Wrap paddingBottom={40}>
                    <BoardWrapPart data={boardListArr}></BoardWrapPart>
                </Wrap>
            </MaxWidthWrap>
            <Wrap background={theme.white} shadow='0 0 30px #191998'>
                <MaxWidthWrap>
                    <CountPart data={countData}></CountPart>
                </MaxWidthWrap>
            </Wrap>
            <Wrap paddingTop={80} ref={aboutRef}>
                <AboutPart linkList={linkList}></AboutPart>
            </Wrap>
            <TopLineItem></TopLineItem>
            <Wrap ref={collectionRef} paddingTop={80}>
                <MaxWidthWrap>
                    <CollectionPart list={nftList}></CollectionPart>
                </MaxWidthWrap>
            </Wrap>
            <TopLineItem></TopLineItem>
            <Wrap ref={roadmapRef} paddingTop={80}>
                <MaxWidthWrap>
                    <RoadMapPart></RoadMapPart>
                </MaxWidthWrap>
            </Wrap>
            <TopLineItem></TopLineItem>
            <MaxWidthWrap>
                <NewsPart list={newsList}></NewsPart>
            </MaxWidthWrap>
            <TopLineItem></TopLineItem>
            <Wrap ref={faqRef} paddingTop={80}>
                <MaxWidthWrap>
                    <FaqPart list={faqList}></FaqPart>
                </MaxWidthWrap>
            </Wrap>
        </Wrap>
        
    )
}

const Wrap = styled.div<{background?:string,paddingBottom?:number,shadow?:string,paddingTop?:number}>`
    background-color: ${props=>props.background ?? 'transparent'};
    padding-bottom: ${props=>props.paddingBottom ?? 0}px;
    padding-top: ${props=>props.paddingTop ?? 0}px;
    box-shadow: ${props=>props.shadow ?? 'none'};
`;