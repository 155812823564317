import styled from '@emotion/styled';
import React from 'react'
import { MaxWidthWrap, PaddingWrap } from '../../../style/basic/commonStyle';
import AboutContentPart01 from './AboutContentPart01';
import AboutContentPart02 from './AboutContentPart02';
import AboutContentPartChart from './AboutContentPartChart';
import VideoPart from './VideoPart';

export default function AboutPart({linkList}:any) {
    return (
        <Wrap>
            <PaddingWrap padding='100px 0 0'>
                <AboutContentPart01></AboutContentPart01>
            </PaddingWrap>
            <MaxWidthWrap>
                <PaddingWrap padding='200px 0 0'>
                    <AboutContentPart02></AboutContentPart02>
                </PaddingWrap>
            </MaxWidthWrap>
            <MaxWidthWrap>
                <PaddingWrap padding='200px 0 0'>
                    <AboutContentPartChart></AboutContentPartChart>
                </PaddingWrap>
            </MaxWidthWrap>
            <MaxWidthWrap>
                <PaddingWrap padding='200px 0 0'>
                    <VideoPart linkList={linkList}></VideoPart>
                </PaddingWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;