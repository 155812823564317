import styled from '@emotion/styled';
import React from 'react'
import { IMG_URI } from '../../../api/config';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import GridItem from '../../common/item/GridItem';

export default function NftItem({title,link,id,fontSize,mFontSize,img}:any) {
    const theme = useTheme();
    return (
        <GridItem background={theme.itemBg} text={title} fontSize={fontSize} mFontSize={mFontSize} link={link} type='nft'>
            <img alt='nft-img' src={IMG_URI+img}></img>
        </GridItem>
    )
}
