import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import CenterTitleItem from '../../common/item/CenterTitleItem';
import SliderPart from './SliderPart';
import GridItem from '../../common/item/GridItem';
import RotateSliderBtnPart from './RotateSliderBtnPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import RoadMapItem from '../item/RoadMapItem';
import { apiRoadmapList } from '../../../api/mainApi';
import NoListItem from '../item/NoListItem';

interface roadMapPartType {
  list?:Array<any>;
}

export default function RoadMapPart() {
  const theme = useTheme();
  const ref = useRef<any>(null);
  const [nowPage,setNowPage] = useState(0);
  const [total,setTotal] = useState(2);
  const [dataList,setDataList] = useState([{roadmap_id:0, roadmap_content:'',roadmap_quarter:'',roadmap_title:'',}])
  const sliderNext = () =>{
    ref.current.slickNext();
  }
  const sliderPrev = () =>{
    ref.current.slickPrev();
  }
  
  const arrowClick = (type:string) =>{
    if(type==='left'){
      sliderPrev();
    }
    if(type==='right'){
      sliderNext();
    }
  }

  useEffect(()=>{
    getListData();
  },[])

  const getListData = async() =>{
    const res = await apiRoadmapList(nowPage+1,8);
    setDataList(res.data);
    setTotal(res.total);
  }

  return (
    <Wrap>
      <CenterTitleItem title='Roadmap'></CenterTitleItem>
      <ListWrap>
          {dataList?.length === 0 ?
            <NoListItem></NoListItem>  :
            <SliderPart slider={ref} autoplay={false} slideSpeed={1500} showSlideCount={4} nowPage={nowPage} setNowPage={setNowPage}>
              {dataList?.map((data)=>{
                return(
                  <ItemWrap key={data.roadmap_id}>
                    <GridItem background={theme.listItemBg} link='no'>
                      <RoadMapItem {...data}></RoadMapItem>
                    </GridItem>
                  </ItemWrap>
                )
              })}
            </SliderPart>
          }
      </ListWrap>
      <MaxWidthWrap maxWidth='320px'>
        <RotateSliderBtnPart list={dataList} nowActive={nowPage} clickEvent={arrowClick}></RotateSliderBtnPart>
      </MaxWidthWrap>
    </Wrap>
  )
}

const Wrap = styled.div``;

const ListWrap = styled.div`
  margin-top: 60px;
`;

const ItemWrap = styled.div`
  padding: 10px;
`;