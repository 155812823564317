import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { Center, MaxWidthWrap, PaddingWrap } from '../../../style/basic/commonStyle';
import CenterTitleItem from '../../common/item/CenterTitleItem';
import ListPage from '../../list/page/ListPage';
import { apiNewsList } from '../../../api/mainApi';
import BasicText from '../../basic/BasicText';

const LIST = {notice_id:0,notice_content:'',notice_title:'',notice_img:[],notice_create_time:0}

export default function NewsPage() {
    const [nowPage,setNowPage] = useState(1);
    const [totalPage,setTotalPage] = useState(5);
    const [list,setList] = useState([LIST]);
    
    useEffect(()=>{
        getList();
    },[nowPage]);


    const getList = async() =>{
        const res = await apiNewsList(nowPage,10);
        if(!res.result || res.data.length===0){
            setList([LIST]);
            return;
        }
        setTotalPage(res.total);
        
        if(nowPage === 1){
            setList(res.data);
        }else{
            setList(arr=>[...arr,...res.data]);
        }
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <PaddingWrap padding='100px 0'>
                    <CenterTitleItem title='PRESS'></CenterTitleItem>
                </PaddingWrap>
                {(list[0]?.notice_id === 0 || list?.length === 0) ?
                    <Center style={{minHeight:'300px'}}>
                        <BasicText theme='black'>No items to display</BasicText>
                    </Center> :
                    <ListPage type='infinite' nowPage={nowPage} changeNowPage={setNowPage} totalPage={totalPage} list={list} count={4} mCount={2} gap={25} page='news' link='/pressDetail'></ListPage>
                }
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`

`;