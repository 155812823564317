import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Center, Row } from '../../../style/basic/commonStyle'
import TitleItem from '../../common/item/TitleItem';
import BtnItem from '../../header/item/BtnItem';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { FaTwitter,FaTelegramPlane } from 'react-icons/fa';
import { twitterLink,telKor, telRom, telThai, telViet } from '../../../api/config';

export default function AboutContentPart01() {
    const theme = useTheme();
    const [telBtn,setTelBtn] = useState(false);
    const telBtnClick = () =>{
        // setTelBtn(!telBtn);
        location.href = 'https://t.me/MetaCasinoDAO';
    }
    const btnClick = (link:string,type?:string) =>{
        if(type==='flag'){
            setTelBtn(false);  
        }
        location.href=link;
    }

    return (
        <Wrap>
            <LeftWrap></LeftWrap>
            <RightWrap>
                <RightInnerWrap>
                    <ImgWrap background={theme.imgBg}>
                        <ImgItemWrap>
                            <img alt='about-img' src='/img/logo/meca_token.png'></img>
                        </ImgItemWrap>
                    </ImgWrap>
                    <TextMaxWrap>
                        <TitleItem 
                            lineState='bottom'
                            title1='What is Meca?'
                            sub1={`Meca project's goal is to lower the entry barriers for investors by using the crypto market to solve the high investment barriers in the online casino industry. `}
                            sub2={'Meca is the best of transparent project of Casino by opening profit transparent. You can earn forever casino operating profits just by holding Meca NFT. '}
                            sub3={'Meca is Web3 environmental platform to provide fair compensation to the community that fits the current market trend! Spin your way to fortune with our MECA Project.'}
                            subTheme={'about1_sub'}
                        ></TitleItem>
                        <BtnWrap>
                            <TelBtnWrap>
                                <BtnItem text='Join to Telegram' borderColor={theme.btnBorder} clickEvent={telBtnClick} 
                                    children1={
                                        <IconWrap bgColor={theme.comingBtn}>
                                            <FaTelegramPlane color='#fff'></FaTelegramPlane>
                                        </IconWrap>
                                    }
                                    // children2={
                                    //     <IconWrap>
                                    //         {telBtn ?
                                    //             <FaChevronUp></FaChevronUp> : 
                                    //             <FaChevronDown></FaChevronDown>
                                    //         }
                                    //     </IconWrap>
                                    // }
                                ></BtnItem>
                                {/* <TelBtnListWrap borderColor={theme.btnBorder} telBtn={telBtn}>
                                    {telBtn &&
                                        <>
                                            <img onClick={()=>{btnClick(telKor,'flag')}} alt='korea_south' src='/img/flag/korea_south.png'></img>
                                            <img onClick={()=>{btnClick(telThai,'flag')}} alt='thailand' src='/img/flag/thailand.png'></img>
                                            <img onClick={()=>{btnClick(telViet,'flag')}} alt='vietnam' src='/img/flag/vietnam.png'></img>
                                            <img onClick={()=>{btnClick(telRom,'flag')}} alt='romania' src='/img/flag/romania.png'></img>
                                        </>
                                    }
                                </TelBtnListWrap> */}
                            </TelBtnWrap>
                            <BtnItem text='Join to Twitter' borderColor={theme.btnBorder} clickEvent={()=>btnClick(twitterLink)}
                                children1={
                                    <IconWrap bgColor={theme.comingBtn}>
                                        <FaTwitter color='#fff'></FaTwitter>
                                    </IconWrap>
                                }
                            ></BtnItem>
                        </BtnWrap>
                    </TextMaxWrap>
                </RightInnerWrap>
            </RightWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    flex-wrap: wrap;
`;
const LeftWrap = styled.div`
    width:45%;
    background: url('/img/about/bg01.png');
    background-size: cover;
    background-position: 28% center;
    position: relative;
    &::before{content:''; display:block; padding-bottom:100%;}
    @media (max-width:1288px){
        width:100%;
        &::before{content:none;}
    }
`;

const RightWrap = styled.div`
    width:55%;
    @media (max-width:1288px){
        width:100%;
        background: url('/img/about/bg01.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding:35px 15px;
    }
`;

const RightInnerWrap = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    @media ${({theme}:any)=>theme.media.m}{
        flex-wrap: wrap;
    }
`;

const ImgWrap = styled.div<{background?:string}>`
    width: 45%;
    margin-left: -30%;
    background-color: ${props=>props.background ?? 'transparent'};
    border-radius: ${({theme}:any)=>theme.borderRadius};
    border: 1px solid #fff;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    &::before{content:''; display:block; padding-bottom:100%;}
    @media (max-width: 1288px){
        margin-left: 0;
    }
    @media ${({theme}:any)=>theme.media.m}{
        margin: 0 auto;
        width: 65%;
    }
`;

const ImgItemWrap = styled.div`
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    padding: 15px;
    &>img{
        padding: 15px;
        width:100%;
        border-radius: 28px;
        background-color: #000835;
    }
`;

const TextMaxWrap = styled.div`
    margin-left: 5%;
    @media ${({theme}:any)=>theme.media.m}{
        margin-left: 0;
        margin-top: 15px;
    }
`;

const BtnWrap = styled.div`
    display: flex;
    margin-top: 30px;
    align-items: flex-start;
`;

const TelBtnWrap = styled.div`
    margin-right: 15px;
`;

const IconWrap = styled(Center)<{bgColor?:string}>`
    width:37px;
    height:28px;
    border-radius: 28px;
    background-color: ${props=>props.bgColor ?? 'transparent'};
    &>img{max-width:100%;}
`;

const TelBtnListWrap = styled(Row)<{borderColor?:string,telBtn?:boolean}>`
    width: 100%;
    height: 44px;
    border: ${props=>props.borderColor === undefined ? '2x solid transparent' : `2px solid ${props.borderColor}`};
    border-radius: ${({theme}:any)=>theme.snsBtn.borderRadius ?? '0'};
    justify-content: space-around;
    opacity: ${props=>props.telBtn ? 1 : 0};
    & img{height:20px; cursor: pointer;}
`;