import styled from '@emotion/styled';
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import {AiOutlineLeft,AiOutlineRight} from 'react-icons/ai';

interface rotateSliderArrowItemType{
    type?:'left'|'right';
    clickEvent?:any;
}

export default function RotateSliderArrowItem({type,clickEvent}:rotateSliderArrowItemType) {
    const theme = useTheme();
    
    return (
        <ArrowBtnWrap>
            <Circle borderColor={theme.black} bg={theme.arrowBg}></Circle>
            <ArrowBtn onClick={()=>clickEvent(type)} borderColor={theme.black} bg={theme.arrowBg}>
                {type ==='left' &&
                    <AiOutlineLeft color={theme.black}></AiOutlineLeft>
                }
                {type ==='right' &&
                    <AiOutlineRight color={theme.black}></AiOutlineRight>
                }
            </ArrowBtn>
            <Circle borderColor={theme.black} bg={theme.arrowBg}></Circle>
        </ArrowBtnWrap>
    )
}

const ArrowBtnWrap = styled(Center)``;

const ArrowBtn = styled(Center)<{borderColor?:string,bg?:string}>`
    border: ${props=>props.borderColor === undefined ? `1px solid #ddd`: `1px solid ${props.borderColor}`};
    border-radius: 100%;
    padding: 3px;
    margin: 0 5px;
    background-color: ${props=>props.bg ?? 'transparent'};
    cursor: pointer;
`;

const Circle = styled.div<{borderColor?:string,bg?:string}>`
    width:10px;
    height:10px;
    border-radius: 100%;
    border: ${props=>props.borderColor === undefined ? `1px solid #ddd`: `1px solid ${props.borderColor}`};
    background-color: ${props=>props.bg ?? 'transparent'};
`;