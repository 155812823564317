import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Center, Row } from '../../../style/basic/commonStyle'
import NumberItem from '../item/NumberItem'
import { BiChevronLeft,BiChevronRight } from 'react-icons/bi'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme'

interface numberPartType{
    nowPage:number;
    totalPage:number;
    changeNowPage:any;
    listCount:number;
}

export default function NumberBtnPart({nowPage,totalPage,changeNowPage,listCount}:numberPartType) {
    const [start,setStart] = useState(nowPage);
    const [last,setLast] = useState(totalPage);
    const [totalLast,setTotalLast] = useState(false);
    const [totalStart,setTotalStart] = useState(false);
    const theme = useTheme();
    
    useEffect(()=>{
        let pageBox = Math.ceil(nowPage/listCount);
        let totalLast =  Math.ceil(totalPage/listCount);
        let pageLast = pageBox * listCount;
        
        if(totalLast===pageBox){
            setTotalLast(true);
            setLast(totalPage);
        }else{
            setTotalLast(false);
            setLast(pageLast);
        }
        if(pageBox===1){
            setTotalStart(false);
        }else{
            setTotalStart(true);
        }
        setStart(pageLast-listCount);
    },[nowPage,totalPage]);

    //번호 컴포넌트 생성
    const numberItem = () =>{
        if(last === 1) return;
        let numberList = [];
        for (let i = start; i < last; i++) {
            numberList.push(<NumberItem key={i} changeNowPage={changeNowPage} page={i+1} nowPage={nowPage}>{i+1}</NumberItem>)
        }
        return numberList;
    }

    //화살표 클릭했을때 페이지 이동
    const arrowClick = (type:string) =>{
        if(type==='left'){
            if(nowPage <= 1) return;
            changeNowPage(nowPage-1);
        }
        if(type==='right'){
            if (nowPage === totalPage) return;
            changeNowPage(nowPage+1);
        }
    }

    return (
        <Wrap>
            {totalStart ?
                <ArrowBtn onClick={()=>arrowClick('left')} showState={totalStart}>
                    <BiChevronLeft size={18} color={theme.black}></BiChevronLeft>
                </ArrowBtn> : <NoneArrow><BiChevronLeft size={18}></BiChevronLeft></NoneArrow>
            }
            <NumberWrap>
                {numberItem()}
            </NumberWrap>
            {!totalLast ?
                <ArrowBtn onClick={()=>arrowClick('right')} showState={!totalLast}>
                    <BiChevronRight size={18} color={theme.black}></BiChevronRight>
                </ArrowBtn> : <NoneArrow><BiChevronRight size={18}></BiChevronRight></NoneArrow>
            }
        </Wrap>
    )
}

const Wrap = styled(Center)`
    margin: 30px 0;
    flex-wrap: wrap;
`
const NumberWrap = styled.div`
    display: flex;
`;
const ArrowBtn = styled.div<{showState?:boolean}>`
    cursor: pointer;
`;
const NoneArrow = styled.div`
    opacity: 0;
`;