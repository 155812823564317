import styled from '@emotion/styled'
import React from 'react'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme'

interface topLineItemType {
    marginTop?:number;
    marginBottom?:number;
}

// 점선 구분?
export default function TopLineItem({marginTop=100,marginBottom=100}:topLineItemType) {
    const theme = useTheme();
    return (
        <Wrap borderColor={theme.subtext} marginTop={marginTop} marginBottom={marginBottom}>
            <Square></Square>
        </Wrap>
    )
}

const Wrap = styled.div<{borderColor?:string,marginTop?:number,marginBottom?:number}>`
    width:100%;
    border-top: ${props => props.borderColor===undefined ? 'none' : `2px dashed ${props.borderColor}`};
    margin-top: ${props => props.marginTop}px;
    margin-bottom: ${props => props.marginBottom}px;
    position: relative;
`
const Square = styled.div`
    width: 12%;
    height: 10px;
    background-color: #fff;
    position: absolute;
    top:-6px;
    left:50%;
    transform: translateX(-50%);
`;