import React,{useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import BasicInput from '../../basic/BasicInput';
import { BasicInputType } from '../../basic/BasicInput';
import { Row } from '../../../style/basic/commonStyle';
import CategoryPart from '../part/CategoryPart';

interface SearchType extends BasicInputType{
    category?:Array<any>;
    selectCategory?:any;
    setSelectCategory?:any;
    border?:string;
    setResSearch?:any;
    icon?:any;
    background?:any;
}

/**
 * 
 * @param category 카테고리 배열[{id:1,cate:cate1}]
 * @param selectCategory 선택한 카테고리 value(id)
 * @param setSelectCategory 카테고리 변경
 * @param border border
 * @param setResSearch 최종 검색한 value {cate:'',search:''}
 * @param icon 검색아이콘
 * @param padding padding
 * @param color color
 * @param value 검색어
 * @param setValue 검색어 변경
 * @param background 배경
 * @returns <SearchPage border='1px solid #ddd' padding='10px' category={category} value={search} setValue={setSearch} selectCategory={selectCategory} setSelectCategory={setSelectCategory} setResSearch={resSearchEvent} icon={<IoSearchOutline size={20}></IoSearchOutline>}></SearchPage>
 */

export default function SearchPage({category, border, padding, value, setValue, selectCategory,setSelectCategory,setResSearch,icon,color,background }: SearchType) {
    const ref = useRef<any>(null);
    const [height,setsHeight] = useState('');
    // input에서 엔터 눌렀을때
    const onKeyDownEvent = (e:any) =>{
        if(e.key === 'Enter'){
            setResSearch(selectCategory,value);
        }
    }

    useEffect(()=>{
        let h = ref.current.clientHeight;
        setsHeight(`${h}px`);
    },[ref])
    
    return (
        <Wrap ref={ref} border={border} background={background}>
            <CategoryPart category={category} setSelectCategory={setSelectCategory} height={height}></CategoryPart>
            <BasicInput color={color} padding={padding} value={value} setValue={setValue} onKeyDownEvent={onKeyDownEvent}></BasicInput>
            <IconWrap padding={padding} onClick={()=>setResSearch(selectCategory,value)}>
                {icon}
            </IconWrap>
        </Wrap>
    )
}

const Wrap = styled(Row)<{border?:string,wrapPadding?:string,background?:string}>`
    width:100%;
    border: ${props=>props.border ?? 'none'};
    margin: ${props=>props.wrapPadding ?? '0'};
    background-color: ${props=> props.background ?? 'transparent'};
`

const IconWrap = styled.div<{padding?:string}>`
    flex-shrink: 0;
    padding: ${props=>props.padding ?? '0'};
    cursor: pointer;
`;