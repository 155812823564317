import styled from '@emotion/styled';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import useScrollMove from '../../../utils/custom-hooks/common/useScrollMove';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import BasicText from '../../basic/BasicText';

interface footerPageType {
    menu?:Array<any>;
    type?:'text'|'img';
    logo?:string;
    logoSize?:any;
    menuClickEvent?:any;
}

export default function FooterPage({menu,type,logo,logoSize,menuClickEvent}:footerPageType) {
    const theme = useTheme();

    return (
        <MaxWidthWrap>
            <Wrap>
                <FooterWrap>
                    <LogoWrap>
                        <LogoItemWrap>
                            {type==='img' ?
                                 <Img logosize={logoSize} alt='logo-img' src={logo}></Img> 
                                : <Center>
                                    <LogoImg alt='logo' src='./img/logo/meca_token.png'></LogoImg>
                                    <BasicText theme='black' fontSize={logoSize?.fontSize} fontWeight={800}>{logo}</BasicText>
                                </Center>
                            }
                        </LogoItemWrap>
                    </LogoWrap>
                    <MenuWrap>
                        {menu?.map((data:any,index:any)=>{
                            return(
                                <MenuItemWrap key={`${data.id}-${index}`}>
                                    <MenuTitle fontSize={22} color={theme.black} to={data.sub !== undefined ? '#': data.link} onClick={(event)=>menuClickEvent(event,data.link)}>
                                        {data.title}
                                    </MenuTitle>
                                    {data.sub!==undefined &&
                                        <SubMenuWrap>
                                            {data.sub.map((sub:any,index:any)=>{
                                                let img = sub.id === 3 ? '/img/flag/korea_south.png' 
                                                            : sub.id === 4 ? '/img/flag/thailand.png'
                                                            : sub.id === 5 ? '/img/flag/vietnam.png'
                                                            : sub.id === 6 ? '/img/flag/romania.png'
                                                            : '';
                                                return(
                                                    sub.type==='out' ? 
                                                    <MenuA type={'sub'} fontSize={15} color={theme.black} href={sub.link} key={`${data.id}-${sub.id}`}>
                                                        {sub.title}
                                                        {/* {img !== '' &&
                                                            <TelImg alt='country' src={img}></TelImg>
                                                        } */}
                                                    </MenuA>
                                                    : <MenuTitle type={'sub'} fontSize={15} color={theme.black} to={sub.link} key={`${data.id}-${sub.id}`}>{sub.title}</MenuTitle>
                                                )
                                            })}
                                        </SubMenuWrap>
                                    }
                                </MenuItemWrap>
                            )
                        })}
                    </MenuWrap>
                </FooterWrap>
            </Wrap>
        </MaxWidthWrap>
    )
}


const Wrap = styled.div`
    margin-top: 120px;
    min-height: 250px;
`;

const FooterWrap = styled(RowBetween)`
    align-items: start;
    padding: 30px 0;
    @media ${({theme}:any)=>theme.media.m}{
        flex-wrap: wrap;
    }
`;

const LogoWrap = styled.div`
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
    }
`;
const LogoItemWrap = styled.div`
    max-width: 80px;
    &>img{width:100%;}
`;
const LogoImg = styled.img`
    max-height: 35px;
    margin-right: 5px;
`;

const MenuWrap = styled.div`
    display: flex;
    align-items: start;
    justify-content: end;
    max-width: 670px;
    width: 100%;
    @media ${({theme}:any)=>theme.media.m}{
        flex-wrap: wrap;
        margin-top: 15px;
    }
`;
const MenuItemWrap = styled.div`
    margin-left: 50px;
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
        margin-top: 15px;
        margin-left: 0;
    }
`;
const MenuTitle = styled(Link)<{color?:string,fontSize?:number,type?:string}>`
    color: ${props=>props.color ?? '#333'};
    font-size: ${props=>props.fontSize ?? 16}px;
    display: block;
    margin:${props=>props.type === 'sub' ? '8px 0 10px' : 0};
`;

const MenuA = styled.a<{color?:string,fontSize?:number,type?:string}>`
    color: ${props=>props.color ?? '#333'};
    font-size: ${props=>props.fontSize ?? 16}px;
    display: block;
    margin:${props=>props.type === 'sub' ? '8px 0 10px' : 0};
    display: flex;
`;

const SubMenuWrap = styled.div`
    padding: 4px 0 0 4px;
    @media ${({theme}:any)=>theme.media.m}{
        padding: 4px 0 0 10px;
    }
`;

const Img = styled.img<{logosize?:any}>`
    width: ${props => props.logosize.w === undefined ? 'auto' :  props.logosize.w};
    height: ${props => props.logosize.h === undefined ? 'auto' :  props.logosize.h};
`;

const TelImg = styled.img`
    width: 18px;
    margin-left: 5px;
`;