import styled from '@emotion/styled';
import React from 'react'
import { JustifyWrap, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

interface countPartType {
    data?:Array<any>;
}

export default function CountPart({data}:countPartType) {
    return (
        <Wrap>
            <InnerWrap>
                <LineWrap></LineWrap>
                {data?.map((data,index)=>{
                    return(
                        <ItemWrap key={data.setting_count_id}>
                            <Text>{data.setting_count_content}</Text>
                            <BasicText marginTop={1.8} theme={'black'}>{data.setting_count_title}</BasicText>
                        </ItemWrap>
                    )
                })}
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled(RowBetween)`
    min-height: 300px;
    margin-top: 70px;
`;

const InnerWrap = styled(RowBetween)`
    flex-wrap: wrap;
    position: relative;
`;

const LineWrap = styled.div`
    width:100%;
    height:35%;
    border-bottom: 1px dashed #ddd;
    position: absolute;
    top:0;
    z-index: 1;
    @media ${({theme}:any)=>theme.media.m}{
        display: none;
    }
`;

const ItemWrap = styled(JustifyWrap)`
    position: relative;
    text-align: center;
    &>p{display:block;}
    z-index: 2;
    background-color: #000;
    padding: 0 28px;
    @media ${({theme})=>theme.media.m}{
        width: 100%;
        margin: 20px 0;
        padding: 0;
    }
`;

const Text = styled.div`
    -webkit-text-stroke: 2px #e91482; 
    font-size: 56px; 
    font-weight: 400; 
    color: transparent;
`;