import React from 'react'
import styled from '@emotion/styled'
import GridItem from '../../common/item/GridItem';
import BasicText from '../../basic/BasicText';
import {addTheme} from '../../../style/basic/addTheme';
import {momentFormat} from '../../../utils/format/time';
import { Link } from 'react-router-dom';
import { IMG_URI } from '../../../api/config';

interface newsItemType{
    notice_img?:any;
    notice_title?:string;
    notice_content?:string;
    notice_create_time?:number;
    link?:string;
}

export default function NewsItem({notice_img,notice_title,notice_content='',notice_create_time,link='#'}:newsItemType) {
    const newContent = notice_content.replaceAll(/<[^>]*>?/g, '');
    const imgState = notice_img[0] === undefined || notice_img[0] === '' ? false : true;
    return (
        <Wrap to={link}>
            <GridItem link={'no'}>
                {imgState &&
                    <Img src={IMG_URI+notice_img[0]} alt='news-img'></Img>
                }
            </GridItem>
            <TitleWrap>
                <BasicText theme='black' limit='true' line={2} marginTop={1.2} fontSize={addTheme.news.title} mFontSize={addTheme.news.mTitle}>{notice_title}</BasicText>
            </TitleWrap>
            <ContentWrap>
                <BasicText theme='submain' limit='true' fontSize={addTheme.news.content} line={3}>
                    {newContent}
                </BasicText>
            </ContentWrap>
            <BasicText theme='black' marginTop={1}>
                {momentFormat('yyyy-MM-DD',notice_create_time)}
            </BasicText>
        </Wrap>
    )
}

const Wrap = styled(Link)`
    display: block;
`;

const Img = styled.img`
    width: 100%;
`;

const TitleWrap = styled.div`
    min-height: 66px;
`;

const ContentWrap = styled.div`
    margin-top: 8px;
    min-height: 68px;
`;