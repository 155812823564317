import styled from '@emotion/styled';
import React from 'react';
import CenterTitleItem from '../../common/item/CenterTitleItem';
import GridItem from '../../common/item/GridItem';
import NewsItem from '../item/NewsItem';
import BtnItem from '../../header/item/BtnItem';
import { Center } from '../../../style/basic/commonStyle';
import { useNavigate } from 'react-router';
import NoListItem from '../item/NoListItem';
import {useTheme} from '../../../utils/custom-hooks/common/useTheme';

interface newsPartType {
    list?:Array<any>;
}

export default function NewsPart({list}:newsPartType) {
    const theme = useTheme();
    const navigate = useNavigate();

    const btnClick = () =>{
        navigate('/press');
    }
    
    return (
        <Wrap>
            <CenterTitleItem title='PRESS'></CenterTitleItem>
                { list?.length === 0 ?
                    <NoListItem></NoListItem>
                    : 
                    <ListWrap>
                        {list?.map((data,index)=>{
                            return(
                                <NewsItem key={`news-${data.notice_id}`} {...data} link={`/pressDetail/${data.notice_id}`}></NewsItem>
                            )
                        })}
                    </ListWrap>
                }
            <BtnWrap>
                <BtnItem borderColor={theme.btnBorder} text='MORE' padding='6px 20px' clickEvent={btnClick}></BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``
const ListWrap = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-columns: 1fr 1fr;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 45px;
`;
