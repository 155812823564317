import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react'
import { Center, JustifyWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';

export default function CountItem({id,title,count}:any) {
    return (
        <Wrap>
            {title === 'MY NFTs' &&
                <DiamondWrap>
                    <Diamond></Diamond>
                </DiamondWrap>
            }
            <TextWrap>
                <BasicText theme='black' fontSize={15} mFontSize={14}>Total</BasicText>
                <BasicText theme='black' fontSize={18} mFontSize={17} fontWeight={800} limit='true' line={1}>{title}</BasicText>
            </TextWrap>
            <CountWrap>
                <Text>{count}</Text>
            </CountWrap>
        </Wrap>
    )
}

const Wrap = styled(JustifyWrap)`
    position:relative;
    display: flex;
    border: 1px solid #fff;
    padding: 15px;
    border-radius: ${({theme}:any)=>theme.borderRadius};
    background-color: rgba(255,255,255,0.24);
`;

const TextWrap = styled.div`
    position: relative;
`;

const CountWrap = styled(Row)`
    position: relative;
    justify-content: end;
`;

const Text = styled.div`
    -webkit-text-stroke: 1px #fff; 
    font-size: 40px; 
    font-weight: 900; 
    color: transparent;
    @media ${({theme}:any)=>theme.media.m}{
        font-size: 24px;
        word-break: break-all;
    }
`;

const DiamondWrap = styled(Center)`
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    @media ${({theme}:any)=>theme.media.m}{
        width: auto;
    }
`;

const scaleAni = keyframes`
    0%{transform:rotate(48deg) skew(10deg, 10deg) scale(1);}
    50%{transform:rotate(45deg) skew(10deg, 10deg) scale(1.25);}
    100%{transform:rotate(42deg) skew(10deg, 10deg) scale(1);}
`;

const Diamond = styled.div`
    width:42%;
    background: radial-gradient(circle, rgba(15, 201, 238, 0.7) 10%, rgba(14, 14, 215, 0.8) 70%);
    transform: rotate(45deg) skew(10deg, 10deg);
    filter: blur(12px);
    &::before{content:''; display:block; padding-bottom:100%;}
    animation: ${scaleAni} 15s 2s infinite ease;
`;