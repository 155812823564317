import styled from '@emotion/styled';
import React from 'react';
import { Center } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import RotateSliderArrowItem from '../item/RotateSliderArrowItem';

export default function RotateSliderBtnPart({clickEvent,list,nowActive}:any) {
    const theme = useTheme();
    
    return (
        <Wrap>
            <BtnWrap>
                <RotateSliderArrowItem clickEvent={clickEvent} type='left'></RotateSliderArrowItem>
            </BtnWrap>
            <CountWrap>
                {list?.map((data:any,index:any)=>{
                    let newCount = nowActive%list.length;
                    return(
                        <CountItem key={index} background={theme.black} length={list.length} noneBg={theme.arrowBg} now={newCount===index}></CountItem>
                    )
                })}
            </CountWrap>
            <BtnWrap>
                <RotateSliderArrowItem clickEvent={clickEvent} type='right'></RotateSliderArrowItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    padding-top: 25px;
`;

const CountWrap = styled(Center)`
    width: 100%;
    margin: 0 10px;
`;

const CountItem = styled.div<{length?:number,background?:string,now?:boolean,noneBg?:string,}>`
    width: ${props => props.length === undefined ? 20 : 100/props.length}%;
    height:2px;
    background-color: ${props => props.now ? props.background ?? 'transparent' : props.noneBg};
    margin:0 2px;
`;

const BtnWrap = styled.div`
    flex-shrink: 0;
`;