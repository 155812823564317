import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Center, MaxWidthWrap, PaddingWrap, Row } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import CenterTitleItem from '../../common/item/CenterTitleItem';
import { momentFormat } from '../../../utils/format/time';
import BasicText from '../../basic/BasicText';
import { apiNewsDetail } from '../../../api/mainApi';
import { IMG_URI } from '../../../api/config';

export default function NewsDetailPage() {
    const params = useParams();
    let pId = Number(params.pId);
    const theme = useTheme();
    const [item,setItem] = useState({notice_id:0,notice_title:'',notice_content:'',notice_create_time:0,notice_img:[''],notice_category:'News'});
    const [time,setTime] = useState('');
    
    useEffect(()=>{
        getData();
    },[pId])

    const getData = async() =>{
        const res = await apiNewsDetail(pId);
        if(!res.result){
            return;
        }
        setItem(res.data);
    }
    let timeChange = momentFormat('YYYY-MM-DD',item.notice_create_time);
    return (
        <Wrap>
            <MaxWidthWrap>
                <PaddingWrap padding='100px 0 0'>
                    <CenterTitleItem title={item.notice_title} sub={timeChange} subMarginTop={1}></CenterTitleItem>
                </PaddingWrap>
                <CategoryWrap>
                    <BasicText theme='black' fontSize={18}>{item.notice_category}</BasicText>
                </CategoryWrap>
                <PaddingWrap padding='80px 0 0'>
                    {item.notice_id !== 0 && 
                        item.notice_img.map((data,index)=>{
                            return(
                                <ImgWrap key={`notice-img-${index}`}>
                                    <img alt='news-img' src={IMG_URI+data}></img>
                                </ImgWrap>
                            )
                        })
                    }
                </PaddingWrap>
                <PaddingWrap padding='60px 0 0'>
                    <BasicText theme='black' fontSize={18} dangerouslySetInnerHTML={{__html:item.notice_content}}></BasicText>
                </PaddingWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const CategoryWrap = styled(Row)`
    justify-content: end;
    padding-bottom: 15px;
    border-bottom: 1px solid #fff;
`;

const ImgWrap = styled(Center)`
    max-width: 100%;
    margin: 35px;
    &>img{width:100%;}
`;