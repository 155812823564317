import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Input } from '@mui/material'
import React, { useState } from 'react'
import BasicText from '../../basic/BasicText';
import styled from '@emotion/styled';
import { useGlobalDialog } from '../../../utils/custom-hooks/common/useGlobalDialog';
import { setDialogHideCreator } from '../../../store/common/globalDialog';
import { useDispatch } from 'react-redux';

interface modalDialogPageType {
    openChk:boolean;
    setOpenChk?:any;
    chainChk?:string;
}

export default function ModalDialogPage({openChk,setOpenChk,chainChk}:modalDialogPageType) {
    const modalInfo = useGlobalDialog();
    const dispatch = useDispatch();
    const close = () =>{
        setOpenChk(false);
        dispatch(setDialogHideCreator());
    }
    
    return (
        <div>
            <Dialog
                open={openChk || modalInfo.modalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onBackdropClick={(e)=>{}}
                sx={{'.MuiDialog-paper':{bgcolor:'#fff',color:'#000',border:'1px solid #000',borderRadius:'15px', maxWidth:'450px'}}}
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
                    Welcome Meca✨ 
                </DialogTitle>
                <DialogContent sx={{minHeight:100,textAlign:'center'}}>
                    <BasicText>
                        { openChk &&
                        `Please change network 
                            ${chainChk === '' ?
                            ' BSC or ETH '
                            : ` ${chainChk} `}
                        and confirm your address.
                        `
                        }
                        {modalInfo.modalOpen &&
                            modalInfo.modalText
                        }
                    </BasicText>
                </DialogContent>
                <DialogActions>
                    <BtnWrap>
                        <Button onClick={close} sx={{bgcolor:'#35365c','&:hover':{bgcolor:'#35365c'}}}>
                            OK
                        </Button>
                    </BtnWrap>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const ImgWrap = styled.div`
    max-width: 140px;
    text-align: center;
    margin: 15px auto;
    &>img{
        max-width:100%;
    }
`;

const BtnWrap = styled.div`
    width:100%;
    text-align: center;
    & button{color:#fff; border-radius:15px; text-transform:capitalize;}
    & button:nth-of-type(1){width:40%;}
`;