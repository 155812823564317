import React, { useCallback, useEffect, useRef } from 'react'
import {numberWithComma} from '../../format/number';

export default function useScrollCount(end:any, start = 0, duration = 300) {
    const element = useRef<any>(null);
    const observer = useRef<any>(null);
    const stepTime = Math.abs(Math.floor(duration / (end - start)));

    const plus = (current:any) =>{
        let count = 0;
        const timer = setInterval(()=>{
            if(end === 0) return;
            count = count + 0.01;
            let newNum = end + Number(count.toFixed(2));
            let numToString = numberWithComma(newNum);
            
            current.innerHTML = numToString;
        },1000);
        return () => clearInterval(timer);
    }

    const onScroll = useCallback(([entry]:any) => {
            const { current }:any = element;
            if (entry.isIntersecting) {
                let currentNumber = start;
                let countNumber = Math.ceil(end/111);
                const counter = setInterval(() => {
                    currentNumber += countNumber;
                    current.innerHTML = numberWithComma(currentNumber);
                    if (currentNumber >= end) {
                        current.innerHTML = numberWithComma(end);
                        clearInterval(counter);
                        observer.current.disconnect(element.current);
                        plus(current);
                    }
                }, stepTime);
            }
    },[end, start, stepTime, element],);
  
    useEffect(() => {
        if (element.current) {
            observer.current = new IntersectionObserver(onScroll, { threshold: 0.7 });
            observer.current.observe(element.current);
        }
  
        return () => observer && observer.current.disconnect();
    }, [onScroll]);
  
    return {
        ref: element,
    };
}
