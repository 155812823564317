import styled from '@emotion/styled';
import React, { useState } from 'react'
import { FaTelegramPlane,FaTwitter,FaEllipsisH } from 'react-icons/fa'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { Center, Row } from '../../../style/basic/commonStyle';
import { twitterLink, telKor, telRom, telThai, telViet } from '../../../api/config';

interface snsItemType{
    sns?:string;
    link?:string;
    background?:string;
    detailTitle?:string;
}

export default function SnsItem({detailTitle,sns,background}:snsItemType) {
    const theme = useTheme();
    const [telOpen, setTelOpen] = useState(false);
    const clickEvent = () =>{
        if(sns==='twitter'){
            location.href = twitterLink;
        }
        if(sns==='telegram'){
            setTelOpen(!telOpen);
        }
    }

    const telClick = (link?:string) =>{
        setTelOpen(false);
        if(link === undefined) return;
        location.href = link;
    }

    return (
        <BigWrap>
            <Wrap background={background} onClick={()=>clickEvent()}>
                {sns==='twitter' &&
                    <FaTwitter color={theme.black} size={20} ></FaTwitter>
                }
                {sns==='telegram' &&
                    <FaTelegramPlane color={theme.black} size={20}></FaTelegramPlane>
                    
                }
                {sns==='menu' &&
                    <FaEllipsisH color={theme.black} size={20}></FaEllipsisH>
                }
            </Wrap>
            {telOpen &&
                <TelBox>
                    <TelImg alt='kor' src='/img/flag/korea_south.png' onClick={()=>telClick(telKor)}></TelImg>
                    <TelImg alt='thai' src='/img/flag/thailand.png' onClick={()=>telClick(telThai)}></TelImg>
                    <TelImg alt='viet' src='/img/flag/vietnam.png' onClick={()=>telClick(telViet)}></TelImg>
                    <TelImg alt='rom' src='/img/flag/romania.png' onClick={()=>telClick(telRom)}></TelImg>
                </TelBox>
            }
        </BigWrap>
    )
}

const BigWrap = styled.div`
    position: relative;
`;

const Wrap = styled(Center)<{background?:string}>`
    background-color: ${props=>props.background ?? 'transparent'};
    border-radius: 100%;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
`;

const TelBox = styled(Row)`
    margin-top: 5px;
`;

const TelImg = styled.img`
    width: 34px;
    margin-right: 5px;
    &:last-of-type{margin-right:0;}
    cursor: pointer;
`;