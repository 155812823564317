import {createRoot} from "react-dom/client";
import App from './App';
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const container:any = document.getElementById('root');
const root = createRoot(container);

const getLibrary = (provider: any) => {
    return new Web3Provider(provider);
};

root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App/>
    </Web3ReactProvider>
);