import styled from '@emotion/styled';
import React from 'react'
import CountItem from '../item/CountItem';

export default function CountPart({nftCount,mecaCount,netState}:any) {
    let nft = (nftCount === 0 && netState ==='nft') ? '' : nftCount;
    let meca = (mecaCount === 0 && netState ==='meca') ? '' : mecaCount;
    return (
        <Wrap>
            <CountItem title='MY NFTs' count={nft}></CountItem>
            <CountItem title='MECA' count={meca}></CountItem>
        </Wrap>
    )
}

const Wrap = styled.div`
    display: grid;
    gap: 25px;
    grid-template-columns: 3fr 7fr;
    grid-template-rows : 270px ;
    @media ${({theme}:any)=> theme.media.m}{
        display: block;
        &>div{margin-top:15px;}
    }
`;
