import styled from '@emotion/styled'
import React from 'react'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import BasicText from '../../basic/BasicText';
import TitleItem from '../../common/item/TitleItem';
import BoardPart from './BoardPart';
import BoardBtnItem from '../item/BoardBtnItem';
import { Center } from '../../../style/basic/commonStyle';
import BoardListPart from './BoardListPart';

interface boardPartType{
    data?:any;
}

export default function BoardWrapPart({data}:boardPartType) {
    const theme = useTheme();
    
    return (
        <Wrap>
            <TitleItem 
                lineState='top'
                title1='Open Revenue' 
                sub1='Meca is the best of transparent project of Casino by opening profit transparent. '
                sub2='You can earn forever casino operating profits just by holding Meca NFT. '
                subTheme={'subtext'}
            >
            </TitleItem>
            <BoardWrap>
                <BoardListPart list={data}></BoardListPart>
            </BoardWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 100px;
`;

const BoardWrap = styled.div`
    margin-top: 30px;
    /* overflow-x: auto; */
`;

const DateWrap = styled.div<{borderColor?:string}>`
    border: ${props => props.borderColor === undefined ? 'transparent' : `2px solid ${props.borderColor}`};
    &>p{display:block; text-align:center;}
    padding: 0 16px 5px;
    border-radius: ${({theme}:any)=>theme.borderRadius};
`;