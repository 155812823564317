import React,{ useEffect,useRef } from "react";
import { useLocation } from "react-router-dom";

export default function useScrollMove() {
    const {hash} = useLocation();
    const about = useRef<HTMLDivElement>(null);
    const collection = useRef<HTMLDivElement>(null);
    const roadmap = useRef<HTMLDivElement>(null);
    const faq = useRef<HTMLDivElement>(null);

    return {about,collection,roadmap,faq};
}