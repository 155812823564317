import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import {PieChart} from 'react-minimal-pie-chart';
import { Center } from '../../../style/basic/commonStyle';

export default function AboutContentPartChartItem({chart}:any) {
    
    return (
        <Wrap>
            <PieChart
                data={chart}
            ></PieChart>
            <ShadowWrap></ShadowWrap>
            <BorderWrap></BorderWrap>
            <ImgWrap>
                <ImgItemWrap>
                    <img alt='logo' src='/img/logo/meca_token.png'></img>
                </ImgItemWrap>
            </ImgWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
    &>svg{position: relative;}
`;

const ShadowWrap = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    width:99%;
    height:99%;
    border-radius: 100%;
    box-shadow: 0 0 36px #079ae9;
    transform: translate(-50%,-50%);
`;

const BorderWrap = styled.div`
    position: absolute;
    border: 10px solid #000034;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:95%;
    height: 95%;
    border-radius: 100%;
`;

const ImgWrap = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    width: 50%;
    transform: translate(-50%,-50%);
`;

const ImgItemWrap = styled(Center)`
    width: 100%;
    height: 100%;
    background-color: #000034;
    border-radius: 100%;
    padding: 15px;
    &>img{max-width:100%;}
`;