// export const mecaTokenAddress = `0x46Ad5f77D1E51F5b6e7492e6f0D594a1e8A132e9`;
// export const mecaTokenAbi = [
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_addr",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_value",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "addTokenLock",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "owner_",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "string",
// 				"name": "name_",
// 				"type": "string"
// 			},
// 			{
// 				"internalType": "string",
// 				"name": "symbol_",
// 				"type": "string"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "totalSupply_",
// 				"type": "uint256"
// 			},
// 			{
// 				"internalType": "uint8",
// 				"name": "decimals_",
// 				"type": "uint8"
// 			}
// 		],
// 		"stateMutability": "nonpayable",
// 		"type": "constructor"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "AddTokenLock",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_addr",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_value",
// 				"type": "uint256"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_release_time",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "addTokenLockDate",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "time",
// 				"type": "uint256"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "AddTokenLockDate",
// 		"type": "event"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "owner",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "spender",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "value",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "Approval",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "spender",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "approve",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "burn",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "account",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "burnFrom",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "_enable",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "enableTransfer",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "previousOwner",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "newOwner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "OwnershipTransferred",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_addr",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_index",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "removeLockDate",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "time",
// 				"type": "uint256"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "amount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "RemoveLockDate",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "renounceOwnership",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_to",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_value",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "transfer",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "success",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "from",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "uint256",
// 				"name": "value",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "Transfer",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_from",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "_to",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_value",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "transferFrom",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "success",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "newOwner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "transferOwnership",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "owner",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "spender",
// 				"type": "address"
// 			}
// 		],
// 		"name": "allowance",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "account",
// 				"type": "address"
// 			}
// 		],
// 		"name": "balanceOf",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_sender",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "_value",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "canTransferIfLocked",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "decimals",
// 		"outputs": [
// 			{
// 				"internalType": "uint8",
// 				"name": "",
// 				"type": "uint8"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_addr",
// 				"type": "address"
// 			}
// 		],
// 		"name": "getMinLockedAmount",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "locked",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_addr",
// 				"type": "address"
// 			}
// 		],
// 		"name": "LockDateList",
// 		"outputs": [
// 			{
// 				"components": [
// 					{
// 						"internalType": "uint256",
// 						"name": "latestReleaseTime",
// 						"type": "uint256"
// 					},
// 					{
// 						"components": [
// 							{
// 								"internalType": "uint256",
// 								"name": "amount",
// 								"type": "uint256"
// 							},
// 							{
// 								"internalType": "uint256",
// 								"name": "time",
// 								"type": "uint256"
// 							}
// 						],
// 						"internalType": "struct TokenLock.TokenLockInfo[]",
// 						"name": "tokenLocks",
// 						"type": "tuple[]"
// 					}
// 				],
// 				"internalType": "struct TokenLock.TokenLockState",
// 				"name": "",
// 				"type": "tuple"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_sender",
// 				"type": "address"
// 			}
// 		],
// 		"name": "lockVolumeAddress",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "locked",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "name",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "owner",
// 		"outputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "symbol",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "totalSupply",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "transferEnabled",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	}
// ];

// export const mecaNftAddress = `0x9a51f589bA428707Ed21098A74a72c599B2b75A0`;
// export const mecaNftAbi = [
// 	{
// 		"inputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "constructor"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "owner",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "approved",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "Approval",
// 		"type": "event"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "owner",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "operator",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": false,
// 				"internalType": "bool",
// 				"name": "approved",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "ApprovalForAll",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "approve",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "_mintAmount",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "mint",
// 		"outputs": [],
// 		"stateMutability": "payable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "_mintAmount",
// 				"type": "uint256"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "_receiver",
// 				"type": "address"
// 			}
// 		],
// 		"name": "mintForAddress",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "previousOwner",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "newOwner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "OwnershipTransferred",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "renounceOwnership",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "from",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "safeTransferFrom",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "from",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			},
// 			{
// 				"internalType": "bytes",
// 				"name": "data",
// 				"type": "bytes"
// 			}
// 		],
// 		"name": "safeTransferFrom",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "operator",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "bool",
// 				"name": "approved",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "setApprovalForAll",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "_cost",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "setCost",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "string",
// 				"name": "_hiddenMetadataUri",
// 				"type": "string"
// 			}
// 		],
// 		"name": "setHiddenMetadataUri",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "_maxMintAmountPerTx",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "setMaxMintAmountPerTx",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "_state",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "setPaused",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "_state",
// 				"type": "bool"
// 			}
// 		],
// 		"name": "setRevealed",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "string",
// 				"name": "_uriPrefix",
// 				"type": "string"
// 			}
// 		],
// 		"name": "setUriPrefix",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "string",
// 				"name": "_uriSuffix",
// 				"type": "string"
// 			}
// 		],
// 		"name": "setUriSuffix",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"anonymous": false,
// 		"inputs": [
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "from",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"indexed": true,
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "Transfer",
// 		"type": "event"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "from",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "to",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "transferFrom",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "newOwner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "transferOwnership",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "withdraw",
// 		"outputs": [],
// 		"stateMutability": "nonpayable",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "owner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "balanceOf",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "cost",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "getApproved",
// 		"outputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "hiddenMetadataUri",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "owner",
// 				"type": "address"
// 			},
// 			{
// 				"internalType": "address",
// 				"name": "operator",
// 				"type": "address"
// 			}
// 		],
// 		"name": "isApprovedForAll",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "maxMintAmountPerTx",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "maxSupply",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "name",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "owner",
// 		"outputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "ownerOf",
// 		"outputs": [
// 			{
// 				"internalType": "address",
// 				"name": "",
// 				"type": "address"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "paused",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "revealed",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "bytes4",
// 				"name": "interfaceId",
// 				"type": "bytes4"
// 			}
// 		],
// 		"name": "supportsInterface",
// 		"outputs": [
// 			{
// 				"internalType": "bool",
// 				"name": "",
// 				"type": "bool"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "symbol",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "_tokenId",
// 				"type": "uint256"
// 			}
// 		],
// 		"name": "tokenURI",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "totalSupply",
// 		"outputs": [
// 			{
// 				"internalType": "uint256",
// 				"name": "",
// 				"type": "uint256"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "uriPrefix",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [],
// 		"name": "uriSuffix",
// 		"outputs": [
// 			{
// 				"internalType": "string",
// 				"name": "",
// 				"type": "string"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	},
// 	{
// 		"inputs": [
// 			{
// 				"internalType": "address",
// 				"name": "_owner",
// 				"type": "address"
// 			}
// 		],
// 		"name": "walletOfOwner",
// 		"outputs": [
// 			{
// 				"internalType": "uint256[]",
// 				"name": "",
// 				"type": "uint256[]"
// 			}
// 		],
// 		"stateMutability": "view",
// 		"type": "function"
// 	}
// ];

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
// export const testMecaTokenAddress = `0x41Eb2A5E9734F100D3aF5B89E802C63f41a68DCF`;
// export const mecaTokenAddress = `0xc502cdE1c1BC24B181fDf30a03A7f7fEAC27846d`;
export const mecaTokenAddress = `0xc502cdE1c1BC24B181fDf30a03A7f7fEAC27846d`;
export const mecaTokenAbi = [
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"owner_",
	"type":"address"
	},
	{
	"internalType":"string",
	"name":"name_",
	"type":"string"
	},
	{
	"internalType":"string",
	"name":"symbol_",
	"type":"string"
	},
	{
	"internalType":"uint256",
	"name":"totalSupply_",
	"type":"uint256"
	},
	{
	"internalType":"uint8",
	"name":"decimals_",
	"type":"uint8"
	}
	],
	"stateMutability":"nonpayable",
	"type":"constructor"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	}
	],
	"name":"AddTokenLock",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"time",
	"type":"uint256"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	}
	],
	"name":"AddTokenLockDate",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"owner",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"spender",
	"type":"address"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"value",
	"type":"uint256"
	}
	],
	"name":"Approval",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"previousOwner",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"newOwner",
	"type":"address"
	}
	],
	"name":"OwnershipTransferred",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"time",
	"type":"uint256"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	}
	],
	"name":"RemoveLockDate",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"from",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"indexed":false,
	"internalType":"uint256",
	"name":"value",
	"type":"uint256"
	}
	],
	"name":"Transfer",
	"type":"event"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_addr",
	"type":"address"
	}
	],
	"name":"LockDateList",
	"outputs":[
	{
	"components":[
	{
	"internalType":"uint256",
	"name":"latestReleaseTime",
	"type":"uint256"
	},
	{
	"components":[
	{
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	},
	{
	"internalType":"uint256",
	"name":"time",
	"type":"uint256"
	}
	],
	"internalType":"struct TokenLock.TokenLockInfo[]",
	"name":"tokenLocks",
	"type":"tuple[]"
	}
	],
	"internalType":"struct TokenLock.TokenLockState",
	"name":"",
	"type":"tuple"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_addr",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"_value",
	"type":"uint256"
	}
	],
	"name":"addTokenLock",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_addr",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"_value",
	"type":"uint256"
	},
	{
	"internalType":"uint256",
	"name":"_release_time",
	"type":"uint256"
	}
	],
	"name":"addTokenLockDate",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"owner",
	"type":"address"
	},
	{
	"internalType":"address",
	"name":"spender",
	"type":"address"
	}
	],
	"name":"allowance",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"spender",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	}
	],
	"name":"approve",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"account",
	"type":"address"
	}
	],
	"name":"balanceOf",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	}
	],
	"name":"burn",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"account",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"amount",
	"type":"uint256"
	}
	],
	"name":"burnFrom",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_sender",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"_value",
	"type":"uint256"
	}
	],
	"name":"canTransferIfLocked",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"decimals",
	"outputs":[
	{
	"internalType":"uint8",
	"name":"",
	"type":"uint8"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"bool",
	"name":"_enable",
	"type":"bool"
	}
	],
	"name":"enableTransfer",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_addr",
	"type":"address"
	}
	],
	"name":"getMinLockedAmount",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"locked",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_sender",
	"type":"address"
	}
	],
	"name":"lockVolumeAddress",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"locked",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"name",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"owner",
	"outputs":[
	{
	"internalType":"address",
	"name":"",
	"type":"address"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_addr",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"_index",
	"type":"uint256"
	}
	],
	"name":"removeLockDate",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"renounceOwnership",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"symbol",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"totalSupply",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_to",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"_value",
	"type":"uint256"
	}
	],
	"name":"transfer",
	"outputs":[
	{
	"internalType":"bool",
	"name":"success",
	"type":"bool"
	}
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"transferEnabled",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_from",
	"type":"address"
	},
	{
	"internalType":"address",
	"name":"_to",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"_value",
	"type":"uint256"
	}
	],
	"name":"transferFrom",
	"outputs":[
	{
	"internalType":"bool",
	"name":"success",
	"type":"bool"
	}
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"newOwner",
	"type":"address"
	}
	],
	"name":"transferOwnership",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	}
];

// export const testMecaNftAddress = `0x02875BdcE7d3a264A8BB3FF154F56A69A9F3bb76`;
// export const mecaNftAddress = `0x30b16Bed87e757d72e5f5D08941ff62f47BAa93f`;
export const mecaNftAddress = `0x30b16Bed87e757d72e5f5D08941ff62f47BAa93f`;
export const mecaNftAbi = [
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"owner_",
	"type":"address"
	}
	],
	"stateMutability":"nonpayable",
	"type":"constructor"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"owner",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"approved",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"Approval",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"owner",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"operator",
	"type":"address"
	},
	{
	"indexed":false,
	"internalType":"bool",
	"name":"approved",
	"type":"bool"
	}
	],
	"name":"ApprovalForAll",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"previousOwner",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"newOwner",
	"type":"address"
	}
	],
	"name":"OwnershipTransferred",
	"type":"event"
	},
	{
	"anonymous":false,
	"inputs":[
	{
	"indexed":true,
	"internalType":"address",
	"name":"from",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"indexed":true,
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"Transfer",
	"type":"event"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"approve",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"owner",
	"type":"address"
	}
	],
	"name":"balanceOf",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"cost",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"getApproved",
	"outputs":[
	{
	"internalType":"address",
	"name":"",
	"type":"address"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"hiddenMetadataUri",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"owner",
	"type":"address"
	},
	{
	"internalType":"address",
	"name":"operator",
	"type":"address"
	}
	],
	"name":"isApprovedForAll",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"maxMintAmountPerTx",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"maxSupply",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"_mintAmount",
	"type":"uint256"
	}
	],
	"name":"mint",
	"outputs":[
	],
	"stateMutability":"payable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"_mintAmount",
	"type":"uint256"
	},
	{
	"internalType":"address",
	"name":"_receiver",
	"type":"address"
	}
	],
	"name":"mintForAddress",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"name",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"owner",
	"outputs":[
	{
	"internalType":"address",
	"name":"",
	"type":"address"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"ownerOf",
	"outputs":[
	{
	"internalType":"address",
	"name":"",
	"type":"address"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"paused",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"renounceOwnership",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"revealed",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"from",
	"type":"address"
	},
	{
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"safeTransferFrom",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"from",
	"type":"address"
	},
	{
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	},
	{
	"internalType":"bytes",
	"name":"data",
	"type":"bytes"
	}
	],
	"name":"safeTransferFrom",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"operator",
	"type":"address"
	},
	{
	"internalType":"bool",
	"name":"approved",
	"type":"bool"
	}
	],
	"name":"setApprovalForAll",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"_cost",
	"type":"uint256"
	}
	],
	"name":"setCost",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"string",
	"name":"_hiddenMetadataUri",
	"type":"string"
	}
	],
	"name":"setHiddenMetadataUri",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"_maxMintAmountPerTx",
	"type":"uint256"
	}
	],
	"name":"setMaxMintAmountPerTx",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"bool",
	"name":"_state",
	"type":"bool"
	}
	],
	"name":"setPaused",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"bool",
	"name":"_state",
	"type":"bool"
	}
	],
	"name":"setRevealed",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"string",
	"name":"_uriPrefix",
	"type":"string"
	}
	],
	"name":"setUriPrefix",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"string",
	"name":"_uriSuffix",
	"type":"string"
	}
	],
	"name":"setUriSuffix",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"bytes4",
	"name":"interfaceId",
	"type":"bytes4"
	}
	],
	"name":"supportsInterface",
	"outputs":[
	{
	"internalType":"bool",
	"name":"",
	"type":"bool"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"symbol",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"uint256",
	"name":"_tokenId",
	"type":"uint256"
	}
	],
	"name":"tokenURI",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"totalSupply",
	"outputs":[
	{
	"internalType":"uint256",
	"name":"",
	"type":"uint256"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"from",
	"type":"address"
	},
	{
	"internalType":"address",
	"name":"to",
	"type":"address"
	},
	{
	"internalType":"uint256",
	"name":"tokenId",
	"type":"uint256"
	}
	],
	"name":"transferFrom",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"newOwner",
	"type":"address"
	}
	],
	"name":"transferOwnership",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"uriPrefix",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"uriSuffix",
	"outputs":[
	{
	"internalType":"string",
	"name":"",
	"type":"string"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	{
	"internalType":"address",
	"name":"_owner",
	"type":"address"
	}
	],
	"name":"walletOfOwner",
	"outputs":[
	{
	"internalType":"uint256[]",
	"name":"",
	"type":"uint256[]"
	}
	],
	"stateMutability":"view",
	"type":"function"
	},
	{
	"inputs":[
	],
	"name":"withdraw",
	"outputs":[
	],
	"stateMutability":"nonpayable",
	"type":"function"
	}
	];