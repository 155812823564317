
export const about2ImgData=[
    {id:1,content:'Connect Your Wallet', icon:'wallet'},
    {id:2,content:'Select Your Quantity', icon:'shop'},
    {id:3,content:'Buy Your NFTs', icon:'nft'},
    {id:4,content:'Receive Your Meca Token by Casino profit', icon:'token'},
]

export const aboutChartData = async() =>{
    return{
        list:[
            {id:1,name:'Ecosystem',value:50,color:'#2546a3'},
            {id:2,name:'Partner',value:5,color:'#b72d65'},
            {id:3,name:'R & D',value:10,color:'#4da5bf'},
            {id:4,name:'Marketing',value:7,color:'#508795'},
            {id:5,name:'Foundataion Reserve',value:18,color:'#7cf7b1'},
            {id:6,name:'Advisor',value:3,color:'#e77b76'},
            {id:7,name:'Private Sale',value:7,color:'#00ceff'},
        ]
    }
}
