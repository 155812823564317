import styled from '@emotion/styled';
import React,{useRef} from 'react'
import BasicText from '../../basic/BasicText';
import DetailItem from './DetailItem';
import ListingItem from './ListingItem';
import PropertiesItem from './PropertiesItem';

interface informationItemType{
    background?:string;
    type?:string;
    data?:any;
    clickEvent?:any;
}

export default function InformationItem({type,background,data,clickEvent}:informationItemType) {
    const titleItemWrap = useRef<HTMLDivElement>(null);

    return (
        <Wrap background={background} type={type}>
            <ContentWrap type={type}>
                <TitleWrap>
                    <TitleItemWrap ref={titleItemWrap} borderHeight={titleItemWrap.current?.offsetHeight}>
                        <BasicText theme='white' fontSize={22} fontWeight={700}>{type}</BasicText>
                    </TitleItemWrap>
                </TitleWrap>
                <TextWrap>
                    {data !== undefined && 
                        type==='Details' ? 
                            <DetailItem data={data}></DetailItem>
                        : type === 'Listing' ?
                            <ListingItem data={data} clickEvent={clickEvent}></ListingItem>
                        : type === 'Properties' ?
                            <PropertiesItem data={data}></PropertiesItem>
                        : <></>
                    }
                </TextWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{background?:string,type?:string}>`
    width:100%;
    border-radius: ${({theme}:any)=>theme.borderRadius};
    background-color: ${props=>props.background ?? 'transparent'};
    border: 1px solid #fff;
    position: relative;
    &::before{
        content:''; display: block; padding-bottom: ${props => props.type !== 'Properties' ? 60 : 0}%;
    }
    @media ${({theme}:any)=>theme.media.m}{
        &::before{
            content:none;
        }
    }
`;

const ContentWrap = styled.div<{type?:string}>`
    position: ${props => props.type !== 'Properties' ? 'absolute' : 'static'};
    top:0;
    left:0;
    width:100%;
    height:100%;
    @media ${({theme}:any)=>theme.media.m}{
        position: static;
    }
`;

const TitleWrap = styled.div`
    position: relative;
    padding: 20px 0 0px;
`

const TitleItemWrap = styled.div<{borderHeight?:any}>`
    display: inline-block;
    background-color: #fff;
    padding:5px 0 5px 25px;
    min-width: 130px;
    position: relative;
    margin-left: -10px;
    &::before{
        content:''; width: 0; height:0; border-bottom: ${props=>props.borderHeight ?? 43}px solid #fff; border-right:${props=>props.borderHeight ?? 43}px solid transparent;
        position: absolute;
        top:0; left:100%;
    }
`;

const TextWrap = styled.div`
    padding: 20px 15px;
    height: calc(100% - 63px);
    @media ${({theme}:any)=>theme.media.m}{
        height: 100%;
    }
`;