import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderWrap = styled.div`
  width:100%;
  height: 100%;
  .slick-slider {
    .slick-arrow {
      z-index: 1;
      background: white;
      border: none;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      box-shadow: rgb(0 0 0 / 15%) 0px 4px 10px;
      color: black;

      > svg {
        transform: translate(0,-10px);
      }
    }

  .slick-slide {
  }

  .slick-dots {
    position: static;
  }
  .slick-dots li button:before{content:''}
  .slick-dots li {width:30px; height:6px; background-color:#ddd; border-radius:50px;}
  .slick-dots li button{width:100%; height:100%; padding:0;}
  .slick-dots li.slick-active{background-color:#333; width:60px;}
}
`
interface slideType{
  dots?:boolean;
  infinitySlide?:boolean;
  slideSpeed?:number;
  showSlideCount?:number;
  arrow?: boolean;
  children?:any;
  data?:any;
  autoplay?:boolean;
  slider?:any;
  nowPage?:any;
  setNowPage?:any;
}

export default function SliderPart({dots=false, infinitySlide=true, slideSpeed=300, showSlideCount=4, children,arrow=false,autoplay=false,data,slider,nowPage,setNowPage}:slideType) {
  const settings = {
    dots: dots,
    infinite: infinitySlide,
    speed: slideSpeed,
    slidesToShow: showSlideCount,
    arrows:arrow,
    autoplay:autoplay,
    nextArrow: <button><IoChevronForwardSharp size="30" /></button>,
    prevArrow: <button><IoChevronBackSharp size="30" /></button>,
    responsive: [ // 반응형 웹 구현 옵션
		{  
			breakpoint: 1200, //화면 사이즈 960px일 때
			settings: {
				//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
				slidesToShow:3 
			} 
		},
		{  
			breakpoint: 1000, //화면 사이즈 960px일 때
			settings: {
				//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
				slidesToShow:2 
			} 
		},
		{ 
			breakpoint: 768, //화면 사이즈 768px일 때
			settings: {	
				//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
				slidesToShow:1 
			} 
		}
	]
  };

  return (
    <SliderWrap>
      <Slider ref={slider} {...settings} initialSlide={nowPage} beforeChange={(slide,newSlide)=>setNowPage(newSlide)}>
        {children}
      </Slider>
    </SliderWrap>
  )
}