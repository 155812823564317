import styled from '@emotion/styled';
import React from 'react'
import { Center, Row } from '../../../style/basic/commonStyle';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import TitleItem from '../../common/item/TitleItem';
import BtnItem from '../../header/item/BtnItem';
import GridItem from '../../common/item/GridItem';
import { about2ImgData } from '../../../data/dummy';
import BasicText from '../../basic/BasicText';
import { BsWallet2 } from 'react-icons/bs';

export default function AboutContentPart02() {
    const theme = useTheme();
    const clickEvent = () =>{
        location.href = 'https://pancakeswap.finance/swap?chain=bsc&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xc502cdE1c1BC24B181fDf30a03A7f7fEAC27846d';
    }
    return (
        <Wrap>
            <LeftWrap>
                <TitleItem 
                    title1='How to get Meca?' 
                    lineState='bottom'
                    sub1='Meca is the best of transparent project of Casino by opening profit transparent. You can earn forever casino operating profits just by holding Meca NFT.'
                    subTheme={'subtext'}
                ></TitleItem>
                <BtnWrap>
                    <BtnItem clickEvent={clickEvent} borderColor={theme.btnBorder} text='Go to pancakeswap' borderRadius={20}
                        children1={
                            <BtnItemInner bgColor={theme.comingBtn}>
                                <img alt='pancakeswap-logo' src='/img/logo/pancakeswap-logo.png'></img>
                            </BtnItemInner>
                        }
                    ></BtnItem>
                </BtnWrap>
            </LeftWrap>
            <SpaceWrap></SpaceWrap>
            <RightWrap>
                {about2ImgData.map((data,index)=>{
                    return(
                        <GridItem key={`about2-${data.id}`} bottomState='70%' background={theme.listItemBg} link='no' type='about'>
                            <ItemContent>
                                <ItemContentBox>
                                    <div>
                                        <Text>0{index+1}</Text>
                                        <BasicText theme='black' marginTop={1.2} mFontSize={15}>{data.content}</BasicText>
                                    </div>
                                    <IconWrap>
                                        <IconItemWrap bgColor={theme.comingBtn}>
                                            {data.icon === 'wallet' && <IconImg width={65} alt='wallet-icon' src='/img/home/1.png'></IconImg>}
                                            {data.icon === 'shop' && <IconImg width={65} alt='shop-icon' src='/img/home/2.png'></IconImg>}
                                            {data.icon === 'nft' &&  <IconImg width={75} alt='nft-icon' src='/img/home/3.png'></IconImg>}
                                            {data.icon === 'token' && <IconImg width={80} alt='token' src='/img/logo/meca_token.png'></IconImg>}
                                        </IconItemWrap>
                                    </IconWrap>
                                </ItemContentBox>
                            </ItemContent>
                        </GridItem>
                    )
                })}
            </RightWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    align-items: stretch;
    @media ${({theme}:any) => theme.media.m}{
        flex-wrap: wrap;
    }
`;
const LeftWrap = styled.div`
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${({theme}:any) => theme.media.m}{
        width:100%;
    }
`;

const SpaceWrap = styled.div`
    width:2%;
`;

const RightWrap = styled.div`
    width: 55%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    @media ${({theme}:any) => theme.media.m}{
        width:100%;
        margin-top: 30px;
        grid-template-columns: 1fr;
    }
`;

const BtnWrap = styled.div`
    margin-top: 30px;
`;

const BtnItemInner = styled(Center)<{bgColor?:string}>`
    background-color: ${props => props.bgColor ?? 'transparent'};
    width:37px;
    height:30px;
    border-radius: 100%;
    &>img{
        max-width: 80%;
    }
`;

const ItemContent = styled.div`
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    padding: 15px;
`;

const ItemContentBox = styled.div`
    width:100%;
    height: 100%;
    background-color: rgba(255,255,255,0.2);
    border-radius: 10px;
    text-align: left;
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Text = styled.div`
    -webkit-text-stroke: 1px #fff; 
    font-size: 32px; 
    font-weight: 900; 
    color: transparent;
    @media ${({theme}:any)=>theme.media.m}{
        font-size: 26px;
    }
`;

const IconWrap = styled(Row)`
    justify-content: end;
`;

const IconItemWrap = styled(Center)<{bgColor?:string}>`
    background-color: ${props=>props.bgColor ?? 'transparent'};
    width:50px;
    height:50px;
    border-radius: 100%;
    overflow: hidden;
`;

const IconImg = styled.img<{width?:number}>`
    width: ${props=>props.width ?? 80}%;
`;