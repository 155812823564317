import styled from '@emotion/styled';
import React from 'react'

interface CategoryType{
    category?:any;
    padding?:string;
    setSelectCategory?:any;
    height?:string;
}

export default function CategoryPart({category,padding,setSelectCategory,height}:CategoryType) {
    return (
        <>
            {category!==undefined &&
                <CategoryWrap height={height} padding={padding} onChange={(el)=>setSelectCategory(el.target.value)}>
                    {category.map((data:any,index:any)=>{
                        return(
                            <option key={index} value={data.id}>{data.cate}</option>
                        )
                    })}
                </CategoryWrap>
            }
        </>
    )
}

const CategoryWrap = styled.select<{padding?:string,height?:string}>`
    flex-shrink: 0;
    margin-right: 5px;
    padding : ${props=>props.padding ?? '0'};
    height: ${props=>props.height ?? '100%'};
    overflow: hidden;
`;
