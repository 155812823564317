import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Input } from '@mui/material'
import React, { useState } from 'react'
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../../store/common/user';
import {apiUserEmailChk, apiUserEmailCodeChk, apiUserEmailCodeInsert, apiUserEmailDelete, apiUserEmailNoShow, apiUserEmailUpdate} from '../../../api/mainApi';
import BasicText from '../../basic/BasicText';
import styled from '@emotion/styled';
import ModalItem from '../item/ModalItem';

interface emailDialogPageType {
    openChk:boolean;
    setOpenChk?:any;
    setEmail?:any;
    userJoin?:any;
    userId:string;
}

export default function EmailDialogPage({openChk,setOpenChk,userId}:emailDialogPageType) {
    const [value,setValue] = useState('');
    const [code,setCode] = useState('');
    const [codeBox,setCodeBox] = useState(false);
    
    const [modalOpen,setModalOpen] = useState(false);
    const [modalType,setModalType] = useState('');
    const [modalContent1,setModalContent1] = useState('');
    const [modalContent2,setModalContent2] = useState('');

    const dispatch = useDispatch();
    
    const stateReset = async() =>{
        setValue('');
        setCode('');
        setCodeBox(false);
        setOpenChk(false);
        setModalOpen(false);
        setModalType('');
        setModalContent1('');
        setModalContent2('');
        await apiUserEmailNoShow(userId);
    }

    //이메일 입력
    const handleValue = (event:any) =>{
        setValue(event.target.value);
    }
    //코드입력
    const handleCode = (event:any) =>{
        setCode(event.target.value);
    }

    //모달
    const modalState = (type:string,content1:string,content2:string) =>{
        setModalOpen(true);
        setModalType(type);
        setModalContent1(content1);
        setModalContent2(content2);
    }

    //이메일 입력 후 클릭
    const handleClick = async() =>{
        if(value===''){
            modalState('alert','Please enter your e-mail','');
            return;
        }
        if(!validator.isEmail(value)){
            modalState('alert',"It doesn't fit the email format.",'');
            return
        }
        const emailChk = await apiUserEmailChk(value);
        if(!emailChk.result){
            modalState('alert',"This email has already been auth.",'Please check your email.');
            return;
        }
        // 이메일 인증 코드 생성
        const codeCreate = await apiUserEmailCodeInsert(value,userId);
        if(codeCreate.result){
            setCodeBox(true);
            return;
        }
    }

    //코드 입력 후 클릭
    const afterClick = async() =>{
        // 코드체크
        const codeChk = await apiUserEmailCodeChk(code,userId);
        if(!codeChk.result){
            modalState('fail','This code is incorrect.','Please check your code.');
            return;
        }
        // 이메일 업데이트
        const emailUpdate = await apiUserEmailUpdate(value,userId);
        if(!emailUpdate){
            modalState('fail','This code is incorrect.','Please check your code.');
            return;
        }
        await apiUserEmailDelete(userId);
        dispatch(setUserInfo({
            user_email:value,
        }))
        modalState('success','Success!','Welcome Meca✨');
    }

    return (
        <div>
            <Dialog
                open={openChk}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onBackdropClick={(e)=>{}}
                sx={{'.MuiDialog-paper':{bgcolor:'#fff',color:'#000',border:'1px solid #000',borderRadius:'15px'}}}
            >
                <DialogTitle id="alert-dialog-title" sx={{textAlign:'center'}}>
                    {!codeBox ? 'Welcome Meca✨' : 'Check your e-mail'}
                </DialogTitle>
                <DialogContent>
                    {!codeBox ? 
                        <>
                            <BasicText sx={{textAlign:'center'}} color='#000'>If you are from Meca Game Site,</BasicText> 
                            <BasicText sx={{textAlign:'center'}} color='#000'>Enter your email and Click Check.</BasicText> 
                        </> :
                        <>
                            <BasicText sx={{textAlign:'center'}} color='#000'>Check your e-mail, enter your code</BasicText>
                            <BasicText sx={{textAlign:'center'}} color='#000'>and click Auth.</BasicText>
                        </>
                    }
                    {!codeBox &&
                        <>
                            <ImgWrap>
                                <img alt='token' src='/img/logo/meca_token.png'></img>
                            </ImgWrap>
                            <TextField
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={value}
                                onChange={handleValue}
                                inputProps={{readOnly:codeBox ? true : false,placeholder:'E-mail'}}
                                sx={{
                                    '.MuiInput-root':{color:'#000'},
                                    '.MuiInput-root .MuiInput-input':{color:'#000 !important',border:'2px solid #00ceff',borderRadius:'15px',padding: '5px 8px 6px',},
                                    '.MuiInput-root:after':{content:'none',},
                                    '.MuiInput-root:before':{content:'none',},
                                    // '.css-161xg6r-MuiFormLabel-root-MuiInputLabel-root':{color:'#c0c0c0',left:'10px'},
                                }}
                            />
                        </>
                    }
                    {codeBox &&
                        <TextField
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={code}
                            onChange={handleCode}
                            inputProps={{placeholder:'Code'}}
                            sx={{
                                margin:'25px 0 15px',
                                '.MuiInput-root':{color:'#000'},
                                '.MuiInput-root .MuiInput-input':{color:'#000 !important',border:'2px solid #00ceff',borderRadius:'15px',padding: '5px 8px 6px',},
                                '.MuiInput-root:after':{content:'none',},
                                '.MuiInput-root:before':{content:'none',},
                                // '.css-161xg6r-MuiFormLabel-root-MuiInputLabel-root':{color:'#c0c0c0',left:'10px'},
                            }}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <BtnWrap>
                        <Button onClick={stateReset} sx={{bgcolor:'#ababab','&:hover':{bgcolor:'#ababab'}}}>Close</Button>
                        <Button onClick={!codeBox ? handleClick : afterClick} sx={{bgcolor:'#35365c','&:hover':{bgcolor:'#35365c'}}}>
                            {!codeBox ? 'Check' : 'Auth'}
                        </Button>
                    </BtnWrap>
                </DialogActions>
            </Dialog>
            <ModalItem type={modalType} content1={modalContent1} content2={modalContent2} modalOpen={modalOpen} setModalOpen={setModalOpen} stateReset={stateReset}></ModalItem>
        </div>
    )
}

const ImgWrap = styled.div`
    max-width: 140px;
    text-align: center;
    margin: 15px auto;
    &>img{
        max-width:100%;
    }
`;

const BtnWrap = styled.div`
    width:100%;
    & button{color:#fff; border-radius:15px; text-transform:capitalize;}
    & button:nth-of-type(1){margin-right:2%; width:40%;}
    & button:nth-of-type(2){width:58%;}
`;