import React from 'react'
import styled from '@emotion/styled'
import CenterTitleItem from '../../common/item/CenterTitleItem';
import Accordion from '../item/Accordion';
import { FAQ } from '../page/HomePage';
import NoListItem from '../item/NoListItem';

interface faqPartType {
    list:Array<typeof FAQ>;
}

export default function FaqPart({list}:faqPartType) {
    return (
        <Wrap>
            <TitleWrap>
                <TitleBg></TitleBg>
                <CenterTitleItem title='FAQ'></CenterTitleItem>
            </TitleWrap>
            <ListWrap>
                {list[0].faq_id === 0 ?
                    <NoListItem></NoListItem> :
                    list?.map((data,index)=>{
                        return(
                            <Accordion key={`faq-${data.faq_id}`} count={`Q${index+1}`} title={data.faq_title} content={data.faq_content}></Accordion>
                        )
                    })
                }
            </ListWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled.div`
    position: relative;
    &>div:nth-of-type(2){position: relative;}
`;

const TitleBg = styled.div`
    position: absolute;
    top:0;
    left:50%;
    width:30%;
    transform: translateX(-50%);
    background-color: rgba(183, 0, 255, 0.4);
    filter: blur(40px);
    border-radius: 100%;
    &::before{
        content:''; display: block; padding-bottom: 100%;
    }
    @media ${({theme}:any)=>theme.media.m}{
        width: 40%;
    }
`;

const ListWrap = styled.div`
    position: relative;
    margin-top: 30px;
`;
