import React from 'react'
import styled from '@emotion/styled';
import LogoItem from '../item/LogoItem';
import MenuBoxPart from './MenuBoxPart';

export default function LeftLogoPart({addItem,logoType,logoMargin,logo,menuPosition,menu,logoSize,fontSize,menuMarginLeft,menupadding,addPosition,menuClickEvent}:any) {
    
    const type = menuPosition === 'left' || menuPosition ==='center' ? 'none' : 'real';
    
    return (
        <>
            <LeftBox menuPosition={menuPosition}>
                <LogoItem logoType={logoType} logo={logo} logoMargin={logoMargin} logoSize={logoSize}></LogoItem>
                {menuPosition === 'left' && 
                    <MenuBoxPart menu={menu} menuMarginLeft={menuMarginLeft} menupadding={menupadding} fontSize={fontSize} menuClickEvent={menuClickEvent}></MenuBoxPart>
                }
                {addPosition ==='left' && addItem}
            </LeftBox>
            <CenterBox>
                {menuPosition === 'center' && 
                    <MenuBoxPart menu={menu} menuMarginLeft={menuMarginLeft} menupadding={menupadding} fontSize={fontSize} menuClickEvent={menuClickEvent}></MenuBoxPart>
                }
                {addPosition ==='center' && addItem}
            </CenterBox>
            <RightBox>
                <AddWrap>
                    {addPosition ==='right' && addItem}
                </AddWrap>
                {(type ==='none' || type==='real') &&
                    <MenuBoxPart menu={menu} menuMarginLeft={menuMarginLeft} menupadding={menupadding} fontSize={fontSize} type={type} menuM='right' menuClickEvent={menuClickEvent} addItem={addItem}></MenuBoxPart>
                }
            </RightBox>
        </>
    )
}

const LeftBox = styled.div<{menuPosition?:string}>`
    display: ${props=>props.menuPosition === 'left' ? 'flex' : 'block'};
    align-items: center;
`
const CenterBox = styled.div``;
const RightBox = styled.div`
    display: flex;
    align-items: center;
`

const AddWrap = styled.div`
    margin-right: 5px;
    /* @media ${({theme}:any)=>theme.media.m}{
        display: none;
    } */
`;