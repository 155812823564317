import React from 'react'
import styled from '@emotion/styled';
import LogoItem from '../item/LogoItem';
import MenuBoxPart from './MenuBoxPart';

export default function CenterLogoPart({logoType,logoMargin,logo,menuPosition,menu,logoSize,fontSize,menumargin,menupadding}:any) {
    const type = menuPosition === 'left' || menuPosition ==='right' ? 'none' : 'real';
    return (
        <>
            <LeftBox menuPosition={menuPosition}>
                {menuPosition === 'left' && 
                    <MenuBoxPart menu={menu} menumargin={menumargin} menupadding={menupadding} fontSize={fontSize} type={type} menuM='left'></MenuBoxPart>
                }
            </LeftBox>
            <CenterBox>
                <LogoItem logoType={logoType} logo={logo} logoMargin={logoMargin} logoSize={logoSize}></LogoItem>
            </CenterBox>
            <RightBox>
                {menuPosition === 'right' && 
                    <MenuBoxPart menu={menu} menumargin={menumargin} menupadding={menupadding} fontSize={fontSize} type={type} menuM='right'></MenuBoxPart>
                }
            </RightBox>
            <MenuWrap>
                <MenuBoxPart menu={menu} menumargin={menumargin} menupadding={menupadding} fontSize={fontSize}></MenuBoxPart>
            </MenuWrap>
        </>
    )
}

const LeftBox = styled.div<{menuPosition?:string}>`
    display: ${props=>props.menuPosition === 'left' ? 'flex' : 'block'};
    align-items: center;
`
const CenterBox = styled.div``;
const RightBox = styled.div``

const MenuWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
`;