import axios from 'axios';

export const axiosConfigInstance = (path: string,type?:any) =>  axios.create({
    // baseURL: `http://192.168.1.22:10087/${path}`,
    // baseURL: `https://meka.api.cemonemo.co.kr/${path}`,
    // baseURL: `https://meca.api.cemonemo.co.kr/${path}`,
    baseURL: `https://api.mecazone.com/${path}`,
    timeout: 20000,
    headers: {
        ...type
    }
});

// export const IMG_URI = 'http://192.168.1.22:10087/';
// export const IMG_URI = 'https://meka.api.cemonemo.co.kr/';
// export const IMG_URI = 'https://meca.api.cemonemo.co.kr/';
export const IMG_URI = 'https://api.mecazone.com/';

// export const ETH_ID = 5;
// export const ETH_ID = 11155111;
// export const BSC_ID = 97;
export const ETH_ID = 1;
export const BSC_ID = 56;

// export const PROVIDER_NET = 'goerli';
// export const PROVIDER_NET = 'sepolia';
export const PROVIDER_NET = 'mainnet';

export const twitterLink = 'https://twitter.com/MetaCasinoDAO';
export const telViet = 'https://t.me/+9JGeNTYlgVI0OTA1';
export const telThai = 'https://t.me/+LRB7Thjg8O84Mzk1';
export const telRom = 'https://t.me/+7zrQMq3zPj42YzBl';
export const telKor = 'https://t.me/+YhH9lOK0TFNhN2I0';


