import React from 'react'
import styled from '@emotion/styled';
import RightLogoPart from '../part/RightLogoPart';
import LeftLogoPart from '../part/LeftLogoPart'
import CenterLogoPart from '../part/CenterLogoPart'

interface LogoSize{
    w?:string;
    h?:string;
    fontSize?:string;
}

interface menu{
    id:number;
    title:string;
    link:string;
}

interface Header {
    addItem?:any;
    wrapPadding?:string;
    logoPosition?: 'left' | 'center' | 'right';
    logoType?: 'img' | 'text';
    logo?:string;
    menuPosition?:'left' | 'center' | 'right';
    menu?: Array<menu>;
    logoSize?:LogoSize;
    fontSize?:string;
    logoMargin?:string;
    menuMarginLeft?:string;
    menupadding?:string;
    addPosition?:string;
    menuClickEvent?:any;
}

/**
 * 
 * @param addItem 헤더에 추가할 컴포넌트(logoPosition이 left 일때만 가능)
 * @param addPosition 헤더에 추가할 컴포넌트의 위치(logoPosition이 left 일때만 가능)
 * @param wrapPadding 헤더전체 패딩
 * @param logoPosition 로고의 위치
 * @param logoType 로고타입 이미지,글자
 * @param logo 이미지면 이미지 url 아니면 글자
 * @param menuPosition 메뉴 위치
 * @param menu 메뉴의 배열 데이터
 * @param logoSize 로고의 사이즈 지정
 * @param fontSize 메뉴의 폰트사이즈
 * @param logoMargin 로고 마진
 * @param menuMarginLeft 메뉴 마진
 * @param menupadding 메뉴 패딩
 */
export default function HeaderPage({addItem,wrapPadding,logoPosition='left',logoMargin,logoType,logo,menuPosition='left',menu,logoSize,fontSize,menuMarginLeft,menupadding,addPosition,menuClickEvent}:Header) {
    
    return (
        <>
            <Wrap wrapPadding={wrapPadding}>
                {logoPosition === 'left' ? 
                    <LeftLogoPart addItem={addItem} logoType={logoType} logo={logo} logoMargin={logoMargin} menuPosition={menuPosition} menu={menu} menuMarginLeft={menuMarginLeft} menupadding={menupadding} logoSize={logoSize} fontSize={fontSize} addPosition={addPosition} menuClickEvent={menuClickEvent}></LeftLogoPart>
                : logoPosition === 'center' ?
                    <CenterLogoPart logoType={logoType} logo={logo} logoMargin={logoMargin} menuPosition={menuPosition} menu={menu} menuMarginLeft={menuMarginLeft} menupadding={menupadding} logoSize={logoSize} fontSize={fontSize}></CenterLogoPart>
                : logoPosition === 'right' &&
                    <RightLogoPart logoType={logoType} logo={logo} logoMargin={logoMargin} menuPosition={menuPosition} menu={menu} menuMarginLeft={menuMarginLeft} menupadding={menupadding} logoSize={logoSize} fontSize={fontSize}></RightLogoPart>
                }
            </Wrap>
        </>
    )
}

const Wrap = styled.div<{wrapPadding?:string;}>`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding : ${props=>props.wrapPadding ?? '0px'};
    flex-wrap: wrap;
    
`;

