import styled from '@emotion/styled';
import React, { useRef } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { setDialogData } from '../../../store/common/globalDialog';
import { RowBetween } from '../../../style/basic/commonStyle';
import { walletMiddleSubstring } from '../../../utils/format/string';
import { displayedAt } from '../../../utils/format/time';
import BasicText from '../../basic/BasicText';

export default function DetailItem({data}:any) {
    const wrap = useRef<HTMLDivElement>(null);
    const time  = displayedAt(data.last);
    const wallet = (data?.address === undefined || data?.address === '') ? '' : walletMiddleSubstring(data.address) ;
    const dispatch = useDispatch();
    
    const copyAlert = () =>{
        dispatch(setDialogData({
            modalOpen:true,
            modalText:'Wallet address has been copied.',
        }))
    }
    return (
        

        <Wrap ref={wrap}>
            <ItemWrap>
                <BasicText theme='black'>Contract Address</BasicText>
                <AddressItem>
                    <CopyToClipboard text={`${data.address}`} onCopy={copyAlert}>
                        <BasicText theme='comingBtn'>{wallet}</BasicText>
                    </CopyToClipboard>
                </AddressItem>
            </ItemWrap>
            <ItemWrap>
                <BasicText theme='black'>TokenID</BasicText>
                <BasicText theme='comingBtn'>{data?.id}</BasicText>
            </ItemWrap>
            <ItemWrap>
                <BasicText theme='black'>Token Standard</BasicText>
                <BasicText theme='black'>{data?.standard}</BasicText>
            </ItemWrap>
            <ItemWrap>
                <BasicText theme='black'>Blockchain</BasicText>
                <BasicText theme='black'>{data?.chain}</BasicText>
            </ItemWrap>
            <ItemWrap>
                <BasicText theme='black'>Last Updated</BasicText>
                <BasicText theme='black'>{time}</BasicText>
            </ItemWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    height: 100%;
`;

const ItemWrap = styled(RowBetween)`
    height: calc(100% / 5);
`;

const AddressItem = styled.div`
    max-width: 125px;
    width: 100%;
    cursor: pointer;
    /* overflow: hidden;
    text-overflow: ellipsis;
    &>p{
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
    } */
`;