import React from 'react'
import NoItem from '../item/NoItem';

export default function NoItemPart() {
    const noItem = () =>{
        let item = [];
        for (let i = 0; i < 10; i++) {
            item.push(<NoItem key={i}></NoItem>);
        }
        return item;
    }
    return (
        <>
            {noItem()}
        </>
    )
}
