import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import { BiPlayCircle } from 'react-icons/bi';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import ReactPlayer from 'react-player/youtube';

export default function VideoPart({linkList}:any) {
    const theme = useTheme();
    const [videoUrl,setVideoUrl] = useState('');
    useEffect(()=>{
        if(linkList[0].meca_link_id === 0) return;
        if(linkList === undefined || linkList.length === 0){
            setVideoUrl('');
        }else{
            const link = linkList.filter((data:any)=>data.meca_link_type === 1);
            
            setVideoUrl(link[0].meca_link_txt);
        }
    },[linkList]);
    
    return (
        <MaxWidthWrap maxWidth='800px'>
            {videoUrl !== '' &&
                <Wrap>
                    <BgWrap></BgWrap>
                    <ContentWrap>
                        {/* <img alt='video-img' src='/img/dummy/video.jpg'></img>
                        <IconWrap>
                            <BiPlayCircle color={theme.black} size={50}></BiPlayCircle>
                        </IconWrap> */}
                        <VideoWrap url={videoUrl} controls ></VideoWrap>
                    </ContentWrap>
                </Wrap>
            }
        </MaxWidthWrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    position: relative;
    &::before{content:''; display:block; padding-bottom:60%;}
    
`;

const ContentWrap = styled.div`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;
    border-radius: ${({theme}:any)=>theme.borderRadius};
    &>img{
        max-width: 100%;
    }
`;

const IconWrap = styled.div`
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
`;

const BgWrap = styled.div`
    position: absolute;
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: linear-gradient(42deg, #ff0000 0%, #0b0be9 80%);
    border-radius: ${({theme}:any)=>theme.borderRadius};
    filter: blur(30px);
    &::before{
        content:''; position: absolute; top:-30px; left:-30px; width:30%; height: 30%; border-radius:100%; background-color: #ff0000; filter: blur(50px);
    }
    &::after{
        content:''; position: absolute; bottom:-30px; right:-30px; width:30%; height: 30%; border-radius:100%; background-color: #0b0be9; filter: blur(40px);
    }
`;

const VideoWrap = styled(ReactPlayer)`
    width: 100% !important;
    height: 100% !important;
`;