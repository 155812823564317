import e from "express";
import { axiosConfigInstance } from "./config";

const url = `client`;

//user getInfo
export const apiUserInfo = async(wallet:any,chainId?:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'select',
            params:{
                user_wallet:wallet,
                chainId:chainId
            }
        })
        return res.data 
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//user 가입
export const apiUserJoin = async(wallet:any,chainId?:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'insert',
            params:{
                user_wallet:wallet,
                chainId:chainId
            }
        })
        return res.data 
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//email show
export const apiUserEmailNoShow = async(user_id?:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'email_show',
            params:{
                user_id:user_id,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error_en:error,
            result:false,
        }
    }
}

//user 다른지갑주소 추가
export const apiUserWalletAdd = async(user_id?:number,wallet?:any,chainId?:number) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'addWallet',
            params:{
                user_id:user_id,
                user_wallet:wallet,
                chainId:chainId,
            }
        })
        return res.data;
    } catch (error) {
        return{
            error_en:error,
            result:false,
        }
    }
}

//유저 로그
export const apiUserLog = async(user_id?:any,wallet_eth?:string,wallet_bsc?:string) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'user_log',
            params:{
                user_id:user_id,
                wallet_eth:wallet_eth,
                wallet_bsc:wallet_bsc,
            }
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//이메일 체크
export const apiUserEmailChk = async(email:string) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'email_chk',
            params:{
                user_email:email
            }
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//user email code create
export const apiUserEmailCodeInsert = async(email:string,id:string) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'code_insert',
            params:{
                email_email:email,
                user_id:id
            }
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//user email code chk
export const apiUserEmailCodeChk = async(code:string,id:string) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'code_chk',
            params:{
                email_code:code,
                user_id:id
            }
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//user email code chk
export const apiUserEmailUpdate = async(email:string,id:string) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'email_update',
            params:{
                user_email:email,
                user_id:id
            }
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//user email code chk
export const apiUserEmailDelete = async(id:string) =>{
    try {
        const res = await axiosConfigInstance(`${url}/user`).post('',{
            method:'email_delete',
            params:{
                user_id:id
            }
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//user 프로필 사용 x
export const apiUserProfile = async(img?:any,id?:any) =>{
    try {
        const formData = new FormData();
        const type = {'Content-Type':'multipart/form-data'};
        formData.append('method','profile_update');
        formData.append('img',img);
        formData.append('user_id',id);
        const res = await axiosConfigInstance(`${url}/user`).post('',formData);
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

export const apiLinkList = async() =>{
    try {
        const res = await axiosConfigInstance(`${url}/link`).post('',{
            method:'list',
        })
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//open revenue
export const apiRevenueList = async() => {
    try {
        const res = await axiosConfigInstance(`${url}/profit`).post('',{
            method:'list'
        })
        return res.data 
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//카운트 리스트
export const apiCountList = async() =>{
    try {
        const res = await axiosConfigInstance(`${url}/count`).post('',{
            method:'list',
        });
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//로드맵 리스트
export const apiRoadmapList = async(page:number,count:number) =>{
    try {
        const res = await axiosConfigInstance(`${url}/roadmap`).post('',{
            method:'list',
            params:{
                page,count
            }
        });
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//뉴스 리스트
export const apiNewsList = async(page:number,count:number) =>{
    try {
        const res = await axiosConfigInstance(`${url}/news`).post('',{
            method:'list',
            params:{
                page,count
            }
        });
        return res.data;
    } catch (error) {
        return {
            error_en:error,
            result:false,
        }
    }
}

//뉴스 디테일
export const apiNewsDetail = async(id:number) =>{
    try {
        const res = await axiosConfigInstance(`${url}/news`).post('',{
            method:'detail',
            params:{
                notice_id:id,
            }
        });
        return res.data;
    } catch (error) {
        return {
            error_en:error,
            result:false,
        }
    }
}

//faq 리스트
export const apiFaqList = async() =>{
    try {
        const res = await axiosConfigInstance(`${url}/faq`).post('',{
            method:'list',
        })
        return res.data;
    } catch (error) {
        return{
            error_en:error,
            result:false,
        }
    }
}

// 카테고리 리스트
export const apiNftCategoryList = async() =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'category',
            params:{}
        });
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}


//nft 리스트
export const apiNftList = async(page:number,count:number,search:any,select1?:any,select2?:any,select3?:any,select4?:any,) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'list',
            params:{
                page,count,search,
                select1,select2,select3,select4
            }
        });
        return res.data;
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//nft 디테일
export const apiNftDetail = async(id:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'detail',
            params:{
                nft_id : id,
            }
        })
        return res.data
    } catch (e) {
        return{
            error_en:e,
            result:false,
        }
    }
}

//좋아요 상태
export const apiNftLike = async(nft_id:any,user_id:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'like',
            params:{
                nft_id,user_id
            }
        })
        return res.data
    } catch (e) {
        return {
            error_en :e,
            result : false,
        }
    }
}

//하트 +
export const apiNftLikeInsert = async(nft_id:any,user_id:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'likeInsert',
            params:{
                nft_id,user_id
            }
        })
        return res.data
    } catch (e) {
        return {
            error_en :e,
            result : false,
        }
    }
}

//하트 -
export const apiNftLikeDelete = async(nft_id:any,user_id:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'likeDelete',
            params:{
                nft_id,user_id
            }
        })
        return res.data
    } catch (e) {
        return {
            error_en :e,
            result : false,
        }
    }
}

//구매
export const apiBuyItem = async(user_id?:any,nft_id?:any,wallet_bsc?:any,wallet_eth?:any,price?:any,profit?:any,type?:any,count?:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'buy',
            params:{
                user_id,nft_id,
                trs_wallet:wallet_eth,
                trs_wallet_bsc:wallet_bsc,
                trs_price:price,
                trs_profit:profit,
                trs_buy_type:type,
                nft_count:count
            }
        })
        return res.data
    } catch (e) {
        return {
            error_en :e,
            result : false,
        }
    }
}

//nft 전송 상태 변경
export const apiNftSend = async(nft_id?:any) =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'nft_send',
            params:{
                nft_id:nft_id
            }
        })
        return res.data
    } catch (e) {
        return {
            error_en :e,
            result : false,
        }
    }
}

//마스터지갑
export const apiMasterWallet = async() =>{
    try {
        const res = await axiosConfigInstance(`${url}/nft`).post('',{
            method:'master',
        })
        return res.data;
    } catch (error) {
        return {
            error_en :error,
            result : false,
        }
    }
}

//mypage

//구매리스트
export const apiMyList = async(arr:any,nowPage:number,count:number) =>{
    try {
        const res = await axiosConfigInstance(`${url}/mypage`).post('',{
            method:'list',
            params:{
                nftArr:arr,
                page:nowPage,
                count:count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error_en :error,
            result : false,
        }
    }
}

//시세
export const apiNowPrice = async() =>{
    try {
        const res = await axiosConfigInstance(`core`).post('',{})
        return res.data;
    } catch (error) {
        return {
            error_en :error,
            result : false,
        }
    }
}