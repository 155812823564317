import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

export default function useTokenInfo(ContractAddress?:any,ContractAbi?:any) {
    const {library,account} = useWeb3React();
    if(!account) return ;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const singer = provider.getSigner(account !== null ? account : '');
    //로그인된 메타마스크 계정으로 토큰 정보, 전송
    const contract = new ethers.Contract(ContractAddress,ContractAbi,singer);

    //지갑주소로 수량,토큰 정보 
    const onlyBalanceContract = new ethers.Contract(ContractAddress,ContractAbi,provider);

    //코인 보유량
    const balance = async() =>{
        if(!account) return 0;
        const res = await library.getBalance(account);
        const resFormat = ethers.utils.formatEther(res);
        return Number(resFormat);
    }

    //코인 전송
    const send = async(receive?:string,count?:any) =>{
        // const res = await singer.getTransactionCount();
        const value = ethers.utils.parseUnits(String(count));
        const tx = {
            to:receive,
            value:value
        }
        const send = await singer.sendTransaction(tx);
        
        return send;
    }

    //토큰 보유량
    const tokenBalance = async() => {
        if(!account) return 0;
        const res = await contract.balanceOf(account);
        const decimals = await contract.decimals();
        const value = await ethers.utils.formatUnits(res,decimals);
        
        const resVal = Math.floor((Number(value)*10000)) / 10000;
        return resVal;
    };

    //토큰전송
    const tokenSend = async(receive?:string,count?:any) =>{
        if(!account) return;
        const decimals = await contract.decimals();
        const value = await ethers.utils.parseUnits(count.toString(),decimals);
        
        const res = await contract.transfer(receive,value);
        return res;
    }

    //지갑주소로 코인 balance
    const walletBalance = async(wallet:string) =>{
        let res = await provider.getBalance(wallet);
        let value = await ethers.utils.formatEther(res);
        const resVal = Math.floor((Number(value)*10000)) / 10000;
        return resVal
    }

    //지갑주소로 토큰 balance
    const walletTokenBalance = async(wallet:string) =>{
        const res = await onlyBalanceContract.balanceOf(wallet);
        const decimals = await onlyBalanceContract.decimals();
        const value = await ethers.utils.formatUnits(res,decimals);
        
        const resVal = Math.floor((Number(value)*10000)) / 10000;
        return resVal;
    }
    
    return {contract, balance, send, tokenBalance, tokenSend, walletBalance, walletTokenBalance,};
}


/*
 * window.ethereum 에서 오류 나면
    src/react-app-env.d.ts 생성 밑의 코드 추가
    /// <reference types="react-scripts" />
    import { ExternalProvider } from "@ethersproject/providers";

    declare global{
        interface Window{
            ethereum:ExternalProvider;
        }
    }
 * 
 */