import React from 'react'
import { addTheme } from '../../../style/basic/addTheme';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import styled from '@emotion/styled';

interface TitleItem {
    textTheme? : string;
    title1?:string;
    sub1?:string;
    sub2?:string;
    sub3?:string;
    subTheme?:string;
    children?:any;
    border?:boolean;
    lineState?:string;
    icon?:any;
}

export default function TitleItem({children,textTheme='black',title1='title',sub1='',sub2='',sub3='',subTheme='black',border,lineState,icon}:TitleItem) {
    const theme = useTheme();
    
    return (
        <>
            <BorderWrap border={border}>
                <Wrap>
                    <TextWrap lineState={lineState} background={theme.black}>
                        {title1 !=='' &&
                            <Row>
                                <BasicText theme={textTheme} fontSize={addTheme.title.fontSize} mFontSize={addTheme.title.mFontSize} fontWeight={addTheme.title.fontWeight}>{title1}</BasicText>
                                <IconWrap fontSize={addTheme.title.fontSize} mFontSize={addTheme.title.mFontSize}>
                                    {icon}
                                </IconWrap>
                            </Row>
                        }
                        {sub1 !=='' &&
                            <BasicText marginTop={3} theme={subTheme} fontSize={addTheme.subTitle.fontSize} mFontSize={addTheme.subTitle.mFontSize}>
                                {sub1}
                            </BasicText>
                        }
                        {sub2 !=='' &&
                            <BasicText theme={subTheme} fontSize={addTheme.subTitle.fontSize} mFontSize={addTheme.subTitle.mFontSize}>
                                {sub2}
                            </BasicText>
                        }
                        {sub3 !=='' &&
                            <BasicText theme={subTheme} fontSize={addTheme.subTitle.fontSize} mFontSize={addTheme.subTitle.mFontSize}>
                                {sub3}
                            </BasicText>
                        }
                    </TextWrap>
                    {children !== undefined &&
                        <ChildrenPc>
                            {children}
                        </ChildrenPc>
                    }
                </Wrap>
                {children !== undefined &&
                    <ChildrenM>
                        {children}
                    </ChildrenM>
                }
            </BorderWrap>
        </>
    )
}

const BorderWrap = styled.div<{border?:boolean}>`
    width: 80%;
    min-height: ${props=>props.border ? '100px' : 'auto'};
    margin-bottom: 15px;
    border-bottom: ${props=>props.border ? '1px solid #333' :''};
`;

const Wrap = styled(RowBetween)`
    width:100%;
`;

const TextWrap = styled.div<{lineState?:string,background?:string}>`
    flex-shrink: 0;
    max-width: 100%;
    &>div>p:first-of-type{position: relative;}
    &>div>p:first-of-type::before{
        content:'';
        position: absolute;
        left: 0;
        width: 18%;
        height: 3px;
        background-color: ${props => props.background ?? 'transparent'};
        display: ${props=> typeof props.lineState === 'string' ? 'block' : 'none'};
        top: ${props => props.lineState === 'top' ? '-10px' : props.lineState === 'bottom' ? 'calc(100% + 5px)' : 0};
    }
`;

const IconWrap = styled.div<{fontSize?:number,mFontSize?:number}>`
    margin-left: 14px;
    &>svg{
        width:${props=>props.fontSize ?? 16}px;
        height:${props=>props.fontSize ?? 16}px;
    }
    @media ${({theme}:any)=>theme.media.m}{
        &>svg{
            width:${props=>props.mFontSize ?? 16}px;
            height:${props=>props.mFontSize ?? 16}px;
        }   
    }
`;

const ChildrenPc = styled.div`
    @media ${({theme}:any)=>theme.media.m}{
        display: none;
    }
`;
const ChildrenM = styled.div`
    display: none;
    @media ${({theme}:any)=>theme.media.m}{
        display: block;
        margin: 25px 0;
    }
`;