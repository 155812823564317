import styled from '@emotion/styled';
import React from 'react'
import { Center } from '../../../style/basic/commonStyle';
import CenterTitleItem from '../../common/item/CenterTitleItem'
import BtnItem from '../../header/item/BtnItem';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { useNavigate } from 'react-router-dom';
import GridItem from '../../common/item/GridItem';
import BasicText from '../../basic/BasicText';
import { IMG_URI } from '../../../api/config';
import NoListItem from '../item/NoListItem';

interface collectionPartType {
    list?:Array<any>;
}

export default function CollectionPart({list}:collectionPartType) {
    const theme = useTheme();
    const navigate = useNavigate();
    const btnClick = () =>{
        navigate(`/collection?page=1&sc=&select1=0&select2=0&select3=0&select4=0`);
    }
    
    return (
        <Wrap>
            <CenterTitleItem 
                title='Meca Collection'
                sub='Meca Collection is the best NFTs by transparent of Casino by opening profit.You can earn forever casino operating profits just by holding Meca NFTs.'
            ></CenterTitleItem>
            <ListWrap displayType={list?.length === 0}>
                {list?.length === 0 ?
                    <NoListItem></NoListItem> :
                    list?.map((data,index)=>{
                        let imgState = data.nft_img === '' || data.nft_img === undefined ? false : true;
                        return(
                            <GridItem link={`/detail/${data.nft_id}`} key={`collection-item-${index}`} background={theme.listItemBg} imgHeight='77%' position='main' {...data} lh={1.1} type='nft'>
                                {imgState &&
                                    <img alt='nft-img' src={IMG_URI+data.nft_img}></img>
                                }
                            </GridItem>
                        )
                    })
                }
            </ListWrap>
            <BtnWrap>
                <BtnItem borderColor={theme.btnBorder} text='See All Collection' clickEvent={btnClick}></BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ListWrap = styled.div<{displayType:boolean}>`
    margin-top: 60px;
    display: ${props => props.displayType ? 'block':'grid'};
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-columns: 1fr 1fr;
    }
`;
const BtnWrap = styled(Center)`
    margin-top: 60px;
`;

