import React,{useState} from 'react'
import styled from '@emotion/styled';
import MenuItem from '../item/MenuItem'
import MenuMPart from './MenuMPart';

export default function MenuBoxPart({menu,menuMarginLeft,menupadding,type,fontSize,menuM,menuClickEvent,addItem}:any) {
    const [menuMState,setMenuMState] = useState(false);
    const menuMBtnClick = () =>{
        setMenuMState(!menuMState)
    }
    return (
        <>
            <PcWrap type={type}>
                {menu?.map((data:any,index:any)=>{
                    return(
                        <MenuItem fontSize={fontSize} key={index} link={data.link} menuMarginLeft={menuMarginLeft} menupadding={menupadding} menuClickEvent={menuClickEvent}>{data.title}</MenuItem>
                    )
                })}
            </PcWrap>
            <MWrap type={type}>
                <MenuMPart menu={menu} menuM={menuM} fontSize={fontSize} menuMarginLeft={menuMarginLeft} menupadding={menupadding} addItem={addItem} menuClickEvent={menuClickEvent}></MenuMPart>
            </MWrap>
        </>
    )
}

const PcWrap = styled.div<{type?:string}>`
    display: ${props => props.type === 'none' ? 'none' : 'block'};
    @media ${({theme}:any)=>theme.media.m}{
        display: none;
    }
`;

const MWrap = styled.div<{type?:string}>`
    display: none;
    @media ${({theme}:any)=>theme.media.m}{
        display: ${props => props.type ==='none' ? 'block' : props.type==='real' ? 'block':'none'};
    }
`;
