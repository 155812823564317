import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import {PROVIDER_NET} from '../../../api/config'
import { keyDec } from '../../format/string';

export default function useNft(ContractAddress?:any,ContractAbi?:any) {
    const {library,account} = useWeb3React();
    if(!account) return ;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const singer = provider.getSigner(account !== null ? account : '');
    
    //로그인된 메타마스크 계정으로 토큰 정보, 전송
    const contract = new ethers.Contract(ContractAddress,ContractAbi,singer);
    
    // 소유한 nft 
    const nftListWallet = async(wallet?:any) => {
        if(!account) return [0,0];
        const res = await contract.walletOfOwner(wallet);
        let arr = res.map((data:any)=>Number(data));
        return arr;
    }

    // 구매한 nft 전송
    const nftBuySend = async(wallet?:string,id?:any,masterKey?:string) =>{
        //infura
        const infuraProvider = new ethers.providers.InfuraProvider(
            PROVIDER_NET,
            process.env.REACT_APP_INFURA_KEY
            );
        let key = masterKey === undefined ? '' : masterKey;
        const keyRes = keyDec(key);
        const infuraSigner = new ethers.Wallet(keyRes,infuraProvider);
        const infuraContract = new ethers.Contract(ContractAddress,ContractAbi,infuraSigner);
        
        const res = await infuraContract.transferFrom(infuraSigner.address,wallet,id);
        return res;
    }

    return {contract,nftListWallet,nftBuySend};
}