import styled from '@emotion/styled'
import React from 'react'
import GridItem from '../../common/item/GridItem';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/basic/addTheme';
import { HiOutlineHeart,HiHeart,HiEye } from 'react-icons/hi';
import SnsItem from '../item/SnsItem';
import { IMG_URI } from '../../../api/config';

export default function ImgDescPart({data,heart,heartClick}:any) {
    const theme = useTheme();
    let videoSrc = IMG_URI+data.nft_video;
    let imgSrc= IMG_URI+data.nft_img;
    let contentState = data.nft_video === undefined ? false : true;
    
    return (
        <Wrap>
            <ImgWrap>
                <GridItem link='no' borderNo={true}>
                    {contentState &&
                        (data.nft_video === '"' ?
                        <img src={imgSrc} alt='nft-img'></img>
                        :
                        <VideoWrap key={videoSrc} autoPlay={true} muted loop={true}>
                            <source src={videoSrc} type='video/mp4'></source>
                        </VideoWrap>)
                    }
                </GridItem>
            </ImgWrap>
            <SpaceWrap></SpaceWrap>
            <TextWrap>
                <FlexWrap>
                    <BasicText theme='black' fontSize={addTheme.detail.title} mFontSize={addTheme.detail.mTitle}>{data.nft_name}</BasicText>
                    <IconWrap fontSize={addTheme.detail.title} mFontSize={addTheme.detail.mTitle} onClick={heartClick}>
                        {heart ?
                            <HiHeart color={theme.signalRed}></HiHeart>
                            : <HiOutlineHeart color={theme.black}></HiOutlineHeart>
                        }
                    </IconWrap>
                </FlexWrap>
                <MarginTopWrap top={10}>
                    <FlexWrap>
                        <FlexWrap>
                            <HiEye color={theme.black}></HiEye>
                            <BasicText marginLeft={1}  theme='black' fontSize={addTheme.detail.sub}>{data.nft_view_count} views</BasicText>
                        </FlexWrap>
                        <FlexWrap margin='0 0 0 10px'>
                            <HiHeart color={theme.black}></HiHeart>
                            <BasicText marginLeft={1} theme='black' fontSize={addTheme.detail.sub}>{data.nft_like_count} likes</BasicText>
                        </FlexWrap>
                    </FlexWrap>
                </MarginTopWrap>
                <MarginTopWrap top={28}>
                    <BasicText theme='black'>{data.nft_desc}</BasicText>
                </MarginTopWrap>
                <MarginTopWrap top={50}>
                    <FlexWrap align={'start'}>
                        <SnsItem sns='twitter' background={theme.comingBtn}></SnsItem>
                        <SnsItem sns='telegram' background={theme.comingBtn}></SnsItem>
                        {/* <SnsItem sns='menu' background={theme.comingBtn}></SnsItem> */}
                    </FlexWrap>
                </MarginTopWrap>
            </TextWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    @media ${({theme}:any)=>theme.media.m}{
        flex-wrap: wrap;
    }
`;

const FlexWrap = styled.div<{margin?:string,align?:string}>`
    display: flex;
    align-items: ${props => props.align ?? 'center'};
    margin:${props=>props.margin ?? 0};
`;

const ImgWrap = styled.div`
    width:45%;
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
    }
`;

const IconWrap = styled.div<{fontSize?:number,mFontSize?:number}>`
    margin-left: 10px;
    cursor: pointer;
    &>svg{
        width:${props=>props.fontSize ?? '0'}px;
        height:${props=>props.fontSize ?? '0'}px;
        @media ${({theme}:any)=>theme.media.m}{
            width:${props=>props.mFontSize ?? '0'}px;
            height:${props=>props.mFontSize ?? '0'}px;
        }
    }
`;

const MarginTopWrap = styled.div<{top?:number}>`
    margin-top: ${props=>props.top ?? 0}px;
`;

const SpaceWrap = styled.div`
    width:5%;
    padding: 15px 0;
`;

const TextWrap = styled.div`
    width:50%;
    @media ${({theme}:any)=>theme.media.m}{
        width:100%;
    }
`;

const VideoWrap = styled.video`
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
`;