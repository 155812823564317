import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Center } from '../../../style/basic/commonStyle';
import {listType,viewCountType} from '../page/ListPage'
import NoItemPart from './NoItemPart';

interface infiniteListType extends listType{
    viewCount?:viewCountType;
}

export default function InfiniteListPart({viewCount,gap,changeNowPage,nowPage=1,totalPage=1,children}:infiniteListType) {
    const obsRef = useRef<any>(null); //observer el
    const [loading,setLoading] = useState(false);
    useEffect(() => {
        const observer= new IntersectionObserver(async(entries) => {
            if(nowPage<totalPage){
                if(entries[0].isIntersecting){
                    if(!loading){
                        pageLoading();
                    }
                }
            }else{
                observer.disconnect();
            }
        })
        //옵져버 탐색 시작
        observer.observe(obsRef.current);
        return () => observer.disconnect();
    },[nowPage,loading]);

    const pageLoading = ()=>{
        if(nowPage<totalPage){
            setLoading(true);
            let timer = setTimeout(() => {
                setLoading(false);
                changeNowPage((page:number)=>page+1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }
    
    return (
        <Wrap>
            <ListWrap gap={gap} viewCount={viewCount}>
                {children === undefined ?
                    <NoItemPart></NoItemPart>
                : children}
            </ListWrap>
            <div className='observer' ref={obsRef}></div>
            <LoadingWrap>
                {loading && (nowPage !== totalPage) && <img alt='loading-img' src='/img/loading.gif'></img>}
            </LoadingWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const ListWrap = styled.div<{viewCount?:any,gap?:number}>`
    width: 100%;
    display: grid;
    gap: ${props=>props.gap ?? 0}px;
    grid-template-columns: ${props=>props.viewCount.pcCount ?? '1fr 1fr 1fr 1fr'};
    margin-bottom: 50px;
    @media ${({theme}:any)=>theme.media.m}{
        grid-template-columns: ${props=>props.viewCount.mCount ?? '1fr 1fr 1fr 1fr'};
    }
`;

const LoadingWrap = styled(Center)`
    margin-top: 85px;
`;