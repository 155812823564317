import styled from '@emotion/styled'
import React,{useState,useEffect} from 'react'
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import GridItem from '../../common/item/GridItem';
import TimeItem from '../item/TimeItem';

export default function ComingSoonPage({count}:any) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [day,setDay] = useState(0);
    const [h,setH] = useState(0);
    const [m,setM] = useState(0);
    const [s,setS] = useState(0);

    function formatNum(data:any) {
        let resDays = data<10 ? `0${data}` : data;
        return resDays;
    }

    const countDown = () =>{
        let _second = 1000;
        let _minute = _second * 60;
        let _hour = _minute * 60;
        let _day = _hour * 24;
        let now = new Date().getTime();
        let distDt = count - now;
        
        setDay(formatNum(Math.floor(distDt / _day)));
        setH(formatNum(Math.floor((distDt % _day) / _hour)));
        setM(formatNum(Math.floor((distDt % _hour) / _minute)));
        setS(formatNum(Math.floor((distDt % _minute) / _second)));
    }

    useEffect(()=>{
        let now = new Date().getTime();
        let distDt = count - now;
        
        const timer = setInterval(()=>{
            countDown();
        },1000);

        if(distDt<0){
            clearInterval(timer);
        }

        return() => clearInterval(timer);
    },[]);
    
    return (
        <Wrap>
            <InnerWrap maxWidth='800px'>
                <BgWrap>
                    <GradientWrap></GradientWrap>
                    <NoneBgWrap></NoneBgWrap>
                </BgWrap>
                <BasicText theme='black' fontSize={48} mFontSize={32} fontWeight='800'>COMING SOON!</BasicText>
                <TimeWrap>
                    <TimeItem time={day}></TimeItem>
                    <TimeItem time={h}></TimeItem>
                    <TimeItem time={m}></TimeItem>
                    <TimeItem time={s}></TimeItem>
                </TimeWrap>
                <BtnWrap color={theme.black} bg={theme.comingBtn} onClick={()=>navigate('/')}>Go Home</BtnWrap>
            </InnerWrap> 
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    height: calc(100vh - 71px);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InnerWrap = styled(MaxWidthWrap)`
    position: relative;
    &>p{display:block; text-align:center;}
    padding: 20px 15px;
`;

const TimeWrap = styled.div`
    margin-top:60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap:15px;
`;

const BtnWrap = styled.button<{color?:string,bg?:string}>`
    color : ${props => props.color ?? '#333'};
    background-color : ${props => props.bg ?? '#333'};
    width:100%;
    height:44px;
    border-radius: ${({theme}:any)=>theme.borderRadius};
    margin-top: 50px;
`;

const BgWrap = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: -1;
    filter: blur(20px);
    background-color: #000034;
`;

const NoneBgWrap = styled.div`
    width: calc(100% + 10px);
    height:calc(100% + 10px);
    position: relative;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: #000034;
`;

const GradientWrap = styled.div`
    position: absolute;
    width: calc(100% + 30px);
    height:calc(100% + 30px);
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: linear-gradient(56deg, #f200ff 0%, #1418eb 100%);
    &::before{
        content:''; position: absolute; top:-30px; left:-30px; width:30%; height: 30%; border-radius:100%; background-color: #f200ff; filter: blur(50px);
    }
    &::after{
        content:''; position: absolute; bottom:-30px; right:-30px; width:30%; height: 30%; border-radius:100%; background-color: #1418eb; filter: blur(40px);
    }
`;