import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';

export default function MenuItem({children,link,menuMarginLeft,menupadding,fontSize,menuClickEvent}:any) {
    const theme = useTheme();
    
    const clickEvent = (event:any,link:any) =>{
        menuClickEvent(event,link);
    }

    return (
        <>
            <MenuLink onClick={(event)=>clickEvent(event,link)} color={theme.black} to={link} menumarginleft={menuMarginLeft} menupadding={menupadding} fontSize={fontSize}>{children}</MenuLink>
        </>
    )
}

const MenuLink = styled(Link)<{menumarginleft?:string, menupadding?:string,fontSize?:string,color?:string}>`
    margin-left: ${props => props.menumarginleft ?? '0px'};
    padding : ${props => props.menupadding ?? '0px'};
    font-size: ${props => props.fontSize ?? '16px'};
    color: ${props=>props.color ?? '#ddd'};
`;