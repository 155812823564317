import React from 'react'
import styled from '@emotion/styled';
import LogoItem from '../item/LogoItem';
import MenuBoxPart from './MenuBoxPart';

export default function RightLogoPart({logoType,logoMargin,logo,menuPosition,menu,logoSize,fontSize,menumargin,menupadding}:any) {
    const type = menuPosition === 'right' || menuPosition ==='center' ? 'none' : 'real';
    
    return (
        <>
            <LeftBox>
                {(type ==='none' || type==='real') &&
                    <MenuBoxPart menu={menu} menumargin={menumargin} menupadding={menupadding} fontSize={fontSize} type={type}></MenuBoxPart>
                }
            </LeftBox>
            <CenterBox>
                {menuPosition === 'center' && 
                    <MenuBoxPart menu={menu} menumargin={menumargin} menupadding={menupadding} fontSize={fontSize}></MenuBoxPart>
                }
            </CenterBox>
            <RightBox menuPosition={menuPosition}>
                {menuPosition === 'right' && 
                    <MenuBoxPart menu={menu} menumargin={menumargin} menupadding={menupadding} fontSize={fontSize} menuM={'left'}></MenuBoxPart>
                }
                <LogoItem logoType={logoType} logo={logo} logoMargin={logoMargin} logoSize={logoSize}></LogoItem>
            </RightBox>
        </>
    )
}

const LeftBox = styled.div`
    
`
const CenterBox = styled.div``;
const RightBox = styled.div<{menuPosition?:string}>`
    display: ${props=>props.menuPosition === 'right' ? 'flex' : 'block'};
    align-items: center;
`