import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import validator from 'validator';

interface emailDialogPageType {
    openChk:boolean;
    setOpenChk?:any;
    clickEvent?:any;
}

export default function BuyDialogPart({openChk,setOpenChk,clickEvent}:emailDialogPageType) {
    const [value,setValue] = useState('');

    const handleClose = () => {
        setOpenChk(false);
    };

    const handleClick = () =>{
        setOpenChk(false);
        clickEvent();
    }

    return (
        <Dialog
            open={openChk}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={(e)=>{}}
            sx={{'.MuiPaper-root':{backgroundColor:'#fff',color:'#111'}}}
        >
            <DialogTitle id="alert-dialog-title">
                Would you like to purchase this product?
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={handleClick}>OK</Button>
            </DialogActions>
        </Dialog>
    )
}
