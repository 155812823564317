import React, { useState } from 'react'
import styled from '@emotion/styled';
import {FaAngleDown,FaAngleUp} from 'react-icons/fa';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import BasicText from '../../basic/BasicText';
import { Center } from '../../../style/basic/commonStyle';

interface accordion{
    title? : string;
    content? : string;
    count?:string;
}

export default function Accordion({title,content,count}:accordion) {
    const theme = useTheme();
    const [open,setOpen] = useState(false);
    const openClick = () =>{
        setOpen(!open)
    }

    return (
        <>
            <Wrap>
                <TitleWrap onClick={openClick}>
                    <FlexWrap>
                        <Center>
                            <BasicText theme='black' fontSize={21} mFontSize={16} fontWeight={800}>{count}</BasicText>
                            <BasicText marginLeft={2} theme='black' fontSize={21} mFontSize={16}>
                                {title}
                            </BasicText>
                        </Center>
                        <IconWrap>
                            {open ?
                                <FaAngleUp size={26} color={theme.black}></FaAngleUp>
                                : <FaAngleDown size={26} color={theme.black}></FaAngleDown>
                            }
                        </IconWrap>
                    </FlexWrap>
                </TitleWrap>
                <ContentWrap openChk={open}>
                    <BasicText theme='black' fontSize={18} mFontSize={15} dangerouslySetInnerHTML={{__html:content}}></BasicText>
                </ContentWrap>
            </Wrap>
        </>
    )
}

const Wrap = styled.div`
    margin: 20px 0;
`;

const FlexWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TitleWrap = styled.div`
    padding: 12px 15px;
    cursor: pointer;
    border-radius: ${({theme}:any)=>theme.borderRadius};
    border: 1px solid #fff;
    background-color: #a8c3f14a;
`
const ContentWrap = styled.div<{openChk?:boolean}>`
    margin-top: 8px;
    padding: 18px 28px;
    display: ${props=> props.openChk ? 'block':'none'};
    border: 1px solid #fff;
    background-color: #a8c3f14a;
    border-radius: ${({theme}:any)=>theme.borderRadius};
`

const IconWrap = styled.div`
    flex-shrink: 0;
`;

