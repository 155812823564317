import { telKor, telRom, telThai, telViet, twitterLink } from "../api/config";

export const MENU = [
    {id:0,title:'Home',link:'/'},
    {id:1,title:'About',link:'/#about'},
    {id:2,title:'Collection',link:'/#collection'},
    {id:3,title:'Roadmap',link:'/#roadmap'},
    {id:4,title:'FAQ',link:'/#faq'},
];

export const FOOTER_MENU = [
    {id:0,title:'Home',link:'/'},
    {id:1,title:'Collections',link:'/#collection'},
    {id:2,title:'Roadmap',link:'/#roadmap'},
    {id:3,title:'Media',link:'/media',sub:[
        {id:1,title:'Press',link:'/press'},
        {id:2,title:'Twitter',link:twitterLink,type:'out'},
        {id:3,title:'Telegram',link:'https://t.me/MetaCasinoDAO',type:'out'},
        // {id:4,title:'Telegram',link:telThai,type:'out'},
        // {id:5,title:'Telegram',link:telViet,type:'out'},
        // {id:6,title:'Telegram',link:telRom,type:'out'},
    ]},
    {id:4,title:'Terms',link:'/terms'},
]