import styled from '@emotion/styled';
import React from 'react'
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import GridItem from '../../common/item/GridItem';

export default function TimeItem({time}:any) {
    const theme = useTheme();
    return (
        <Wrap>
            <GridItem background={theme.itemBg} link='no'>
                <TimeItemWrap color={theme.black}>
                    <Text>{time}</Text>
                </TimeItemWrap>
            </GridItem>
        </Wrap>
    )
}

const Wrap = styled.div``;

const TimeItemWrap = styled.div<{color?:string}>`
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
    -webkit-text-stroke: 3px #ff00d6; 
    font-size: 75px; 
    font-weight: 900; 
    color: transparent;
    
    @media (max-width:495px){
        font-size: 42px;
    }
`;