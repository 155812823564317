import React from 'react'
import styled from '@emotion/styled';

export default function NoItem({id}:any) {
    return (
        <Item>{id}</Item>
    )
}


const Item = styled.div`
    width: 100%;
    padding-bottom: 100%;
    background: #ddd;
`;