import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import ImgDescPart from '../part/ImgDescPart';
import InformationPart from '../part/InformationPart';
import TitleItem from '../../common/item/TitleItem';
import { FaAngleDown } from 'react-icons/fa';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import ListPage from '../../list/page/ListPage';
import GridItem from '../../common/item/GridItem';
import { PaddingWrap } from '../../../style/basic/commonStyle';
import { apiBuyItem, apiMasterWallet, apiNftDetail,apiNftLike,apiNftLikeDelete,apiNftLikeInsert,apiNftList, apiNftSend } from '../../../api/mainApi';
import { useWeb3React } from '@web3-react/core';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import BuyDialogPart from '../part/BuyDialogPart';
import useTokenInfo from '../../../utils/custom-hooks/common/useTokenInfo';
import { mecaTokenAddress, mecaTokenAbi, mecaNftAddress,mecaNftAbi } from '../../../data/tokenData';
import { setDialogData } from '../../../store/common/globalDialog';
import { useDispatch } from 'react-redux';
import { BSC_ID, ETH_ID, IMG_URI } from '../../../api/config';
import useNft from '../../../utils/custom-hooks/common/useNft';
import CryptoJS from 'crypto-js'
import { keyDec } from '../../../utils/format/string';

export default function DetailPage() {
    const params = useParams();
    const theme = useTheme();
    const [item,setItem] = useState({nft_id:0, nft_create_time:0, nft_desc:'', nft_detail:{},nft_img:'', nft_like_count:0, nft_listing:{},nft_name:'',nft_price_eth:0,nft_price_meca:0,nft_properties:[],nft_token:'ETH',nft_view_count:0,nft_status:0,nft_profit:0,nft_count:0,});
    const [list,setList] = useState([{nft_id:0,nft_name:'',nft_img:'',}]);
    const [heart,setHeart] = useState(false);
    const [openChk,setOpenChk] = useState(false);
    const [buyType,setBuyType] = useState('');
    const {account,chainId} = useWeb3React();
    const token = useTokenInfo(mecaTokenAddress,mecaTokenAbi);
    const nft = useNft(mecaNftAddress,mecaNftAbi);
    const user = useUserInfo();
    const dispatch = useDispatch();

    useEffect(()=>{
        getInfo();
        getList();
    },[params.pId]);
    
    //디테일 정보
    const getInfo = async()=>{
        let {pId} = params;
        const res = await apiNftDetail(pId);
        const like = await apiNftLike(pId,1);
        
        setHeart(like.like);
        
        if(!res.result){
            alert(res.error_en);
            return;
        }
        
        setItem({...res.data,nft_view_count:res.data.nft_view_count+1});
    }

    //하단 리스트
    const getList = async() =>{
        const res = await apiNftList(1,9,'');
        if(!res.result){
            alert(res.error_en);
            return;
        }
        setList(res.data);
    }

    //좋아요
    const heartClick = async() =>{
        let {pId} = params;
        if(user.user_id === 0){
            return;
        }
        if(!heart){
            //좋아요 on
            const res = await apiNftLikeInsert(pId,user.user_id);
            if(!res.result){
                return;
            }
            setHeart(res.like);
            setItem((data)=>{
                return{...data,nft_like_count:data.nft_like_count+1}
            })
        }
        if(heart){
            //좋아요 off
            const res = await apiNftLikeDelete(pId,user.user_id);
            if(!res.result){
                return;
            }
            setItem((data)=>{
                let count = data.nft_like_count === 0 ? 0 : data.nft_like_count-1;
                return{...data,nft_like_count:count}
            })
            setHeart(res.like);
        }
    }

    //구매팝업창
    const buyBtnClick = async(type:string) =>{
        setBuyType(type);
        
        if(user.user_id === 0){
            dispatch(setDialogData({
                modalOpen:true,
                modalText:'Please connect wallet.',
            }))
            return;
        }
        if(type === 'eth'){
            if(chainId !== ETH_ID) {
                dispatch(setDialogData({
                    modalOpen:true,
                    modalText:'Please change the network to ETH.',
                }))
                return;
            }
        }
        setOpenChk(true);
    }

    //이더 보유량 체크
    const ethChk = async() =>{
        let balance = await token?.balance();
        balance = balance === undefined ? 0 : balance;
        //nft price
        let price = item.nft_price_eth;
        if(balance < price){
            return {result:false};
        }
        return {result:true,price};
    }
    //bsc 보유량 체크
    const bscChk = async() =>{
        let balance = user.user_point;
        let price = item.nft_price_meca;
        if(balance < price){
            if(chainId !== BSC_ID){
                dispatch(setDialogData({
                    modalOpen:true,
                    modalText:'Please change the network to BSC',
                }))
                return {result:false,code:1};
            }
            let tokenBalance = await token?.tokenBalance();
            tokenBalance = tokenBalance === undefined ? 0 : tokenBalance;
            if(tokenBalance < price){
                return {result:false,code:2};
            }
            // 포인트가 적지만 token이 있을경우
            return {result:true,price,type:2};
        }
        // 포인트 보유량이 더 많을 경우
        return {result:true,price,type:1};
    }
    
    const setModalData = (txt?:string) =>{
        dispatch(setDialogData({
            modalOpen:true,
            modalText:txt,
        }))
    }

    // 마스터지갑 type별
    const getMaster = (arr:any,type:any) =>{
        const master = arr.filter((data:any)=>data.master_type === type);
        return master[0];
    }

    //구매
    const buyEvent = async() =>{
        if(!account){
            //매타마스크에서 유저 지갑정보 없으면
            setModalData('Please login with metamask.');
            return;
        }
        const master = await apiMasterWallet();
       
        if(!master.result){
            //마스터지갑이 등록안되었으면
            setModalData('The administrator has not yet registered the master wallet. The administrator can purchase after registering the master wallet.');
            return;
        }
        const masterBsc = getMaster(master.data,0);
        const masterEth = getMaster(master.data,1);
        const masterKey = getMaster(master.data,2);
        //nft id
        let {pId} = params;
        try {
            if(buyType === 'eth'){
                // 이더로 구매시
                if(masterEth.master_wallet === ''){
                    setModalData(`Master's Ethereum wallet is not registered.`);
                    return;
                }
                const ethChkRes = await ethChk();
                if(!ethChkRes.result){
                    setModalData('The purchase amount is higher than the amount owned.');
                    return;
                }
                await token?.send(masterEth.master_wallet,ethChkRes.price).then(async()=>{
                    const res = await apiBuyItem(user.user_id,pId,user.user_wallet_eth, user.user_wallet_bsc,ethChkRes.price, item.nft_profit, 0, item.nft_count);
                    
                    if(!res.result){
                        setModalData('Please try again in a few minutes.');
                        return;
                    }
                    
                    await getInfo();
                    await nft?.nftBuySend(account,item.nft_count,masterKey.master_wallet).then(async()=>{
                        const sendRes = await apiNftSend(pId);
                        
                        if(!sendRes.result){
                            setModalData('Please try again in a few minutes.');
                            return;
                        }
                        setModalData('purchase has been made.');
                    })
                    return;
                });
                return;
            }
            if(buyType === 'meca'){
                const bscChkRes = await bscChk();
                
                if(!bscChkRes.result && bscChkRes.code === 2){
                    setModalData('The purchase amount is higher than the amount owned.');
                    return;
                }
                
                // 포인트
                if(bscChkRes.type === 1){
                    const res = await apiBuyItem(user.user_id,pId,user.user_wallet_eth, user.user_wallet_bsc,bscChkRes.price, item.nft_profit, 1, item.nft_count);
                    if(!res.result){
                        setModalData('Please try again in a few minutes.');
                        return;
                    }
                    setModalData('purchase has been made.');
                    await getInfo();
                    return;
                }

                //meca
                if(bscChkRes.type === 2){
                    if(masterBsc.master_wallet === ''){
                        setModalData(`Master's BSC wallet is not registered.`);
                        return;
                    }
                    await token?.tokenSend(masterBsc.master_wallet,bscChkRes.price).then(async()=>{
                        const res = await apiBuyItem(user.user_id,pId,user.user_wallet_eth, user.user_wallet_bsc,bscChkRes.price, item.nft_profit, 2, item.nft_count);
                        
                        if(!res.result){
                            setModalData('Please try again in a few minutes.');
                            return;
                        }

                        await nft?.nftBuySend(account,item.nft_count,masterKey.master_wallet).then(async()=>{
                            const sendRes = await apiNftSend(pId);
                            
                            if(!sendRes.result){
                                setModalData('Please try again in a few minutes.');
                                return;
                            }
                            setModalData('purchase has been made.');
                            await getInfo();
                        })
                        return;
                    })
                    return;
                }
            }
        } catch (error) {
            return;
        }
    }

    return (
        <Wrap>
            <BuyDialogPart openChk={openChk} setOpenChk={setOpenChk} clickEvent={buyEvent}></BuyDialogPart>
            <MaxWidthWrap>
                <PaddingWrap padding='100px 0 0'>
                    <ImgDescPart data={item} heart={heart} heartClick={heartClick}></ImgDescPart>
                </PaddingWrap>
                <PaddingWrap padding='90px 0 0'>
                    <TitleItem title1='Informations' lineState='top' icon={<FaAngleDown color={theme.black}></FaAngleDown>}></TitleItem>
                    <PaddingWrap padding='40px 0 0'>
                        <InformationPart data={item} clickEvent={buyBtnClick}></InformationPart>
                    </PaddingWrap>
                </PaddingWrap>
                <PaddingWrap padding='150px 0 0'>
                    <TitleItem title1='More Meca NFTs' lineState='top' icon={<FaAngleDown color={theme.black}></FaAngleDown>}></TitleItem>
                    <PaddingWrap padding='30px 0 0'>
                        <ListPage type='none' count={3} mCount={2} gap={30}>
                            {list[0].nft_id !== 0 &&
                                list?.map((data,index)=>{
                                    return(
                                        <GridItem key={`detail-nftList-${index}`} background={theme.itemBg} text={data.nft_name} fontSize={20} mFontSize={16} link={`/detail/${data.nft_id}`} type='nft'>
                                            <img alt='nft-img' src={IMG_URI+data.nft_img}></img>
                                        </GridItem>
                                    )
                                })
                            }
                        </ListPage>
                    </PaddingWrap>
                </PaddingWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
