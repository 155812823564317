import React,{useEffect, useState} from 'react'
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {IoIosMenu} from 'react-icons/io'
import MenuItem from '../item/MenuItem';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';
import { IoClose } from 'react-icons/io5';
import { useLocation } from "react-router-dom";

export default function MenuMPart({menuM,menu,menumargin,menupadding,fontSize,addItem,menuClickEvent}:any) {
    const [menuMState,setMenuMState] = useState(false);
    const menuMBtnClick = () =>{
        setMenuMState(!menuMState)
    }
    const theme = useTheme();
    const  {pathname,search,hash} = useLocation();
    useEffect(() => {
        setMenuMState(false);
    }, [pathname,search,hash,setMenuMState]);
    return (
        <>
            <MenuMBtn onClick={()=>menuMBtnClick()}>
                <IoIosMenu size={28} color={theme.black}></IoIosMenu>
            </MenuMBtn>
            <MenuMWrap menuMState={menuMState} onClick={()=>setMenuMState(false)}>
                <CloseBtn>
                    <IoClose size={28} color={theme.black}></IoClose>
                </CloseBtn>
                <MenuMBg onClick={()=>setMenuMState(false)}></MenuMBg>
                <MenuMListWrap menuMState={menuMState} menuM={menuM}>
                    <MenuMListItemWrap>
                        {menu?.map((data:any,index:any)=>{
                            return(
                                <MenuItem fontSize={fontSize} key={index} link={data.link} menumargin={menumargin} menupadding={menupadding} menuClickEvent={menuClickEvent}>{data.title}</MenuItem>
                            )
                        })}
                    </MenuMListItemWrap>
                    <BtnWrap>
                        {addItem}
                    </BtnWrap>
                </MenuMListWrap>
            </MenuMWrap>
        </>
    )
}

const MenuMBtn = styled.div`
    position: relative;
    z-index: 49;
    cursor: pointer;
`;

const MenuMWrap = styled.div<{menuMState?:boolean}>`
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 50;
    display: ${props => props.menuMState ? 'block' : 'none'};
`;

const MenuMBg = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
`;

const MenuMListWrap = styled.div<{menuMState?:boolean,menuM?:string}>`
    position: absolute;
    top:0;
    ${props=>props.menuM === 'right' ? 
        css`
            right:0;
        `
        : css`
            left:0;
        `
    }
    right:0;
    width: 60%;
    height: 100%;
    background-color: #000034;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(max-width:992px){
        width:80%;
    }
    
`;

const MenuMListItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    &>a{margin:15px 0;}
`;

const CloseBtn = styled.div`
    position: absolute;
    top:15px;
    right:15px;
    z-index: 51;
    cursor: pointer;
`;

const BtnWrap = styled.div`
    & button{width:100%; padding:10px 10px;}
    &>div{
        display:block;
        & button{margin:10px 0;}
    }
`;